import { FormEvent, useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import LoaderButton from "components/LoaderButton";
import { useAppContext } from "libs/contextLib";
import { useFormFields } from "libs/hooksLib";
import { onError } from "libs/errorLib";
import "./Login.css";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { getAccount } from "redux/Account";
import { getUser } from "redux/User/User.actions";

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      if (userHasAuthenticated) {
        userHasAuthenticated(true);
        dispatch(getUser());
        dispatch(getAccount());
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <TextField
          id="email"
          label="Email"
          type="email"
          value={fields.email}
          onChange={handleFieldChange}
        />
        <TextField
          id="password"
          label="Password"
          type="Password"
          value={fields.password}
          onChange={handleFieldChange}
        />
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}
