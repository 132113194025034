const dev = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-west-2",
    BUCKET: "dev-option-journal-s3-uploads4f6eb0fd-3c902dqdyjfa", //"notes-app-2-api-dev-attachmentsbucket-qvdowkch2uj1"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://amsglpdsqg.execute-api.us-west-2.amazonaws.com/dev", //"https://api.serverless-stack.seed-demo.club/dev"
  },
  apiGatewayOptionOrders: {
    REGION: "us-west-2",
    URL: "https://api.smartoptionsai.com/dev",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_NkeqtV2I1", //"us-east-1_f8l0OHPtp",
    APP_CLIENT_ID: "7pde0chnnldgt0cl2aquulf9ck", //"5r5o292r1n7s2vgje5u5c7vpq0",
    IDENTITY_POOL_ID: "us-west-2:13785e06-3c6e-4440-9feb-11320ef30f79", //"us-east-1:b718098b-fe72-410b-b4c6-0750f9135672"
  },
  growthBook: {
    url: "https://cdn.growthbook.io/api/features/sdk-VPVeKbW5Oc2hG",
    key: "sdk-VPVeKbW5Oc2hG",
  },
};

const prod = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-west-2",
    BUCKET: "dev-option-journal-s3-uploads4f6eb0fd-3c902dqdyjfa", //"notes-app-2-api-dev-attachmentsbucket-qvdowkch2uj1"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://amsglpdsqg.execute-api.us-west-2.amazonaws.com/dev", //"https://api.serverless-stack.seed-demo.club/dev"
  },
  apiGatewayOptionOrders: {
    REGION: "us-west-2",
    URL: "https://api.smartoptionsai.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_D4NHblUAU", //"us-east-1_f8l0OHPtp",
    APP_CLIENT_ID: "5svnsj8hdb790n2842dbil2hgp", //"5r5o292r1n7s2vgje5u5c7vpq0",
    IDENTITY_POOL_ID: "us-west-2:bcddc399-3196-43ba-94cf-00609f081b98", //"us-east-1:b718098b-fe72-410b-b4c6-0750f9135672"
  },
  growthBook: {
    url: "https://cdn.growthbook.io/api/features/sdk-1vydnz4R90mD4ipw",
    key: "sdk-1vydnz4R90mD4ipw",
  },
};

console.log("env:", process.env.REACT_APP_ENV);
// Default to dev if not set
const config = process.env.REACT_APP_ENV === "production" ? prod : dev;
// eslint-disable-next-line
export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
