// See: https://usehooks-typescript.com/react-hook/use-screen
import { useEffect, useState } from "react";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;

// import React from 'react'

// import useWindowSize from './useWindowSize'

// export default function Component() {
//   const { width, height } = useWindowSize()

//   return (
//     <div>
//       The current window dimensions are:{' '}
//       <code>{JSON.stringify({ width, height })}</code>
//     </div>
//   )
// }
