import { OptionChainsTable } from "./OptionChainTable";
import { useDispatch, useSelector } from "react-redux";
import { selectorOptionChains } from "redux/Analysis/Analysis.selectors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Grid2 as Grid, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { DateTime } from "luxon";
import { updateOptionChains } from "redux/Analysis";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
export const OptionChains = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectorOptionChains);
  // const [selected, setSelected] = useState<string[]>([]);

  // const handleChange = (selected: SelectChangeEvent<string[]>) => {
  //   const {
  //     target: { value },
  //   } = selected;

  //   // console.log(value);
  //   if (Array.isArray(value)) {
  //     dispatch(updateOptionChains(value));
  //     setSelected(value);
  //   }
  // };
  return (
    <Box className="noDrag">
      <Grid container spacing={3} padding={0}>
        <Grid size={12}>
          {Object.keys(data).map((expDate) => {
            const dte = Math.round(
              DateTime.fromFormat(expDate, "yyyy-MM-dd").diffNow("days").days
            );
            const expDTE = `${expDate} (${dte} Days)`;
            return (
              <Accordion
                key={`${expDate}-accordion`}
                elevation={4}
                slotProps={{ transition: { unmountOnExit: true } }}
                onChange={(event, expanded) => {
                  if (expanded) {
                    dispatch(updateOptionChains([expDate]));
                    // setSelected(value);
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1-content"
                  id="expDate-header"
                >
                  <Typography>{expDTE}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <OptionChainsTable strikes={data[expDate]} exp={expDate} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
