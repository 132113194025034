import { useState } from "react";
import clsx from "clsx";
import { useFormFields } from "libs/hooksLib";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoaderButton from "components/LoaderButton";
import { Activity, getStrategyTemplate, StrategyEnum } from "journal-lib";
import { useDispatch, useSelector } from "react-redux";
import { getEntries } from "redux/JournalEntries";
import { selectorAccountStore } from "redux/Account/Account.selectors";
import { selectorAddEntryFromOrdersDrawer } from "redux/UI/UI.selectors";
import {
  toggleAddEntryDrawer,
  toggleAddEntryFromOrdersDrawer,
} from "redux/UI/UI.actions";
import { getGridSize, usePhoneScreenMedia } from "utils";
import Autocomplete from "@mui/material/Autocomplete";
import { getExpDate } from "redux/Stock/Stock.actions";
import { useEffect } from "react";
import { forwardRef } from "react";
import { useAddEntryStyles } from "./AddEntryFromOrders.styles";
import { API } from "aws-amplify";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { postFeed } from "redux/Feed";
import ReactDOMServer from "react-dom/server";
import { FeedContent } from "components/FeedContent/FeedContent";
import { selectorUser } from "redux/User";
import { getDiscord } from "libs/journalEntry";

export const AddEntryFromOrders = forwardRef(
  (
    {
      isDialog = true,
      fields_parent,
      handleFieldChange_parent,
      totalPrice_parent,
      setTotalPrice_parent,
      validateMode = false,
    }: {
      isDialog?: boolean;
      fields_parent?: any;
      handleFieldChange_parent?: any;
      totalPrice_parent?: number;
      setTotalPrice_parent?: any;
      validateMode?: boolean;
    },
    ref
  ) => {
    const classes = useAddEntryStyles();
    const dispatch = useDispatch();

    const [orders, setOrders] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<any>({});
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);
    const [days, setDays] = useState("1d");
    const [symbol, setSymbol] = useState("");
    const [selectionModel, setSelectionModel] = useState<any>([]);
    const [selectedSymbol, setSelectionSymbol] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isOrderGridLoading, setIsOrderGridLoading] = useState(true);
    const [notes, setNotes] = useState("");
    const [customStrategy, setCustomStrategy] = useState("");

    const isOpen = useSelector(selectorAddEntryFromOrdersDrawer);
    const user = useSelector(selectorUser);

    const getOrders = (account = "") => {
      setIsOrderGridLoading(true);
      // setOrders([]);
      const accountId = account == "" ? fields_.account.value : account;
      const searchSymbol =
        fields_.symbol == "" ? "" : `&symbol=${fields_.symbol.toUpperCase()}`;
      API.get(
        "optionOrder",
        `/order/orders/${accountId}?dateOption=${days}&perPage=${pageSize}&pageOffset=${page}${searchSymbol}`,
        {}
      ).then((orders) => {
        if (accountId in orders) {
          setOrders(orders[accountId].data);
          setPagination(orders[accountId].pagination);
          setIsOrderGridLoading(false);
        }
      });
    };

    useEffect(() => {
      if (isOpen) getOrders();
    }, [pageSize, page, days, symbol, isOpen]);
    // const [showError, setShowError] = useState(false);
    // const [showPL, setShowPL] = useState(false);

    // useEffect(() => {
    //   setShowError(validateMode);
    // }, [validateMode]);

    const accounts = useSelector(selectorAccountStore);
    const accountArray: { value: string; label: string }[] = Object.keys(
      accounts
    ).map((accountId) => {
      return {
        value: accountId,
        label: accounts[accountId].description,
      };
    });

    // const expDates = useSelector(selectorStockExpDate);
    // const expDatesSelectOptions = expDates?.map((string) => ({
    //   label: string,
    //   value: string,
    // }));
    const [postToFeed, setPostToFeed] = useState(true);
    // const [totalPrice_, setTotalPrice_] = useState(0);
    // const totalPrice = totalPrice_parent ? totalPrice_parent : totalPrice_;
    // const setTotalPrice = setTotalPrice_parent
    //   ? setTotalPrice_parent
    //   : setTotalPrice_;
    const [fields_, handleFieldChange_] = useFormFields({
      symbol: "",
      name: { value: "ShortPut", label: "Short Put" },
      // entryDate: getNowDateString(),
      // price: 0,
      // debitCredit: { value: "credit", label: "Credit" },
      // size: 1,
      account:
        accountArray?.length > 0
          ? { value: accountArray[0].value, label: accountArray[0].label }
          : { value: "", label: "" },
      days: { label: "3 Days", value: "3d" },
      // expDate: { label: "", value: "" },
      // expDateInput: "",
      // note: "",
      // strikes: "",

      // type1: { value: "sto", label: "Sell to Open" },
      // strike1: 0,
      // expDate1: { label: "", value: "" },
      // expDate1Input: "",
      // qty1: 0,
      // putCall1: { value: "put", label: "Put" },
      // type2: { value: "sto", label: "Sell to Open" },
      // strike2: 0,
      // expDate2: { label: "", value: "" },
      // expDate2Input: "",
      // qty2: 0,
      // putCall2: { value: "put", label: "Put" },
      // type3: { value: "sto", label: "Sell to Open" },
      // strike3: 0,
      // expDate3: { label: "", value: "" },
      // expDate3Input: "",
      // qty3: 0,
      // putCall3: { value: "put", label: "Put" },
      // type4: { value: "sto", label: "Sell to Open" },
      // strike4: 0,
      // expDate4: { label: "", value: "" },
      // expDate4Input: "",
      // qty4: 0,
      // putCall4: { value: "put", label: "Put" },
    });
    // const [fields_label, handleFieldChange_label] = useFormFields({
    //   name: { value: "ShortPut", label: "Short Put" },
    //   debitCredit: { value: "credit", label: "Credit" },
    //   account: "",
    //   type1: { value: "sto", label: "Sell to Open" },
    //   type2: { value: "sto", label: "Sell to Open" },
    //   type3: { value: "sto", label: "Sell to Open" },
    //   type4: { value: "sto", label: "Sell to Open" },
    // });
    const fields = isDialog ? fields_ : fields_parent;

    // const previewPL = () => {
    //   if (validateForm.all.isValid()) {
    //     dispatch(getEntryPreviewPLChartOnNew(getAddBody()));
    //     setShowPL(true);
    //   }
    // };

    const handleFieldChange = isDialog
      ? handleFieldChange_
      : handleFieldChange_parent;

    // const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
    //   if (
    //     event.type === "keydown" &&
    //     (event.key === "Tab" || event.key === "Shift")
    //   ) {
    //     return;
    //   }

    //   dispatch(toggleAddEntryDrawer());
    // };

    // const validateForm: formValidation = {
    //   account: {
    //     isValid: () => {
    //       if (!isDialog) return true;

    //       return fields["account"]?.value?.length > 0;
    //     },
    //     msg: "Please choose an account",
    //   },
    //   symbol: {
    //     isValid: () => {
    //       if (!isDialog) return true;
    //       return fields["symbol"]?.length > 0;
    //     },
    //     msg: "Please enter a symbol",
    //   },
    //   price: {
    //     isValid: () => {
    //       if (!isDialog) return true;
    //       if (fields["name"].value != "Custom") return fields["price"] >= 0;
    //       else return true;
    //     },
    //     msg: "Price must be positive",
    //   },
    //   size: {
    //     isValid: () => {
    //       if (fields["name"].value != "Custom") return fields["size"] >= 1;
    //       else return true;
    //     },
    //     msg: "Size must be greater than 1",
    //   },
    //   strikes: {
    //     isValid: () => {
    //       if (fields["name"].value == "Custom") return true;

    //       const strikes: string = fields["strikes"];

    //       const key = fields.name.value as keyof typeof StrategyEnum;

    //       if (!Strategy[StrategyEnum[key]]) return false;

    //       const strategyObject = Strategy[StrategyEnum[key]];
    //       const strikeArray = strikes.split("/");

    //       if (!strategyObject.strikes) return true;

    //       if (strikes.trim().length == 0) return false;

    //       if (fields.name.value == "CoveredCall") {
    //         if (strikeArray.length != 1) return false;
    //       } else if (strikeArray.length != strategyObject.strikes.length) {
    //         return false;
    //       }

    //       let sType = "";
    //       let nMin = 0;

    //       for (
    //         let nIndex = 0;
    //         nIndex < strategyObject.strikes.length;
    //         nIndex++
    //       ) {
    //         if (
    //           nMin == 0 ||
    //           sType == "" ||
    //           sType != strategyObject.strikes[nIndex].putCall
    //         ) {
    //           nMin = Number(strikeArray[nIndex]);
    //           sType = strategyObject.strikes[nIndex].putCall;
    //           continue;
    //         }

    //         if (nMin > Number(strikeArray[nIndex])) {
    //           return false;
    //         } else {
    //           nMin = Number(strikeArray[nIndex]);
    //         }
    //       }

    //       return true;
    //     },
    //     msg: "",
    //   },
    //   expDate: {
    //     isValid: () => {
    //       const input = fields["expDateInput"];
    //       const select = fields["expDate"].value;
    //       const strategy = fields["name"].value;

    //       if (
    //         strategy !== "LongStock" &&
    //         strategy !== "ShortStock" &&
    //         strategy !== "LongFuture" &&
    //         strategy !== "ShortFuture" &&
    //         strategy !== "Custom"
    //       ) {
    //         if (input && input.trim().length > 0) {
    //           const inputDate = moment(input, "MM/DD/YYYY");
    //           if (!inputDate.isValid()) return false;

    //           if (inputDate >= moment().startOf("d")) {
    //             if (expDates.length > 1) {
    //               if (expDates.indexOf(input) >= 0) {
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             }
    //           }
    //         } else return false;
    //       }

    //       return true;
    //     },
    //     msg: "Please select a valid expiration date.",
    //   },
    //   expDate1: {
    //     isValid: () => {
    //       const input = fields["expDate1Input"];
    //       const select = fields["expDate1"].value;
    //       const strategy = fields["name"].value;

    //       if (strategy === "Custom") {
    //         if (input && input.trim().length > 0) {
    //           const inputDate = moment(input, "MM/DD/YYYY");
    //           if (!inputDate.isValid()) return false;

    //           if (inputDate >= moment().startOf("d")) {
    //             if (expDates.length > 1) {
    //               if (expDates.indexOf(input) >= 0) {
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             }
    //           }
    //         } else return false;
    //       }

    //       return true;
    //     },
    //     msg: "Please select a valid expiration date.",
    //   },
    //   strike1: {
    //     isValid: () => {
    //       if (fields["name"].value != "Custom") return true;

    //       return fields["strike1"] >= 1;
    //     },
    //     msg: "Strike must be greater than 0",
    //   },
    //   qty1: {
    //     isValid: () => {
    //       return fields["strike1"] >= 1 ? fields["qty1"] != 0 : true;
    //     },
    //     msg: "QTY must not be 0",
    //   },
    //   expDate2: {
    //     isValid: () => {
    //       const input = fields["expDate2Input"];
    //       const select = fields["expDate2"].value;
    //       const strategy = fields["name"].value;

    //       if (strategy === "Custom") {
    //         if (input && input.trim().length > 0) {
    //           const inputDate = moment(input, "MM/DD/YYYY");
    //           if (!inputDate.isValid()) return false;

    //           if (inputDate >= moment().startOf("d")) {
    //             if (expDates.length > 1) {
    //               if (expDates.indexOf(input) >= 0) {
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             }
    //           }
    //         }
    //       }

    //       return true;
    //     },
    //     msg: "Please select a valid expiration date.",
    //   },
    //   strike2: {
    //     isValid: () => {
    //       if (fields["expDate2Input"].trim().length > 0)
    //         return fields["strike2"] >= 1;

    //       return true;
    //     },
    //     msg: "Strike must be greater than 0",
    //   },
    //   qty2: {
    //     isValid: () => {
    //       return fields["strike2"] >= 1 ? fields["qty2"] != 0 : true;
    //     },
    //     msg: "QTY must not be 0",
    //   },
    //   expDate3: {
    //     isValid: () => {
    //       const input = fields["expDate3Input"];
    //       const select = fields["expDate3"].value;
    //       const strategy = fields["name"].value;

    //       if (strategy === "Custom") {
    //         if (input && input.trim().length > 0) {
    //           const inputDate = moment(input, "MM/DD/YYYY");
    //           if (!inputDate.isValid()) return false;

    //           if (inputDate >= moment().startOf("d")) {
    //             if (expDates.length > 1) {
    //               if (expDates.indexOf(input) >= 0) {
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             }
    //           }
    //         }
    //       }

    //       return true;
    //     },
    //     msg: "Please select a valid expiration date.",
    //   },
    //   strike3: {
    //     isValid: () => {
    //       if (fields["expDate3Input"].trim().length > 0)
    //         return fields["strike3"] >= 1;

    //       return true;
    //     },
    //     msg: "Strike must be greater than 0",
    //   },
    //   qty3: {
    //     isValid: () => {
    //       return fields["strike3"] >= 1 ? fields["qty3"] != 0 : true;
    //     },
    //     msg: "QTY must not be 0",
    //   },
    //   expDate4: {
    //     isValid: () => {
    //       const input = fields["expDate4Input"];
    //       const select = fields["expDate4"].value;
    //       const strategy = fields["name"].value;

    //       if (strategy === "Custom") {
    //         if (input && input.trim().length > 0) {
    //           const inputDate = moment(input, "MM/DD/YYYY");
    //           if (!inputDate.isValid()) return false;

    //           if (inputDate >= moment().startOf("d")) {
    //             if (expDates.length > 1) {
    //               if (expDates.indexOf(input) >= 0) {
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             }
    //           }
    //         }
    //       }

    //       return true;
    //     },
    //     msg: "Please select a valid expiration date.",
    //   },
    //   strike4: {
    //     isValid: () => {
    //       if (fields["expDate4Input"].trim().length > 0)
    //         return fields["strike4"] >= 1;

    //       return true;
    //     },
    //     msg: "Strike must be greater than 0",
    //   },
    //   qty4: {
    //     isValid: () => {
    //       return fields["strike4"] >= 1 ? fields["qty4"] != 0 : true;
    //     },
    //     msg: "QTY must not be 0",
    //   },

    //   all: {
    //     isValid: () => {
    //       for (const key in validateForm) {
    //         // console.log(key);
    //         if (key != "all" && !validateForm[key].isValid()) return false;
    //       }
    //       return true;
    //     },
    //     msg: "",
    //   },
    // };

    // const isFieldValid = (sField: string) =>
    //   validateField(sField, validateForm);
    // const fieldErrorMsg = (sField: string) =>
    //   getFieldErrorMsg(sField, validateForm);

    // useImperativeHandle(ref, () => ({
    //   validateAll() {
    //     return validateForm.all.isValid();
    //   },
    // }));

    // const getAddBody = () => {
    //   let price = totalPrice;
    //   if (
    //     fields.name.value === "ShortStock" ||
    //     fields.name.value === "LongStock"
    //   )
    //     price = totalPrice / 100;

    //   return {
    //     ...fields,
    //     symbol: fields.symbol.toUpperCase(),
    //     account: fields.account.value,
    //     name: fields.name.value,
    //     type1: fields.type1.value,
    //     type2: fields.type2.value,
    //     type3: fields.type3.value,
    //     type4: fields.type4.value,
    //     putCall1: fields.putCall1.value,
    //     putCall2: fields.putCall2.value,
    //     putCall3: fields.putCall3.value,
    //     putCall4: fields.putCall4.value,
    //     price: fields["debitCredit"].value === "credit" ? price : price * -1,
    //     entryDate: stringToUTC(fields.entryDate),
    //     expDate:
    //       fields.expDate.value.trim().length > 0
    //         ? stringToUTC(fields.expDate.value)
    //         : stringToUTC(fields["expDateInput"]),
    //     expDate1:
    //       fields.expDate1.value.trim().length > 0
    //         ? stringToUTC(fields.expDate1.value)
    //         : stringToUTC(fields["expDate1Input"]),
    //     expDate2:
    //       fields.expDate2.value.trim().length > 0
    //         ? stringToUTC(fields.expDate2.value)
    //         : stringToUTC(fields["expDate2Input"]),
    //     expDate3:
    //       fields.expDate3.value.trim().length > 0
    //         ? stringToUTC(fields.expDate3.value)
    //         : stringToUTC(fields["expDate3Input"]),
    //     expDate4:
    //       fields.expDate4.value.trim().length > 0
    //         ? stringToUTC(fields.expDate4.value)
    //         : stringToUTC(fields["expDate4Input"]),

    //     postToFeed: postToFeed,
    //     note: fields.note,
    //   };
    // };

    async function handleSubmit(event: any) {
      setIsLoading(true);
      event.preventDefault();

      // if (validateForm.all.isValid()) {
      //   try {
      //     dispatch(addEntry(getAddBody()));
      //     setIsLoading(false);
      //   } catch (e) {
      //     setIsLoading(false);
      //   }
      // } else {
      //   setShowError(true);
      //   setIsLoading(false);
      // }
      if (selectionModel.length > 0) {
        const selectedOrders = orders?.filter((order) => {
          if (selectionModel.indexOf(order.id) > -1) {
            return true;
          } else return false;
        });
        const accountId = fields_.account.value;
        if (selectedOrders && accountId && selectedOrders.length > 0) {
          const payload = {
            orders: selectedOrders,
            notes: notes,
            customStrategy: customStrategy,
          };
          // console.log("payload", payload);
          API.post("optionOrder", `/order/orders/${accountId}`, {
            body: payload,
          })
            .then((res) => {
              if (postToFeed) {
                const activity: Activity =
                  res.position.activities[res.position.activities.length - 1];
                dispatch(
                  postFeed({
                    content: ReactDOMServer.renderToString(
                      FeedContent({
                        activity: activity,
                        entry: res,
                      })
                    ),
                    visibility: "public",
                    discord: getDiscord(activity, res, user),
                    channel: "trades",
                  })
                );
              }
              dispatch(getEntries({}));
              setSelectionModel([]);
              setSelectionSymbol("");
              // setOrders([]);
              dispatch(toggleAddEntryFromOrdersDrawer());
              setCustomStrategy("");
              setNotes("");
            })
            .catch((err) => {
              console.log(err?.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else setIsLoading(false);
    }

    let strategyItemList: { value: string; label: string }[] = [];
    for (const strategy in StrategyEnum) {
      const strategyKey = strategy as keyof typeof StrategyEnum;
      if (getStrategyTemplate(StrategyEnum[strategyKey])?.name)
        strategyItemList.push({
          value: strategy,
          label: getStrategyTemplate(StrategyEnum[strategyKey])?.name,
        });
    }

    // const getStrikeHelperText = () => {
    //   if (fields.name && fields.name.value && fields.name.value.length > 0) {
    //     const key = fields.name.value as keyof typeof StrategyEnum;
    //     return getStrategyTemplate(StrategyEnum[key]).example;
    //   } else return "";
    // };

    // const getTotalPriceMultiplier = (e: any) => {
    //   const qtyArray = (
    //     e.target.id === "price"
    //       ? [fields.qty1, fields.qty2, fields.qty3, fields.qty4]
    //       : [
    //           e.target.id === "qty1" ? e.target.value : fields.qty1,
    //           e.target.id === "qty2" ? e.target.value : fields.qty2,
    //           e.target.id === "qty3" ? e.target.value : fields.qty3,
    //           e.target.id === "qty4" ? e.target.value : fields.qty4,
    //         ]
    //   ).filter((qty) => qty !== 0 && qty !== "");

    //   if (qtyArray.length > 1) {
    //     return Math.abs(GetCommonDiv(qtyArray));
    //   } else if (qtyArray.length === 1) {
    //     return Math.abs(qtyArray[0]);
    //   } else {
    //     return 0;
    //   }
    // };

    const orderGridColDef: GridColDef[] = [
      { field: "underlying-symbol", headerName: "Symbol", width: 130 },
      {
        field: "price",
        headerName: "Fill",
        width: 110,
        renderCell: (params) => {
          if (params.row["price-effect"] == "Debit")
            return (
              <>
                {params.value}
                <Typography id="db" variant="caption">
                  db
                </Typography>
              </>
            );
          else
            return (
              <>
                {params.value}
                <Typography id="cr" variant="caption">
                  cr
                </Typography>
              </>
            );
        },
      },
      { field: "datetime", headerName: "Date", width: 120 },
      {
        field: "legs",
        headerName: "Description",
        flex: 1,
        renderCell: (params) => {
          if (params.row.legs.length == 0) {
            return <></>;
          } else {
            return (
              <Grid container spacing={0}>
                {params.row.legs.map((leg: any) => {
                  // console.log(leg);
                  let bIsBuy = true;
                  if (
                    leg.action == "Sell to Open" ||
                    leg.action == "Sell to Close" ||
                    leg.action == "Sell"
                  )
                    bIsBuy = false;
                  const bHasExpDate = leg?.symbolMeta?.expDate != undefined;
                  const expDate = leg?.symbolMeta?.expDate
                    ? DateTime.fromISO(leg.symbolMeta.expDate)
                    : DateTime.utc();
                  let sAction = "";
                  const qty = bIsBuy ? leg.quantity : leg.quantity * -1;
                  if (leg.action == "Sell to Close") sAction = "STC";
                  else if (leg.action == "Sell to Open") sAction = "STO";
                  else if (leg.action == "Buy to Close") sAction = "BTC";
                  else if (leg.action == "Buy to Open") sAction = "BTO";
                  else if (leg.action == "Buy") sAction = "BTO";
                  else if (leg.action == "Sell") sAction = "STO";
                  return (
                    <Grid
                      size={12}
                      container
                      spacing={0}
                      style={{ height: "19px" }}
                    >
                      <Grid size={1}>
                        <Typography variant="caption">{qty}</Typography>
                      </Grid>
                      <Grid size={2}>
                        <Typography variant="caption">
                          {bHasExpDate
                            ? expDate.toLocal().toFormat("MMM dd")
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid size={1}>
                        <Typography variant="caption">
                          {bHasExpDate
                            ? `${Math.round(expDate.diffNow("days").days)}d`
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid size={2}>
                        <Typography variant="caption">
                          {leg.symbolMeta?.strike}
                        </Typography>
                      </Grid>
                      <Grid size={1}>
                        <Typography variant="caption">
                          {leg.symbolMeta?.optionType == "put"
                            ? "P"
                            : leg.symbolMeta?.optionType == "call"
                            ? "C"
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid size={1}>
                        <Typography variant="caption" id={bIsBuy ? "cr" : "db"}>
                          {sAction}
                        </Typography>
                      </Grid>
                      {/* {`${leg.quantity} ${
                        bHasExpDate
                          ? `${expDate
                              .toLocal()
                              .toFormat("MMM dd")} ${Math.round(
                              expDate.diffNow("days").days
                            )}`
                          : ""
                      } ${leg.symbolMeta?.strike} ${
                        leg.symbolMeta?.optionType == "put"
                          ? "P"
                          : leg.symbolMeta?.optionType == "call"
                          ? "C"
                          : ""
                      } 
                      ${sAction}`} */}
                    </Grid>
                  );
                })}
              </Grid>
            );
          }
        },
      },
    ];
    const orderGridRows = () => {
      return orders?.map((order: any) => {
        return {
          ...order,
          datetime: DateTime.fromISO(order["received-at"]).toLocaleString({
            ...DateTime.DATETIME_SHORT,
            year: undefined,
            hourCycle: "h24",
          }),
          id: order.id,
          symbol: order.symbol,
          fill: order.fill,
          legs: order.legs,
        };
      });
    };
    const list = (anchor: string) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        //   onClick={toggleDrawer(anchor, false)}
        //   onKeyDown={toggleDrawer(anchor, false)}
      >
        <Grid container spacing={3} style={{ padding: "0 20px" }}>
          {[
            {
              id: "account",
              label: "Account",
              type: "select",
              items: accountArray,
              xsSize: getGridSize(6),
              gridSize: getGridSize(3),
              // error: !isFieldValid("account"),
              // helperText: fieldErrorMsg("account"),
              dialogOnly: true,
              autoFocus: true,
            },
            {
              id: "days",
              label: "Date Filter",
              type: "select",
              items: [
                {
                  value: "1d",
                  label: "1 Day",
                },
                {
                  value: "3d",
                  label: "3 Days",
                },
                {
                  value: "7d",
                  label: "7 Days",
                },
                {
                  value: "30d",
                  label: "30 Days",
                },
                {
                  value: "60d",
                  label: "60 Days",
                },
                {
                  value: "90d",
                  label: "90 Days",
                },
                {
                  value: "180d",
                  label: "180 Days",
                },
              ],
              xsSize: getGridSize(6),
              gridSize: getGridSize(3),
              // error: !isFieldValid("days"),
              // helperText: fieldErrorMsg("days"),
              dialogOnly: true,
              autoFocus: false,
            },
            {
              id: "symbol",
              label: "Symbol",
              type: "text",
              xsSize: getGridSize(6),
              gridSize: getGridSize(3),
              dialogOnly: true,
            },
            // {
            //   id: "symbol",
            //   label: "Symbol",
            //   type: "text",
            //   xsSize: getGridSize(6),
            //   gridSize: getGridSize(3),
            //   error: !isFieldValid("symbol"),
            //   helperText: fieldErrorMsg("symbol"),
            //   dialogOnly: true,
            // },
            // {
            //   id: "name",
            //   label: "Strategy",
            //   type: "select",
            //   items: strategyItemList,
            //   xsSize: getGridSize(6),
            //   gridSize: isDialog ? getGridSize(4) : getGridSize(12),
            //   dialogOnly: false,
            // },
            // {
            //   id: "debitCredit",
            //   label: "Debit/Credit",
            //   type: "select",
            //   items: [
            //     { value: "debit", label: "Debit" },
            //     { value: "credit", label: "Credit" },
            //   ],
            //   xsSize: getGridSize(6),
            //   gridSize: getGridSize(2),
            //   dialogOnly: true,
            // },
            // {
            //   id: "entryDate",
            //   label: "Entry Date",
            //   type: "date",
            //   xsSize: getGridSize(6),
            //   gridSize: getGridSize(4),
            //   dialogOnly: true,
            // },
            // {
            //   id: "price",
            //   label: "Entry Price",
            //   type: "number",
            //   xsSize: getGridSize(6),
            //   gridSize: getGridSize(4),
            //   error: !isFieldValid("price"),
            //   helperText: fieldErrorMsg("price"),
            //   dialogOnly: true,
            // },
            // {
            //   id: "totalPrice",
            //   label: "Total Price",
            //   type: "number",
            //   xsSize: getGridSize(6),
            //   gridSize: getGridSize(4),
            //   helperText: "Price must be positive",
            //   dialogOnly: true,
            // },
          ].map((text, index) =>
            !isDialog && text.dialogOnly ? (
              <div key={text.id} />
            ) : text.type === "select" ? (
              <Grid
                size={{
                  xs: text.xsSize ? text.xsSize : text.gridSize,
                  sm: text.xsSize ? text.gridSize : undefined,
                }}
                key={text.id}
              >
                <Autocomplete
                  value={fields[text.id]}
                  onChange={(event, newValue) => {
                    if (text.id === "account") {
                      setIsOrderGridLoading(true);
                      setPage(0);
                      setOrders([]);
                      if (isOpen) getOrders(newValue.value);
                    } else if (text.id == "days") {
                      setIsOrderGridLoading(true);
                      setDays(newValue.value);
                      setPage(0);
                      // getOrders();
                    }

                    if (newValue) {
                      handleFieldChange(
                        { target: { id: text.id, value: newValue } },
                        text.id
                      );
                    } else {
                      handleFieldChange(
                        { target: { id: text.id, value: "" } },
                        text.id
                      );
                    }
                  }}
                  // inputValue={fields_label[text.id]}
                  // onInputChange={(event, newValue) => {
                  //   handleFieldChange_label(
                  //     { target: { id: text.id, value: newValue } },
                  //     text.id
                  //   );
                  // }}
                  autoSelect
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                  }}
                  id={text.id}
                  options={text.items ? text.items : []}
                  getOptionLabel={(option: any) =>
                    option?.label ? option.label : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={text.label}
                      autoFocus={text.autoFocus ? true : false}
                      variant="standard"
                    />
                  )}
                  // fullWidth
                />
                {/* <FormControl fullWidth>
                <InputLabel id={`${text.id}-label`}>{text.label}</InputLabel>
                <Select
                  fullWidth
                  labelId={`${text.id}-label`}
                  id={text.id}
                  value={fields[text.id]}
                  onChange={(event) => {
                    handleFieldChange(event, text.id);
                  }}
                >
                  {text.items ? (
                    text.items.map((menuItem) => (
                      <MenuItem
                        value={menuItem.value}
                        id={text.id}
                        key={text.id}
                      >
                        {menuItem.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={" "}> </MenuItem>
                  )}
                </Select>
              </FormControl> */}
              </Grid>
            ) : (
              // : (
              //   <></>
              // )
              // text.type === "date" ? (
              //   <Grid
              //     item
              //     xs={text.xsSize ? text.xsSize : text.gridSize}
              //     sm={text.xsSize ? text.gridSize : undefined}
              //     key={text.id}
              //   >
              //     <TextField
              //       fullWidth
              //       id={text.id}
              //       label={text.label}
              //       value={fields[text.id]}
              //       onChange={handleFieldChange}
              //       type="date"
              //     />
              //   </Grid>
              // )
              <Grid
                size={{
                  xs: text.xsSize ? text.xsSize : text.gridSize,
                  sm: text.xsSize ? text.gridSize : undefined,
                }}
                key={text.id}
              >
                <TextField
                  variant="standard"
                  className={clsx({
                    [classes.debit]:
                      (text.id === "price" || text.id === "totalPrice") &&
                      fields.debitCredit.value === "debit",
                    [classes.credit]:
                      (text.id === "price" || text.id === "totalPrice") &&
                      fields.debitCredit.value === "credit",
                  })}
                  // error={showError && text.error ? text.error : false}
                  // helperText={showError && text.error ? text.helperText : ""}
                  fullWidth
                  id={text.id}
                  label={text.label}
                  type={text.type}
                  value={fields[text.id]}
                  onBlur={() => {
                    if (
                      text.id === "symbol" &&
                      fields.symbol &&
                      fields.symbol.trim().length > 0
                    )
                      dispatch(getExpDate(fields.symbol));
                  }}
                  // onKeyDown={(e) => {
                  // if (text.id === "symbol" && e.key == "Enter") getOrders();
                  // }}
                  onChange={(e) => {
                    // if (text.id === "totalPrice") {
                    //   setTotalPrice(+e.target.value);
                    // } else {
                    setPage(0);
                    if (text.id === "symbol") setSymbol(e?.target.value);
                    handleFieldChange(e);

                    // if (text.id === "price") {
                    //   setTotalPrice(
                    //     Math.round(
                    //       +e.target.value *
                    //         (fields.name.value === "Custom"
                    //           ? getTotalPriceMultiplier(e)
                    //           : fields.size) *
                    //         100
                    //     ) / 100
                    //   );
                    // }
                    // }
                  }}
                  inputProps={
                    text.id === "symbol"
                      ? { style: { textTransform: "uppercase" } }
                      : text.id === "totalPrice"
                      ? { readOnly: true }
                      : text.type === "number"
                      ? { step: 0.01, inputMode: "decimal" }
                      : { step: 0.01 }
                  }
                />
              </Grid>
            )
          )}
          <Grid size={1}>
            <Button
              onClick={() => {
                getOrders();
              }}
              // className={classes.button}
            >
              Refresh Orders
            </Button>
          </Grid>
          <Grid size={4}>
            <TextField
              id="customStrategy"
              label="Custom Strategy"
              value={customStrategy}
              fullWidth
              variant="standard"
              onChange={(e) => {
                setCustomStrategy(e.target.value);
              }}
            />
          </Grid>
          <Grid size={8}>
            <TextField
              id="notes"
              label="Notes"
              value={notes}
              fullWidth
              variant="standard"
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </Grid>
          <Grid size={12}>
            <div style={{ width: "100%", height: "600px" }}>
              <DataGrid
                className={classes.orderRoot}
                loading={isOrderGridLoading}
                rows={orderGridRows()}
                columns={orderGridColDef}
                checkboxSelection
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(selectionModel) => {
                  if (selectionModel.length == 0) {
                    setSelectionModel([]);
                    setSelectionSymbol("");
                  } else {
                    setSelectionModel(selectionModel);
                    orders?.map((order) => {
                      if (selectionModel.indexOf(order.id) > -1) {
                        setSelectionSymbol(order["root-symbol"]);
                      }
                    });
                  }
                }}
                isRowSelectable={(params) => {
                  if (
                    params.row.Linked != undefined &&
                    params.row.Linked == true
                  )
                    return false;
                  else if (selectedSymbol == "") return true;
                  else if (params.row["root-symbol"] == selectedSymbol)
                    return true;
                  else return false;
                }}
                getRowClassName={(params) => {
                  if (
                    params.row.Linked != undefined &&
                    params.row.Linked == true
                  )
                    return classes.grayorderRow;
                  else if (selectedSymbol == "") return classes.orderRow;
                  else if (params.row["root-symbol"] == selectedSymbol)
                    return classes.orderRow;
                  else return classes.grayorderRow;
                }}
                getCellClassName={() => classes.orderCell}
                paginationModel={{
                  pageSize: pageSize,
                  page: page,
                }}
                onPaginationModelChange={(model) => {
                  setIsOrderGridLoading(true);
                  setPageSize(model.pageSize);
                  setPage(model.page);
                }}
                pageSizeOptions={[25, 50, 100]}
                rowCount={pagination ? pagination["total-items"] : 0}
                paginationMode="server"
              />
            </div>
          </Grid>
          {/* <Divider />

          {fields["name"].value === "Custom"
            ? [
                // {
                //   id: "type1",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate1",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate1"),
                  helperText:
                    showError && !isFieldValid("expDate1")
                      ? fieldErrorMsg("expDate1")
                      : "",
                },
                {
                  id: "strike1",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike1"),
                  helperText:
                    showError && !isFieldValid("strike1")
                      ? fieldErrorMsg("strike1")
                      : "",
                },
                {
                  id: "putCall1",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty1",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty1"),
                  helperText:
                    showError && !isFieldValid("qty1")
                      ? fieldErrorMsg("qty1")
                      : "",
                },
                // {
                //   id: "type2",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate2",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate2"),
                  helperText:
                    showError && !isFieldValid("expDate2")
                      ? fieldErrorMsg("expDate2")
                      : "",
                },
                {
                  id: "strike2",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike2"),
                  helperText:
                    showError && !isFieldValid("strike2")
                      ? fieldErrorMsg("strike2")
                      : "",
                },
                {
                  id: "putCall2",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty2",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty2"),
                  helperText:
                    showError && !isFieldValid("qty2")
                      ? fieldErrorMsg("qty2")
                      : "",
                },
                // {
                //   id: "type3",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate3",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate3"),
                  helperText:
                    showError && !isFieldValid("expDate3")
                      ? fieldErrorMsg("expDate3")
                      : "",
                },
                {
                  id: "strike3",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike3"),
                  helperText:
                    showError && !isFieldValid("strike3")
                      ? fieldErrorMsg("strike3")
                      : "",
                },
                {
                  id: "putCall3",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty3",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty3"),
                  helperText:
                    showError && !isFieldValid("qty3")
                      ? fieldErrorMsg("qty3")
                      : "",
                },
                // {
                //   id: "type4",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate4",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate4"),
                  helperText:
                    showError && !isFieldValid("expDate4")
                      ? fieldErrorMsg("expDate4")
                      : "",
                },
                {
                  id: "strike4",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike4"),
                  helperText:
                    showError && !isFieldValid("strike4")
                      ? fieldErrorMsg("strike4")
                      : "",
                },
                {
                  id: "putCall4",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty4",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty4"),
                  helperText:
                    showError && !isFieldValid("qty4")
                      ? fieldErrorMsg("qty4")
                      : "",
                },
              ].map((text, index) => (
                <Grid
                  item
                  xs={text.xsSize ? text.xsSize : text.gridSize}
                  sm={text.xsSize ? text.gridSize : undefined}
                  key={text.id}
                >
                  {text.type === "select" ? (
                    <Autocomplete
                      value={fields[text.id]}
                      freeSolo
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleFieldChange(
                            { target: { id: text.id, value: newValue } },
                            text.id
                          );
                        } else {
                          handleFieldChange(
                            { target: { id: text.id, value: "" } },
                            text.id
                          );
                        }
                      }}
                      // inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        handleFieldChange(
                          {
                            target: {
                              id: `${text.id}Input`,
                              value: newInputValue,
                            },
                          },
                          `${text.id}Input`
                        );
                      }}
                      id={text.id}
                      getOptionSelected={(option, value) => {
                        return option.value === value.value;
                      }}
                      options={
                        text.items
                          ? text.items
                          : text.id.includes("type")
                          ? [
                              { label: "Sell to Open", value: "sto" },
                              { label: "Buy to Open", value: "bto" },
                            ]
                          : [
                              { label: "Put", value: "put" },
                              { label: "Call", value: "call" },
                            ]
                      }
                      getOptionLabel={(option: any) =>
                        option && option.label ? option.label : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={text.label}
                          error={text.error}
                          helperText={text.helperText}
                        />
                      )}
                      // fullWidth
                    />
                  ) : (
                    // <FormControl fullWidth>
                    //   <InputLabel id={`${text.id}-label`}>
                    //     {text.label}
                    //   </InputLabel>
                    //   <Select
                    //     fullWidth
                    //     labelId={`${text.id}-label`}
                    //     id={text.id}
                    //     value={fields[text.id]}
                    //     onChange={(e) => {
                    //       handleFieldChange(e, text.id);
                    //     }}
                    //   >
                    //     <MenuItem value={"sto"}>Sell to Open</MenuItem>
                    //     <MenuItem value={"bto"}>Buy to Open</MenuItem>
                    //   </Select>
                    // </FormControl>
                    // text.type == "date" ? (
                    //   <KeyboardDatePicker
                    //     disableToolbar
                    //     variant="inline"
                    //     format="MM/dd/yyyy"
                    //     margin="normal"
                    //     id={text.id}
                    //     label={text.label}
                    //     value={fields[text.id]}
                    //     onChange={handleFieldChange}
                    //     KeyboardButtonProps={{
                    //       "aria-label": text.label,
                    //     }}
                    //   />
                    // ) :
                    <TextField
                      fullWidth
                      id={text.id}
                      label={text.label}
                      type={text.type}
                      value={fields[text.id]}
                      onChange={(e) => {
                        handleFieldChange(e);
                        if (
                          text.id === "qty1" ||
                          text.id === "qty2" ||
                          text.id === "qty3" ||
                          text.id === "qty4"
                        ) {
                          setTotalPrice(
                            Math.round(
                              fields.price * getTotalPriceMultiplier(e) * 100
                            ) / 100
                          );
                        }
                      }}
                      InputLabelProps={
                        text.type === "date"
                          ? {
                              shrink: true,
                            }
                          : {}
                      }
                      inputProps={
                        text.type === "number" ? { inputMode: "tel" } : {}
                      }
                      error={text.error}
                      helperText={text.helperText}
                    />
                  )}
                </Grid>
              ))
            : [
                {
                  id: "expDate",
                  label: "Expiration Date",
                  type: "select",
                  items: expDates?.map((string) => ({
                    label: string,
                    value: string,
                  })),
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(4),
                  error: showError && !isFieldValid("expDate"),
                  helperText:
                    showError && !isFieldValid("expDate")
                      ? fieldErrorMsg("expDate")
                      : "",
                },
                {
                  id: "strikes",
                  label: "Strikes",
                  type: "text",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(5),
                  error: showError && !isFieldValid("strikes"),
                  helperText: getStrikeHelperText(),
                },
                {
                  id: "size",
                  label: "Size",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("size"),
                  helperText:
                    showError && !isFieldValid("size")
                      ? fieldErrorMsg("size")
                      : "",
                },
              ].map((text, index) => (
                <Grid
                  item
                  xs={text.xsSize ? text.xsSize : text.gridSize}
                  sm={text.xsSize ? text.gridSize : undefined}
                  key={text.id}
                >
                  {text.type === "select" ? (
                    <Autocomplete
                      value={fields[text.id]}
                      freeSolo
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleFieldChange(
                            { target: { id: text.id, value: newValue } },
                            text.id
                          );
                        } else {
                          handleFieldChange(
                            { target: { id: text.id, value: "" } },
                            text.id
                          );
                        }
                      }}
                      // inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        handleFieldChange(
                          {
                            target: {
                              id: `${text.id}Input`,
                              value: newInputValue,
                            },
                          },
                          `${text.id}Input`
                        );
                      }}
                      id={text.id}
                      getOptionSelected={(option, value) => {
                        return option.value === value.value;
                      }}
                      options={
                        text.items ? text.items : [{ label: "", value: "" }]
                      }
                      getOptionLabel={(option: any) =>
                        option && option.label ? option.label : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={text.label}
                          error={text.error}
                          helperText={text.helperText}
                        />
                      )}
                      // fullWidth
                    />
                  ) : (
                    <TextField
                      fullWidth
                      id={text.id}
                      label={text.label}
                      type={text.type}
                      value={fields[text.id]}
                      onChange={(e) => {
                        handleFieldChange(e);

                        if (text.id === "size")
                          setTotalPrice(
                            Math.round(fields.price * +e.target.value * 100) /
                              100
                          );
                      }}
                      InputLabelProps={
                        text.type === "date"
                          ? {
                              shrink: true,
                            }
                          : {}
                      }
                      error={text.error}
                      helperText={text.helperText}
                      inputProps={
                        text.type === "number" ? { inputMode: "numeric" } : {}
                      }
                    />
                  )}
                </Grid>
              ))}
          <Grid xs={12} item>
            <TextField
              fullWidth
              id="note"
              label="Notes"
              value={fields.note}
              onChange={handleFieldChange}
            />
          </Grid> */}
        </Grid>
      </div>
    );

    const onClosing = () => {
      dispatch(toggleAddEntryFromOrdersDrawer());
      setIsLoading(false);
      // setShowError(false);
    };

    const isPhoneScreen = usePhoneScreenMedia();

    useEffect(() => {
      if (isOpen) getOrders();
    }, [isOpen]);
    // const reset = () => {
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "symbol",
    //         value: "",
    //       },
    //     },
    //     "symbol"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "size",
    //         value: 1,
    //       },
    //     },
    //     "size"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "entryDate",
    //         value: getNowDateString(),
    //       },
    //     },
    //     "entryDate"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "price",
    //         value: 0,
    //       },
    //     },
    //     "price"
    //   );
    //   setTotalPrice(0);
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate",
    //         value: { label: "", value: "" },
    //       },
    //     },
    //     "expDate"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDateInput",
    //         value: "",
    //       },
    //     },
    //     "expDateInput"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "note",
    //         value: "",
    //       },
    //     },
    //     "note"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "strikes",
    //         value: "",
    //       },
    //     },
    //     "strikes"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "type1",
    //         value: { value: "sto", label: "Sell to Open" },
    //       },
    //     },
    //     "type1"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "strike1",
    //         value: 0,
    //       },
    //     },
    //     "strike1"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate1",
    //         value: { label: "", value: "" },
    //       },
    //     },
    //     "expDate1"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate1Input",
    //         value: "",
    //       },
    //     },
    //     "expDate1Input"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "qty1",
    //         value: 0,
    //       },
    //     },
    //     "qty1"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "putCall1",
    //         value: { value: "put", label: "Put" },
    //       },
    //     },
    //     "putCall1"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "type2",
    //         value: { value: "sto", label: "Sell to Open" },
    //       },
    //     },
    //     "type2"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "strike2",
    //         value: 0,
    //       },
    //     },
    //     "strike2"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate2",
    //         value: { label: "", value: "" },
    //       },
    //     },
    //     "expDate2"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate2Input",
    //         value: "",
    //       },
    //     },
    //     "expDate2Input"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "qty2",
    //         value: 0,
    //       },
    //     },
    //     "qty2"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "putCall2",
    //         value: { value: "put", label: "Put" },
    //       },
    //     },
    //     "putCall2"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "type3",
    //         value: { value: "sto", label: "Sell to Open" },
    //       },
    //     },
    //     "type3"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "strike3",
    //         value: 0,
    //       },
    //     },
    //     "strike3"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate3",
    //         value: { label: "", value: "" },
    //       },
    //     },
    //     "expDate3"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate3Input",
    //         value: "",
    //       },
    //     },
    //     "expDate3Input"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "qty3",
    //         value: 0,
    //       },
    //     },
    //     "qty3"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "putCall3",
    //         value: { value: "put", label: "Put" },
    //       },
    //     },
    //     "putCall3"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "type4",
    //         value: { value: "sto", label: "Sell to Open" },
    //       },
    //     },
    //     "type4"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "strike4",
    //         value: 0,
    //       },
    //     },
    //     "strike4"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate4",
    //         value: { label: "", value: "" },
    //       },
    //     },
    //     "expDate4"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "expDate4Input",
    //         value: "",
    //       },
    //     },
    //     "expDate4Input"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "qty4",
    //         value: 0,
    //       },
    //     },
    //     "qty4"
    //   );
    //   handleFieldChange(
    //     {
    //       target: {
    //         id: "putCall4",
    //         value: { value: "put", label: "Put" },
    //       },
    //     },
    //     "putCall4"
    //   );
    //   dispatch(clearExpDate());
    //   // setShowPL(false);
    // };

    return isDialog ? (
      <Dialog
        open={isOpen}
        onClose={onClosing}
        fullWidth
        maxWidth="md"
        fullScreen={isPhoneScreen}
        // onExited={reset}
        className={classes.addOrderEntry}
      >
        <form
          className="AddForm"
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <DialogTitle id="add-entry-dialog-title">
            Select Filled Orders to Create Entry
          </DialogTitle>

          <DialogContent style={{ overflow: "hidden" }}>
            <br />
            {list("right")}
            {/* {showPL ? (
              <Grid
                item
                xs={12}
                container
                justify="flex-end"
                style={{ height: "250px" }}
              >
                <PLChart isPreview={true} />
              </Grid>
            ) : null} */}
          </DialogContent>

          <DialogActions>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <Button
                  onClick={() => {
                    dispatch(toggleAddEntryFromOrdersDrawer());
                    dispatch(toggleAddEntryDrawer());
                  }}
                  className={classes.button}
                >
                  Add Manually
                </Button>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <Box>
                    Post activity to news feed?
                    <Checkbox
                      value={postToFeed}
                      defaultChecked
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={(event, checked) => setPostToFeed(checked)}
                    />
                  </Box>
                  <LoaderButton
                    type="submit"
                    // bsSize="large"
                    isLoading={isLoading}
                    color="primary"
                    // disabled={!validateform.all()}
                  >
                    Add Entry
                  </LoaderButton>
                  <Button color="secondary" onClick={onClosing}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    ) : (
      list("right")
    );
  }
);

export default AddEntryFromOrders;
