import { Box, Grid, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { selectorEarningDates } from "redux/JournalEntries";

export const EarningCalendar = () => {
  const earningDate = useSelector(selectorEarningDates);

  return (
    (<Grid container>
      {earningDate
        ? Object.entries(earningDate)
            .sort((a, b) => (a[1] > b[1] ? 1 : -1))
            .map((symbol: string[]) => (
              <Grid
                xs={9}
                item
                container
                justifyContent="space-between"
                key={symbol[0]}
              >
                <Link href={`/symbol/${symbol[0]}`}>{symbol[0]}</Link>
                <Box>{symbol[1]}</Box>
              </Grid>
            ))
        : null}
    </Grid>)
  );
};
