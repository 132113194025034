import makeStyles from "@mui/styles/makeStyles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Avatar, Button, Grid } from "@mui/material";
import { red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { selectorUser } from "redux/User";
import clsx from "clsx";
import { FeedEditor } from "components";
import { postFeed } from "redux/Feed";
import { Person } from "@mui/icons-material";
import { createTheme } from "@mui/material";
import { useState } from "react";
const theme = createTheme();

const useStyles = makeStyles(() => ({
  myTimeLine: {
    paddingRight: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginTop: "2px",
    marginBottom: "0",
    "&:hover": {
      backgroundColor: "#F3F3F3b0",
    },
    "&>li>div>div": {
      backgroundColor: "transparent",
    },
    "& .MuiAvatar-colorDefault": {
      backgroundColor: "#bdbdbd !important",
    },
  },
  bottomBorder: {
    borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    borderColor: "#EBEEF0",
  },
  zeroPadding: {
    padding: "0 0 0 65px",
  },
  root: {
    "& .MuiTimelineContent-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    "& .MuiTimelineOppositeContent-root": {
      flex: 0,
      padding: 0,
    },
  },
  myAvatar: {
    width: "48px",
    height: "48px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  thumbnail: {
    width: "36px",
    height: "36px",
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardRoot: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  feedContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
}));

export const WriteFeed = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isWriting, setIsWriting] = useState(false);
  const user = useSelector(selectorUser);

  const sendNewFeed = (event: any, htmlText: string) => {
    dispatch(
      postFeed({
        content: htmlText,
        visibility: "public",
        discord: {
          username: user.name,
          content: htmlText.replace(/(<([^>]+)>)/gi, ""),
        },
        channel: "chat",
      })
    );
    setIsWriting(false);
  };

  const cancelClicked = () => {
    setIsWriting(false);
  };

  return (
    <Timeline className={clsx(classes.myTimeLine, classes.bottomBorder)}>
      <TimelineItem className={classes.root}>
        <TimelineOppositeContent />
        <TimelineSeparator>
          <Avatar className={classes.myAvatar}>
            <Person />
          </Avatar>
        </TimelineSeparator>
        <TimelineContent>
          {isWriting ? (
            <Grid container alignItems="center">
              <Grid item xs={12} style={{ paddingLeft: "10px" }}>
                <FeedEditor
                  value=""
                  buttonLabel="Send"
                  onClicked={sendNewFeed}
                  onCancelClicked={cancelClicked}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" style={{ height: "100%" }}>
              <Grid item xs={12} style={{ padding: "0 10px" }}>
                <Button onClick={() => setIsWriting(true)}>
                  What's in your mind?
                </Button>
              </Grid>
            </Grid>
          )}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
