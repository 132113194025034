import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Hidden,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { FilterList } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectorAccountStore } from "redux/Account/Account.selectors";
import {
  addFilterBySymbol,
  removeFilterBySymbol,
  selectorFilterByAccount,
  selectorFilterByDate,
  selectorFilterByStatus,
  selectorFilterBySymbols,
  setFilterByAccount,
  setFilterByDate,
  setFilterByStatus,
  setFilterBySymbols,
} from "redux/UI";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: "32px",
    },
  })
);

const StyledCheckbox = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 9px",
    },
  })
)(Checkbox);

const StyledRadio = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 9px",
    },
  })
)(Radio);

export const FilterEntries = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [openSymbolDialog, setOpenSymbolDialog] = React.useState(false);
  const [filterSymbol, setFilterSymbol] = useState("");

  const statusFilter = useSelector(selectorFilterByStatus);

  const handleChangeStatusFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setFilterByStatus(event.target.value));
  };

  const accountFilter = useSelector(selectorFilterByAccount);

  const handleChangeAccountFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setFilterByAccount(event.target.value));
  };

  const dateFilter = useSelector(selectorFilterByDate);

  const handleChangeDateFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      dispatch(setFilterByDate(event.target.name));
    } else {
      dispatch(setFilterByDate(""));
    }
  };

  // const [fields_, handleFieldChange_] = useFormFields({
  //   symbol: "",
  //   account: { value: "", label: "" },
  // });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ChangeFilterSymbol = (event: any) => {
    setFilterSymbol(event.target.value);
  };

  const AddFilterSymbol = (event: any) => {
    event.preventDefault();
    dispatch(addFilterBySymbol(filterSymbol.toUpperCase()));
    setFilterSymbol("");
  };

  const removeFilterSymbol = (event: any) => {
    dispatch(removeFilterBySymbol(event.target.name));
  };

  const filterSymbols = useSelector(selectorFilterBySymbols);

  const showAll = () => {
    dispatch(setFilterByStatus("All"));
    dispatch(setFilterByAccount("All"));
    dispatch(setFilterByDate(""));
    dispatch(setFilterBySymbols([]));
    handleClose();
  };

  // const filterBySymbol = () => {
  //   setOpenSymbolDialog(true);
  //   handleClose();
  // };

  // const filterBySymbolSubmitted = (event: any) => {
  //   event.preventDefault();
  //   dispatch(setFilterModel("symbol"));
  //   dispatch(setFilterBySymbol(fields_.symbol));
  //   handleClose();
  // };

  const accounts = useSelector(selectorAccountStore);
  const accountArray: { value: string; label: string }[] = Object.keys(
    accounts
  ).map((accountId) => {
    return {
      value: accountId,
      label: accounts[accountId].description,
    };
  });

  // const filterByAccount = () => {
  //   setOpenAccountDialog(true);
  //   handleClose();
  // };

  // const filterByAccountSubmitted = (event: any) => {
  //   event.preventDefault();
  //   dispatch(setFilterModel("account"));
  //   dispatch(setFilterByAccount(fields_.account));
  //   handleClose();
  // };

  return (
    <Box>
      <Hidden smUp>
        <IconButton aria-label="handleClick" onClick={handleClick} size="large">
          <FilterList />
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <Button startIcon={<FilterList />} onClick={handleClick}>
          FILTERS
        </Button>
      </Hidden>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List component="div" disablePadding>
          <ListItem dense>Symbol</ListItem>
          <ListItem dense className={classes.nested}>
            <form onSubmit={AddFilterSymbol}>
              <TextField
                id="outlined-basic"
                // label="Outlined"
                variant="outlined"
                size="small"
                placeholder="Hit Enter after typing a symbol to filter"
                value={filterSymbol}
                onChange={ChangeFilterSymbol}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </form>
          </ListItem>
          {filterSymbols?.map((symbol) => (
            <ListItem dense key={symbol}>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={true}
                    onChange={removeFilterSymbol}
                    name={symbol}
                    color="primary"
                  />
                }
                label={symbol}
              />
            </ListItem>
          ))}
        </List>
        <List component="div" disablePadding>
          <ListItem dense>Status</ListItem>
          <ListItem dense className={classes.nested}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="account"
                name="account"
                value={statusFilter}
                onChange={handleChangeStatusFilter}
              >
                <FormControlLabel
                  value="All"
                  control={<StyledRadio color="primary" />}
                  label="All"
                />
                <FormControlLabel
                  value="Open"
                  control={<StyledRadio color="primary" />}
                  label="Open"
                />
                <FormControlLabel
                  value="Closed"
                  control={<StyledRadio color="primary" />}
                  label="Closed"
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem dense>Account</ListItem>
          <ListItem dense className={classes.nested}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="status"
                name="status"
                value={accountFilter}
                onChange={handleChangeAccountFilter}
              >
                <FormControlLabel
                  value="All"
                  control={<StyledRadio color="primary" />}
                  label="All"
                />
                {accountArray.map((acc) => (
                  <FormControlLabel
                    value={acc.value}
                    control={<StyledRadio color="primary" />}
                    label={acc.label}
                    key={acc.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
        <Divider />
        <List component="div" disablePadding>
          <ListItem dense>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={dateFilter === "TodayTrades"}
                  onChange={handleChangeDateFilter}
                  name="TodayTrades"
                  color="primary"
                />
              }
              label="Today Trades"
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={dateFilter === "ExpThisMonth"}
                  onChange={handleChangeDateFilter}
                  name="ExpThisMonth"
                  color="primary"
                />
              }
              label="Expiring This Month"
            />
          </ListItem>
          <ListItem dense>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={dateFilter === "ExpNextMonth"}
                  onChange={handleChangeDateFilter}
                  name="ExpNextMonth"
                  color="primary"
                />
              }
              label="Expiring Next Month"
            />
          </ListItem>
        </List>
        {/* <MenuItem onClick={filterBySymbol}>Filter by Symbol</MenuItem>
        <MenuItem onClick={allOpen}>All Open Positions</MenuItem>
        <MenuItem onClick={filterByAccount}>Filter by Account</MenuItem>
        <Divider />
        <MenuItem onClick={todayTrades}>Today's Trades</MenuItem>
        <MenuItem onClick={handleClose}>Expiring This Month</MenuItem>
        <MenuItem onClick={handleClose}>Expiring Next Month</MenuItem> */}
        <Divider />
        <MenuItem onClick={showAll}>Show All</MenuItem>
      </Menu>
      {/* <FormDialog
        OpenState={openSymbolDialog}
        SetOpenState={setOpenSymbolDialog}
        DialogTitle="Please enter ticker symbol."
        HideButton={true}
        onSubmit={filterBySymbolSubmitted}
        FormArray={[
          {
            fieldProps: {
              fullWidth: true,
              id: "symbol",
              label: "Symbol",
              value: fields_.symbol,
              onChange: handleFieldChange_,
              autoFocus: true,
              inputProps: {
                style: { textTransform: "uppercase" },
              },
            },
            gridSize: getGridSize(12),
          },
        ]}
      />
      <FormDialog
        OpenState={openAccountDialog}
        SetOpenState={setOpenAccountDialog}
        DialogTitle="Please select an account."
        HideButton={true}
        onSubmit={filterByAccountSubmitted}
        FormArray={[
          {
            fieldProps: {
              type: "select",
              fullWidth: true,
              id: "account",
              label: "Account",
              value: fields_.account,
              onChange: (e, newValue) => {
                if (newValue) {
                  handleFieldChange_(
                    { target: { id: "account", value: newValue } },
                    "account"
                  );
                } else {
                  handleFieldChange_(
                    { target: { id: "account", value: "" } },
                    "account"
                  );
                }
              },
              inputProps: {
                style: { textTransform: "uppercase" },
              },
              options: accountArray,
              getOptionLabel: (option: any) =>
                option?.label ? option.label : "",
            },
            gridSize: getGridSize(12),
          },
        ]}
      /> */}
    </Box>
  );
};
