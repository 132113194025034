import { StoreState } from "redux/store.types";
import { createSelector } from "reselect";

// const namespacedStore = (state: NamespacedStoreState) => state[moduleNamespace];
const storeState = (state: StoreState) => state;

export const selectorAnalysisStore = createSelector(
  storeState,
  (state) => state?.analysis
);

export const selectorParameters = createSelector(
  selectorAnalysisStore,
  (state) => {
    if (state?.parameters) return state?.parameters;
    else
      return {
        t: [],
        includeExpiration: false,
      };
  }
);

export const selectorT = createSelector(selectorParameters, (state) => {
  if (state) return state?.t;
  else return [];
});

export const selectorIncludeExpiration = createSelector(
  selectorParameters,
  (state) => {
    if (state) return state?.includeExpiration;
    else return false;
  }
);

export const selectorEntries = createSelector(
  selectorAnalysisStore,
  (state) => state?.entries
);

export const selectorRate = createSelector(
  selectorAnalysisStore,
  (state) => state?.rate
);

export const selectorRateSetDate = createSelector(
  selectorAnalysisStore,
  (state) => state?.rateSetDate
);

export const selectorOptionChains = createSelector(
  selectorAnalysisStore,
  (state) => state?.optionChains
);

export const selectorModeling = createSelector(
  selectorAnalysisStore,
  (state) => state?.modeling
);

export const selectorEnabledModeling = createSelector(
  selectorModeling,
  (state) => state?.volatilityModeling
);

export const selectorVolatilityAdjustment = createSelector(
  selectorModeling,
  (state) => state?.volatilityAdjustment
);

export const selectorVolatilityPointPercent = createSelector(
  selectorModeling,
  (state) => {
    if (state != undefined && state.volatilityPointPercent != undefined)
      return state.volatilityPointPercent;
    else return "Percent";
  }
);

export const selectorModelQty = createSelector(selectorModeling, (state) => {
  if (state == undefined || state.modelQty == undefined) return {};
  else return state.modelQty;
});

export const selectorPositionModeling = createSelector(
  selectorModeling,
  (state) => {
    if (state == undefined || state.positionModeling == undefined) return false;
    else return state.positionModeling;
  }
);
