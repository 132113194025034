import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { useDispatch, useSelector } from "react-redux";
import { addT, deleteT, setIncludeExpiration } from "redux/Analysis";
import { selectorParameters } from "redux/Analysis/Analysis.selectors";
import "./TSeries.css";
import { useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid2 as Grid,
  Paper,
} from "@mui/material";

export const TSeries = () => {
  const dispatch = useDispatch();
  const [newT, setNewT] = useState<string>("");
  const parameters = useSelector(selectorParameters);
  const t = parameters?.t;
  const includeExpiration = parameters?.includeExpiration;
  const tRef = useRef<TextBoxComponent>(null);

  return (
    <Box>
      <Paper elevation={1} sx={{ p: 2, marginTop: "16px" }}>
        <Grid container spacing={2}>
          <Grid size={6} container>
            <FormControlLabel
              className="noDrag"
              control={<Checkbox />}
              label="Include Expiration"
              //   value={enableModeling}
              checked={includeExpiration}
              onChange={(_, value) => dispatch(setIncludeExpiration(value))}
            />
          </Grid>
          <Grid size={6} container>
            <Grid size={12} container>
              <Grid size={11}>
                <TextBoxComponent
                  className="noDrag"
                  placeholder="Add a T+ line"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  value={newT}
                  ref={tRef}
                  onChange={(e: any) => {
                    setNewT(e.value.toString());
                  }}
                />
              </Grid>
              <Grid size={1} sx={{ padding: "0 0 0 10px" }}>
                <ButtonComponent
                  cssClass="e-small e-round addTBtn noDrag"
                  iconCss="e-btn-sb-icons e-add-icon"
                  aria-label="button"
                  isPrimary
                  onClick={() => {
                    tRef.current?.setProperties({ value: "" });
                    dispatch(addT(+newT));
                    tRef.current?.focusIn();
                  }}
                ></ButtonComponent>
              </Grid>
            </Grid>
            <Grid size={12}>
              <Grid size={12}>
                {t?.map(
                  (item) => (
                    // item != null ? (
                    <Chip
                      className="noDrag"
                      key={item?.toString()}
                      label={item?.toString()}
                      onDelete={() => dispatch(deleteT(+item))}
                    ></Chip>
                  )
                  // ) : (
                  //   <></>
                  // )
                )}
                {/* <ChipListComponent
                  id="chip-avatar"
                  enableDelete={true}
                  aria-labelledby="inputChips"
                  delete={deleteEvent}
                  delayUpdate={true}
                >
                  <ChipsDirective>
                    {t?.map(
                      (item) => (
                        // item != null ? (
                        <ChipDirective
                          key={item?.toString()}
                          text={item?.toString()}
                        ></ChipDirective>
                      )
                      // ) : (
                      //   <></>
                      // )
                    )}
                  </ChipsDirective>
                </ChipListComponent> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* <Paper elevation={1} sx={{ p: 2, marginTop: "16px" }}>
        <Grid container spacing={2}>
          <Grid size={6} container>
            <TextBoxComponent
              placeholder="X - Min"
              cssClass="e-outline"
              floatLabelType="Auto"
              value={newT}
              ref={tRef}
              onChange={(e: any) => {
                setNewT(e.value.toString());
              }}
            />
          </Grid>
          <Grid size={6} container>
            <TextBoxComponent
              placeholder="X - Max"
              cssClass="e-outline"
              floatLabelType="Auto"
              value={newT}
              ref={tRef}
              onChange={(e: any) => {
                setNewT(e.value.toString());
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={1} sx={{ p: 2, marginTop: "16px" }}>
        <Grid container spacing={2}>
          <Grid size={6} container>
            <TextBoxComponent
              placeholder="Y - Min"
              cssClass="e-outline"
              floatLabelType="Auto"
              value={newT}
              ref={tRef}
              onChange={(e: any) => {
                setNewT(e.value.toString());
              }}
            />
          </Grid>
          <Grid size={6} container>
            <TextBoxComponent
              placeholder="Y - Max"
              cssClass="e-outline"
              floatLabelType="Auto"
              value={newT}
              ref={tRef}
              onChange={(e: any) => {
                setNewT(e.value.toString());
              }}
            />
          </Grid>
        </Grid>
      </Paper> */}
    </Box>
  );
};
