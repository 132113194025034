import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event, id) {
      // setValues({
      //   ...fields,
      //   [event.target.id ? event.target.id : id]: event.target.value,
      // });
      setValues((state) => {
        if (event && event.target) {
          return {
            ...state,
            [event.target.id ? event.target.id : id]: event.target.value,
          };
        }
        return state;
      });
    },
  ];
}
