import makeStyles from "@mui/styles/makeStyles";
import { createTheme } from "@mui/material";
const theme = createTheme();

export const useAddLegsStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
    // width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  debit: {
    "& input": {
      color: "red",
    },
  },
  credit: {
    "& input": {
      color: "green",
    },
  },
  noPadding: {
    paddingLeft: "0",
    paddingRight: "0",
  },
}));
