const isLocal = process.env.NODE_ENV === "development";
// console.log(process.env.NODE_ENV);
export function initSentry() {
  if (isLocal) {
    return;
  }

  // Sentry.init({
  //   dsn: "https://793ee15477ae4d10baf7c5669b282c23@o573421.ingest.sentry.io/5723914",
  //   integrations: [new Integrations.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    console.log(error);
    console.log(errorInfo);
    return;
  }

  // Sentry.withScope((scope) => {
  //   errorInfo && scope.setExtras(errorInfo);
  //   Sentry.captureException(error);
  // });
}

export function onError(error) {
  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  //alert(message);
}
