import React, { useEffect } from "react";
import { Feeds } from "components/Feeds/Feeds.component";
import { useDispatch } from "react-redux";
import { getFeed } from "redux/Feed";
import { useParams } from "react-router";

export const FeedPage = () => {
  const { feedId } = useParams<{ feedId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeed({ feedId: feedId, clearFeed: true }));
  });
  return <Feeds />;
};
