const tradingViewSymbol: { [key: string]: string } = {
  "/ES": "CME_MINI:ES1!",
  "/NQ": "CME_MINI:NQ1!",
  "/RTY": "CME_MINI:RTY1!",

  "/6A": "CME:6A1!",
  "/6B": "CME:6B1!",
  "/6C": "CME:6C1!",
  "/6E": "CME:6E1!",
  "/6J": "CME:6J1!",
  "/6M": "CME:6M1!",
  "/HE": "CME:HE1!",

  "/HG": "COMEX:HG1!",
  "/GC": "COMEX:GC1!",
  "/SI": "COMEX:SI1!",

  "/ZN": "CBOT:ZN1!",
  "/ZB": "CBOT:ZN1!",
  "/UB": "CBOT:UB1!",
  "/ZC": "CBOT:ZC1!",
  "/ZF": "CBOT:ZF1!",
  "/ZS": "CBOT:ZS1!",
  "/ZT": "CBOT:ZT1!",
  "/ZW": "CBOT:ZW1!",

  "/CL": "NYMEX:CL1!",
  "/NG": "NYMEX:NG1!",
};

export const getTradingViewSymbol = (symbol: string): string => {
  if (symbol in tradingViewSymbol) {
    return tradingViewSymbol[symbol] as string;
  } else return symbol;
};
