import { AnyAction, createReducer } from "@reduxjs/toolkit";
import { editUserResolved, getUserResolved, resetUser } from "./User.actions";
import { UserStoreState } from "./User.types";

const initialState: UserStoreState = {
  user: {
    userName: "",
    name: "",
    email: "",
    thumbnail: "",
    identityId: "",
    groupIds: [],
    discordUserName: "",
    tags: [],
    phoneNumber: "",
  },
};

interface UserReducer {
  [x: string]: (
    state: UserStoreState,
    action: AnyAction
  ) => UserStoreState | undefined;
}

const reducer: UserReducer = {
  [resetUser.toString()]: () => {
    return initialState;
  },
  [getUserResolved.toString()]: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  [editUserResolved.toString()]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      name: action.payload.name ? action.payload.name : state.user.name,
      discordUserName: action.payload.discordUserName
        ? action.payload.discordUserName
        : state.user.discordUserName,
    },
  }),
};

export default createReducer(initialState, reducer);
