import { Button, CircularProgress } from "@mui/material";
import React from "react";
import "./LoaderButton.css";

export default function LoaderButton({
  isLoading = false,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <CircularProgress />}
      {props.children}
    </Button>
  );
}
