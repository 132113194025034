import Chart from "react-google-charts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEntryPLChart } from "redux/Stock/Stock.actions";
import { selectorStockStore } from "redux/Stock/Stock.selectors";

export const PLChart = ({
  entryId,
  isPreview = false,
}: {
  entryId?: string;
  isPreview?: boolean;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (entryId && entryId.trim().length > 0)
      dispatch(getEntryPLChart(entryId));
  }, [entryId, dispatch]);

  const {
    PLChart: PLChart_,
    PLChartReady: PLChartReady_,
    PreviewPLChart: PreviewPLChart_,
    PreviewPLChartReady: PreviewPLChartReady_,
  } = useSelector(selectorStockStore);

  const PLChart = isPreview ? PreviewPLChart_ : PLChart_;
  const PLChartReady = isPreview ? PreviewPLChartReady_ : PLChartReady_;

  // const response2 = API.get("optionOrder", "", {});
  // const currentPrice =
  //   PLChart && PLChart.length > 0
  //     ? PLChart.find((pt) => pt.CurrentPrice == true)?.x
  //     : 0;
  const data = [
    [
      { label: "Price", type: "number" },
      { label: "P/L", type: "number" },
      { id: "i0", type: "string", role: "annotation" },
      { id: "i2", type: "string", role: "annotation" },
      { id: "i1", type: "string", role: "style" },
      { label: "P/L", type: "number" },
      { id: "i2", type: "string", role: "annotation" },
      { id: "i3", type: "string", role: "annotation" },
      // { id: "i4", type: "number" },
    ],
    // ["domain", "data"],
    ...(PLChart && PLChart.length > 0
      ? PLChart?.map((pt) => [
          pt.x,
          pt.y >= 0 ? pt.y : null,
          pt.CurrentPrice ? "Current" : null,
          pt.Position ? pt.Position : null,
          "color: green",
          pt.y <= 0 ? pt.y : null,
          pt.CurrentPrice ? "Current" : null,
          pt.Position ? pt.Position : null,
          // (blackScholesCallPrice(pt.x, 5440, 14 / 365, 0.0375, 0.19571) - 110) *
          //   50,
        ])
      : []),
  ];

  return PLChartReady ? (
    <Chart
      width={"100%"}
      height={"100%"}
      chartType="AreaChart"
      loader={<div>Loading Chart</div>}
      data={data}
      options={{
        hAxis: {
          title: "Price",
          titleTextStyle: { color: "#333" },
          minValue: PLChart.length > 0 ? PLChart[0].x : 0,
          maxValue: PLChart.length > 0 ? PLChart[PLChart.length - 1].x : 1,
          // viewWindowMode: "pretty",
          // viewWindow: {
          //   min: 217.5 * 0.9,
          //   max: 250 * 1.1,
          // },
        },
        // vAxis: { minValue: -0 },
        // For the legend to fit, we make the chart area smaller
        chartArea: { width: "85%", height: "75%" },
        // lineWidth: 25
        legend: { position: "none" },
        explorer: {
          // actions: ["rightClickToReset", "dragToPan"],
          axis: "horizontal",
          keepInBounds: true,
          maxZoomIn: 3.0,
          zoomDelta: 1.3,
        },
      }}
      // For tests
      rootProps={{ "data-testid": "1" }}
    />
  ) : null;
};
