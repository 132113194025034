import { API } from "aws-amplify";
import { put, select, takeLatest } from "@redux-saga/core/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { Activity, JournalEntry } from "journal-lib";
import { onError } from "libs/errorLib";
import ReactDOMServer from "react-dom/server";
import { getDiscordParam, postFeed } from "redux/Feed";
import {
  selectorFilterByAccount,
  selectorFilterByDate,
  selectorFilterByStatus,
  selectorFilterBySymbols,
  selectorPage,
  selectorPageSize,
  selectorSortModel,
  setDetailsDrawer,
  toggleAddEntryDrawer,
  toggleAddLegsDrawer,
  toggleCloseDrawer,
  toggleDeleteDrawer,
  toggleRollDrawer,
} from "redux/UI";
import {
  addEntry,
  addEntryRejected,
  analysisEntryById,
  closeEntry,
  closeEntryRejected,
  closeEntryResolved,
  deleteActivity,
  deleteActivityRejected,
  deleteActivityResolved,
  deleteEntry,
  deleteEntryRejected,
  deleteEntryResolved,
  editActivity,
  editActivityRejected,
  editActivityResolved,
  editJournalEntry,
  editJournalEntryRejected,
  editJournalEntryResolved,
  getEarning,
  getEarningRejected,
  getEarningResolved,
  getEntries,
  getEntriesResolved,
  refreshEntries,
  refreshEntriesResolved,
  rollEntry,
  rollEntryRejected,
  rollEntryResolved,
  updateJournalEntry,
} from "./JournalEntries.actions";
import { addEntryResolved } from "./JournalEntries.actions";
import moment from "moment";
import { FeedContent } from "components/FeedContent/FeedContent";
import { selectorJournalEntries, selectorOpenSymbol } from ".";
import { GetJournalEntriesResponse } from "./JournalEntries.types";
import { getEntryPLChart } from "redux/Stock/Stock.actions";
import { setEntries } from "redux/Analysis";

function* getEntriesSaga() {
  const filterByDate: string = yield select(selectorFilterByDate);
  const filterBySymbols: string[] = yield select(selectorFilterBySymbols);
  const filterByAccount: string = yield select(selectorFilterByAccount);
  const filterByStatus: string = yield select(selectorFilterByStatus);
  const page: number = yield select(selectorPage);
  const pageSize: number = yield select(selectorPageSize);
  const sortModel: { field: string; sort: string }[] = yield select(
    selectorSortModel
  );

  let address = `/journalEntry?${
    filterBySymbols && filterBySymbols.length > 0
      ? `symbols=${filterBySymbols.join(",")}&`
      : ""
  }${
    filterByAccount && filterByAccount !== "All"
      ? `accountId=${filterByAccount}&`
      : ""
  }${
    filterByStatus && filterByStatus !== "All"
      ? `status=${filterByStatus}&`
      : ""
  }${
    filterByDate && filterByDate.trim() !== ""
      ? `dateFilter=${filterByDate}&date=${
          filterByDate === "TodayTrades"
            ? moment().startOf("day")
            : moment().startOf("month")
        }&`
      : ""
  }${`update=true&`}${`limit=${pageSize}&skip=${(page - 0) * pageSize}&`}${
    sortModel[0] && sortModel[0].field && sortModel[0].field.trim().length > 0
      ? `sort=${sortModel[0].field}&asc=${sortModel[0].sort === "asc"}&`
      : ""
  }`;
  address = address.substr(0, address.length - 1);

  const response: GetJournalEntriesResponse = yield API.get(
    "optionOrder",
    address,
    {}
  );
  yield put(getEntriesResolved(response));
}

function* watchGetJournalEntries() {
  yield takeLatest(getEntries, getEntriesSaga);
}

function* refreshEntriesSaga() {
  yield API.put("optionOrder", "/journalEntry/update", {});
  yield put(refreshEntriesResolved());
  yield put(getEntries({}));
}

function* watchRefreshJournalEntries() {
  yield takeLatest(refreshEntries, refreshEntriesSaga);
}

function* addEntrySaga(action: AnyAction) {
  try {
    const newEntry: JournalEntry = yield API.post(
      "optionOrder",
      "/journalEntry",
      {
        body: {
          ...action.payload,
          symbol: action.payload.symbol.toUpperCase(),
        },
      }
    );

    const activity: Activity =
      newEntry.position.activities[newEntry.position.activities.length - 1];

    yield put(addEntryResolved(newEntry));
    yield put(toggleAddEntryDrawer());
    if (action.payload.postToFeed) {
      yield put(
        postFeed({
          content: ReactDOMServer.renderToString(
            FeedContent({
              activity: activity,
              entry: newEntry,
            })
          ),
          visibility: "public",
          discord: yield getDiscordParam(activity, newEntry),
          channel: "trades",
        })
      );
    }
  } catch (e) {
    onError(e);
    yield put(addEntryRejected());
  }
}

function* watchGetAddEntry() {
  yield takeLatest(addEntry, addEntrySaga);
}

function* deleteEntrySaga(action: AnyAction) {
  try {
    yield API.del("optionOrder", "/journalEntry/delete", {
      body: action.payload,
    });

    yield put(deleteEntryResolved(action.payload));
    yield put(toggleDeleteDrawer());
  } catch (e) {
    onError(e);
    yield put(deleteEntryRejected());
  }
}

function* watchDeleteEntry() {
  yield takeLatest(deleteEntry, deleteEntrySaga);
}

function* closeEntrySaga(action: AnyAction) {
  try {
    const entry: JournalEntry = yield API.put(
      "optionOrder",
      "/journalEntry/close",
      {
        body: action.payload,
      }
    );

    const activity: Activity =
      entry.position.activities[entry.position.activities.length - 1];

    yield put(closeEntryResolved(action.payload));
    yield put(updateJournalEntry(entry));
    yield put(toggleCloseDrawer());

    if (entry?.position?.legs?.length === 0) {
      yield put(setDetailsDrawer(false));
    } else if (entry.entryId) {
      yield put(getEntryPLChart(entry.entryId));
    }
    if (action.payload.postToFeed) {
      yield put(
        postFeed({
          content: ReactDOMServer.renderToString(
            FeedContent({
              activity:
                entry.position.activities[entry.position.activities.length - 1],
              entry: entry,
            })
          ),
          visibility: "public",
          discord: yield getDiscordParam(activity, entry),
          channel: entry.status === "Closed" ? "profitLoss" : "trades",
        })
      );
    }
  } catch (e) {
    onError(e);
    yield put(closeEntryRejected());
  }
}

function* watchCloseEntry() {
  yield takeLatest(closeEntry, closeEntrySaga);
}

function* closeEntryResolvedSaga(action: AnyAction) {
  yield put(getEntries({}));
}

function* watchCloseEntryResolved() {
  yield takeLatest(closeEntryResolved, closeEntryResolvedSaga);
}

function* rollEntrySaga(action: AnyAction) {
  try {
    const entry: JournalEntry = yield API.put(
      "optionOrder",
      "/journalEntry/roll",
      {
        body: action.payload,
      }
    );

    const activity: Activity =
      entry.position.activities[entry.position.activities.length - 1];
    yield put(rollEntryResolved());
    yield put(updateJournalEntry(entry));
    yield put(toggleRollDrawer(false));
    yield put(toggleAddLegsDrawer(false));
    if (action.payload.postToFeed) {
      yield put(
        postFeed({
          content: ReactDOMServer.renderToString(
            FeedContent({
              activity:
                entry.position.activities[entry.position.activities.length - 1],
              entry: entry,
            })
          ),
          visibility: "public",
          discord: yield getDiscordParam(activity, entry),
          channel: "trades",
        })
      );
    }

    if (entry.entryId) yield put(getEntryPLChart(entry.entryId));
  } catch (e) {
    onError(e);
    yield put(rollEntryRejected());
  }
}

function* watchRollEntry() {
  yield takeLatest(rollEntry, rollEntrySaga);
}

function* rollEntryResolvedSaga(action: AnyAction) {
  yield put(getEntries({}));
}

function* watchRollentryResolved() {
  yield takeLatest(rollEntryResolved, rollEntryResolvedSaga);
}

function* deleteActivitySaga(action: AnyAction) {
  try {
    const entry: JournalEntry = yield API.del(
      "optionOrder",
      "/journalEntry/deleteActivity",
      {
        body: action.payload,
      }
    );

    yield put(deleteActivityResolved());
    yield put(updateJournalEntry(entry));
    if (entry.entryId) yield put(getEntryPLChart(entry.entryId));
  } catch (e) {
    onError(e);
    yield put(deleteActivityRejected());
  }
}

function* watchDeleteActivity() {
  yield takeLatest(deleteActivity, deleteActivitySaga);
}

function* editActivitySaga(action: AnyAction) {
  try {
    const entry: JournalEntry = yield API.put(
      "optionOrder",
      "/journalEntry/editActivityPrice",
      {
        body: action.payload,
      }
    );

    yield put(editActivityResolved());
    yield put(updateJournalEntry(entry));
  } catch (e) {
    onError(e);
    yield put(editActivityRejected());
  }
}

function* watchEditActivity() {
  yield takeLatest(editActivity, editActivitySaga);
}

function* getEarningSaga() {
  try {
    const openSymbols: string[] = yield select(selectorOpenSymbol);
    if (openSymbols && openSymbols.length > 0) {
      const response: {
        [symbol: string]: string;
      }[] = yield API.post("optionOrder", "/feed/earnings", {
        body: { symbols: openSymbols.join(",") },
      });
      yield put(getEarningResolved(response));
    }
  } catch (e) {
    onError(e);
    yield put(getEarningRejected());
  }
}

function* watchGetEarningDate() {
  yield takeLatest(getEarning, getEarningSaga);
}

function* editJournalEntrySaga(action: AnyAction) {
  try {
    yield API.put("optionOrder", `/journalEntry/${action.payload.entryId}`, {
      body: action.payload,
    });
    yield put(editJournalEntryResolved(action.payload));
  } catch (e) {
    onError(e);
    yield put(editJournalEntryRejected());
  }
}

function* watchEditJournalEntry() {
  yield takeLatest(editJournalEntry, editJournalEntrySaga);
}

function* analysisEntryByIdSaga(action: AnyAction) {
  try {
    const entries: JournalEntry[] = yield select(selectorJournalEntries);

    const entry = entries.filter((entry) => entry.entryId == action.payload);
    yield put(setEntries(entry));
  } catch (e) {
    onError(e);
  }
}
function* watchAnalysisEntryById() {
  yield takeLatest(analysisEntryById, analysisEntryByIdSaga);
}
const sagaArray = [
  watchGetJournalEntries(),
  watchRefreshJournalEntries(),
  watchGetAddEntry(),
  watchDeleteEntry(),
  watchCloseEntry(),
  watchCloseEntryResolved(),
  watchRollEntry(),
  watchRollentryResolved(),
  watchDeleteActivity(),
  watchEditActivity(),
  watchGetEarningDate(),
  watchEditJournalEntry(),
  watchAnalysisEntryById(),
];
export default sagaArray;
