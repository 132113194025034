import { createAction } from "@reduxjs/toolkit";
import {
  blockUserParams,
  followUserParams,
  unBlockUserParams,
  unFollowUserParams,
  User,
} from "journal-lib";
import { AddUserParams, EditUserParams } from "./User.types";

export const resetUser = createAction("user/reset");

export const followUser = createAction<followUserParams>("user/followUser");

export const followUserResolved = createAction("user/followUserResolved");

export const followUserRejected = createAction("user/followUserRejected");

export const unFollowUser =
  createAction<unFollowUserParams>("user/unFollowUser");

export const unFollowUserResolved = createAction("user/unFollowUserResolved");

export const unFollowUserRejected = createAction("user/unFollowUserRejected");

export const blockUser = createAction<blockUserParams>("user/blockUser");

export const blockUserResolved = createAction("user/blockUserResolved");

export const blockUserRejected = createAction("user/blockUserRejected");

export const unBlockUser = createAction<unBlockUserParams>("user/unBlockUser");

export const unBlockUserResolved = createAction("user/unBlockUserResolved");

export const unBlockUserRejected = createAction("user/unBlockUserRejected");

export const getUser = createAction("user/getUser");

export const getUserResolved = createAction<User>("user/getUserResolved");

export const getUserRejected = createAction("user/getUserRejected");

export const addUser = createAction<AddUserParams>("user/addUser");
export const addUserResolved = createAction<User>("user/addUserResolved");
export const addUserReject = createAction("user/addUserReject");

export const editUser = createAction<EditUserParams>("user/editUser");
export const editUserResolved = createAction<EditUserParams>(
  "user/editUserResolved"
);
export const editUserReject = createAction("user/editUserReject");
