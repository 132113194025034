export interface formValidation {
  [fieldName: string]: {
    isValid: () => boolean;
    msg: string;
  };
}

export const validateField = (
  sField: string,
  formValidation: formValidation
) => {
  if (Object.keys(formValidation).indexOf(sField) >= 0)
    return formValidation[sField].isValid();
  else return false;
};

export const getFieldErrorMsg = (
  sField: string,
  formValidation: formValidation
) => {
  if (Object.keys(formValidation).indexOf(sField) >= 0)
    return formValidation[sField].msg;
  else return "";
};
