import { createAction } from "@reduxjs/toolkit";
import { JournalEntry } from "journal-lib";
import { ModelQty, OptionChains } from "./Analysis.types";

export const resetAnalysis = createAction("analysis/reset");

export const setT = createAction<number[]>("analysis/setT");
export const setTResolved = createAction<number[]>("analysis/setTResolved");
export const setTRejected = createAction("analysis/setTRejected");

export const addT = createAction<number>("analysis/addT");
export const addTResolved = createAction<number>("analysis/addTResolved");
export const addTRejected = createAction("analysis/addTRejected");

export const deleteT = createAction<number>("analysis/deleteT");
export const deleteTResolved = createAction<number>("analysis/deleteTResolved");
export const deleteTRejected = createAction("analysis/deleteTRejected");

export const setEntries = createAction<JournalEntry[]>("analysis/setEntries");
export const setEntriesResolved = createAction<JournalEntry[]>(
  "analysis/setEntriesResolved"
);
export const setEntriesRejected = createAction("analysis/setEntriesRejected");

export const addEntries = createAction<JournalEntry[]>("analysis/addEntries");
export const addEntriesResolved = createAction("analysis/addEntriesResolved");
export const addEntriesRejected = createAction("analysis/addEntriesRejected");

export const setRate = createAction<number>("analysis/setRate");
export const setRateResolved = createAction("analysis/setRateResolved");
export const setRateRejected = createAction("analysis/setRateRejected");

export const setOptionChains = createAction<any[]>("analysis/setOptionChains");
export const setOptionChainsResolved = createAction<OptionChains>(
  "analysis/setOptionChainsResolved"
);
export const setOptionChainsRejected = createAction(
  "analysis/setOptionChainsRejected"
);

export const updateOptionChains = createAction<string[]>(
  "analysis/updateOptionChains"
);
export const updateOptionChainsResolved = createAction(
  "analysis/updateOptionChainsResolved"
);
export const updateOptionChainsRejected = createAction(
  "analysis/updateOptionChainsRejected"
);

export const setModelQty = createAction<ModelQty>("analysis/setModelQty");

export const updateModelQty = createAction<{
  exp: string;
  strike: number;
  putcall: string;
  qty: number;
}>("analysis/updateModelQty");

export const toggleEnableModeling = createAction(
  "analysis/toggleEnableModeling"
);

export const setVolatilityAdjustment = createAction<number>(
  "analysis/setVolatilityAdjustment"
);

export const setVolatilityPointPercent = createAction<"Point" | "Percent">(
  "analysis/setVolatilityPointPercent"
);

export const setIncludeExpiration = createAction<boolean>(
  "analysis/setIncludeExpiration"
);

export const setPositionModeling = createAction<boolean>(
  "analysis/setPositionModeling"
);
