import { StoreState } from "redux/store.types";
import { createSelector } from "reselect";

const storeState = (state: StoreState) => state;

export const selectUserStore = createSelector(
  storeState,
  (state) => state?.user
);

export const selectorUser = createSelector(
  selectUserStore,
  (state) => state.user
);

export const selectorBrokerage = createSelector(selectorUser, (state) =>
  state.brokerageProviders?.map((brokerage) => ({
    value: brokerage.id,
    label: brokerage.description,
  }))
);
