import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { onError } from "./libs/errorLib";
import makeStyles from "@mui/styles/makeStyles";
import ErrorBoundary from "components/ErrorBoundary";
import { AppContext } from "libs/contextLib";
import Routes from "Routes";
import AddEntry from "components/AddEntry/AddEntry.component";
import { Route, Routes as RouterRoutes } from "react-router-dom";

import { Main } from "pages";
import { AddEntryFromOrders } from "components/AddEntryFromOrders";
import { Analysis } from "pages/Analysis";
import { registerLicense } from "@syncfusion/ej2-base";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AWSKey } from "pages/AWSKey/AWSKey";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import config from "config";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: config.growthBook.key,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

const theme = createTheme();

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkxhWnpZcXRVQmZU"
  // "Ngo9BigBOggjHTQxAR8/V1NCaF1cWWhAYVJyWmFZfVpgdl9FY1ZTTGYuP1ZhSXxXdkxjXn9cc3dXTmRbWEQ="
);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    // height: "100vh",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const classes = useStyles();

  // eslint-disable-next-line
  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      growthbook.init({ streaming: true });
      Auth.currentCredentials().then((credentials) => {
        // console.log("credentials", credentials?.identityId);
        growthbook.setAttributes({
          id: credentials?.identityId,
        });
      });
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  return isAuthenticating ? (
    <div></div>
  ) : (
    <ThemeProvider theme={theme}>
      <GrowthBookProvider growthbook={growthbook}>
        <div className={classes.root}>
          <ErrorBoundary fallbackComponent={ErrorFallback}>
            <AppContext.Provider
              value={{ isAuthenticated, userHasAuthenticated }}
            >
              {isAuthenticated ? (
                <RouterRoutes>
                  <Route path="/" element={<Main />}></Route>
                  <Route
                    path="/analysis/:entryid"
                    element={<Analysis />}
                  ></Route>
                  <Route path="/analysis" element={<Analysis />}></Route>
                  <Route path="/awsKey" element={<AWSKey />}></Route>
                  <Route path="*" element={<Routes />}></Route>
                </RouterRoutes>
              ) : (
                <RouterRoutes>
                  <Route path="/" element={<Routes />}></Route>
                  <Route path="/*" element={<Routes />}></Route>
                </RouterRoutes>
              )}
              {/* <RouterRoutes>
              {isAuthenticated ? (
                <Route path="/" element={<Main />}></Route>
              ) : (
                <></>
              )}
              {isAuthenticated ? (
                <Route path="/analysis" element={<Analysis />}></Route>
              ) : (
                <></>
              )} */}
              {/* <AuthenticatedRoute exact path="/">
                <Main />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/analysis/">
                <Analysis />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/analysis/:entryid">
                <Analysis />
              </AuthenticatedRoute> */}
              {/* <Route>
                <Routes />
              </Route> */}
              {/* </RouterRoutes> */}
              <AddEntry />
              <AddEntryFromOrders />

              {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                This is a success Alert inside a Snackbar!
              </Alert>
            </Snackbar> */}
            </AppContext.Provider>
          </ErrorBoundary>
        </div>
      </GrowthBookProvider>
    </ThemeProvider>
  );
}

function ErrorFallback(): JSX.Element {
  return (
    <div className="ErrorBoundary">
      <h3>Sorry there was a problem loading this page</h3>
    </div>
  );
}

export default App;
