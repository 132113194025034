import { AnyAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { User } from "journal-lib";
import { onError } from "libs/errorLib";
import { put, takeLatest } from "redux-saga/effects";
import {
  addUser,
  addUserReject,
  addUserResolved,
  blockUser,
  blockUserRejected,
  blockUserResolved,
  editUser,
  editUserReject,
  editUserResolved,
  followUser,
  followUserRejected,
  followUserResolved,
  getUser,
  getUserRejected,
  getUserResolved,
  unBlockUser,
  unBlockUserRejected,
  unBlockUserResolved,
  unFollowUser,
  unFollowUserRejected,
  unFollowUserResolved,
} from "./User.actions";

function* followUserSaga(action: AnyAction) {
  try {
    yield put(followUserResolved());
  } catch (e) {
    onError(e);
    yield put(followUserRejected());
  }
}

function* watchfollowUser() {
  yield takeLatest(followUser, followUserSaga);
}

function* unFollowUserSaga(action: AnyAction) {
  try {
    yield put(unFollowUserResolved());
  } catch (e) {
    onError(e);
    yield put(unFollowUserRejected());
  }
}

function* watchUnFollowUser() {
  yield takeLatest(unFollowUser, unFollowUserSaga);
}

function* blockUserSaga(action: AnyAction) {
  try {
    yield put(blockUserResolved());
  } catch (e) {
    onError(e);
    yield put(blockUserRejected());
  }
}

function* watchBlockUser() {
  yield takeLatest(blockUser, blockUserSaga);
}

function* unBlockUserSaga(action: AnyAction) {
  try {
    yield put(unBlockUserResolved());
  } catch (e) {
    onError(e);
    yield put(unBlockUserRejected());
  }
}

function* watchUnBlockUser() {
  yield takeLatest(unBlockUser, unBlockUserSaga);
}

function* getUserSaga(action: AnyAction) {
  try {
    const response: User = yield API.get("optionOrder", "/user", {});

    yield put(getUserResolved(response));
  } catch (e) {
    onError(e);
    yield put(getUserRejected());
  }
}

function* watchGetUser() {
  yield takeLatest(getUser, getUserSaga);
}

function* watchAddUser() {
  yield takeLatest(addUser, addUserSaga);
}

function* addUserSaga(action: AnyAction) {
  try {
    const response: User = yield API.post("optionOrder", "/user", {
      body: action.payload,
    });
    yield put(addUserResolved(response));
  } catch (e) {
    onError(e);
    yield put(addUserReject());
  }
}

function* watchEditUser() {
  yield takeLatest(editUser, editUserSaga);
}
function* editUserSaga(action: AnyAction) {
  try {
    yield API.put("optionOrder", "/user", {
      body: action.payload,
    });
    yield put(editUserResolved(action.payload));
  } catch (e) {
    onError(e);
    yield put(editUserReject());
  }
}

const sagaArray = [
  watchfollowUser(),
  watchUnFollowUser(),
  watchBlockUser(),
  watchUnBlockUser(),
  watchGetUser(),
  watchAddUser(),
  watchEditUser(),
];
export default sagaArray;
