import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { usePhoneScreenMedia } from "utils";
import { API } from "aws-amplify";
import { getEntries, SelectSelectedEntry } from "redux/JournalEntries";

export const ChangeEntryNotes = ({
  isOpen = false,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();

  const entry = SelectSelectedEntry();
  const [customStrategy, setCustomStrategy] = useState<string>("");
  const isPhoneScreen = usePhoneScreenMedia();
  const handleClose = () => {
    onClose();
  };
  const handleChange = () => {
    API.put("optionOrder", "/journalEntry/notes", {
      body: { entryId: entry.entryId, notes: customStrategy },
    }).finally(() => {
      onClose();
      setCustomStrategy("");
      dispatch(getEntries({}));
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      fullScreen={isPhoneScreen}
    >
      <DialogTitle id="form-dialog-title">Edit Entry Notes</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Strategy Name"
          type="text"
          value={customStrategy}
          onChange={(e) => setCustomStrategy(e.target.value)}
          fullWidth
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleChange} color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeEntryNotes;
