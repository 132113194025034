import { Portal, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { FormsProps } from "./Forms.types";
import Grid from "@mui/material/Grid2";

const useStyle = makeStyles((theme) => ({
  autocompleteRoot: {
    "& button.MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
}));

export const Forms = ({
  formArray,
  includeFormTag = false,
  ...props
}: FormsProps) => {
  const formContainer = useRef(null);
  const divContainer = useRef(null);
  const classes = useStyle();
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    setShow(true);
  }, [setShow]);

  return (
    <div style={{ width: "100%" }}>
      {includeFormTag ? (
        <form style={{ width: "100%" }}>
          <Grid container spacing={3} ref={formContainer}></Grid>
        </form>
      ) : (
        <Grid size={12} container ref={divContainer}></Grid>
      )}
      {show ? (
        <Portal
          container={
            includeFormTag ? formContainer.current : divContainer.current
          }
        >
          {formArray.map((field) => {
            switch (field.fieldProps.type) {
              case "select":
                const { options = [], ...otherAutocompleteProps } =
                  field.fieldProps;
                return (
                  <Grid size={field.gridSize}>
                    <Autocomplete
                      {...otherAutocompleteProps}
                      className={classes.autocompleteRoot}
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={field.fieldProps.label}
                          autoFocus
                        />
                      )}
                    />
                  </Grid>
                );
              case "number":
              case "string":
              default:
                return (
                  <Grid size={field.gridSize}>
                    <TextField {...field.fieldProps} />
                  </Grid>
                );
            }
          })}
        </Portal>
      ) : null}
    </div>
  );
};
