export const getInitials = (name: string) => {
  if (!name || name.trim().length === 0) {
    return "";
  } else if (name.trim().includes(" ")) {
    return name
      .trim()
      .split(" ")
      .map((namePart) => namePart.substr(0, 1))
      .join("");
  } else {
    return name.trim().substr(0, 1);
  }
};
