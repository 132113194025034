import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid";
import { putCall } from "journal-lib";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModelQty } from "redux/Analysis";
import { selectorModelQty } from "redux/Analysis/Analysis.selectors";

interface strike {
  [key: string]: {
    //strike
    [key: string]: {
      //putcall
      expDate: string;
      optionSymbol: string;
      streamerSymbol: string;
      strike: number;
      symbol: string;
      type: putCall;
      greeks: { volatility: number; delta: number; gamma: number };
      isOption: boolean;
      theoPrice: boolean;
    };
  };
}

interface OptionChainsTableProps {
  strikes: strike;
  exp: string;
}

/**
 * @description
 * @param {OptionChainsTableProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const OptionChainsTable = ({ strikes, exp }: OptionChainsTableProps) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  let tempCallQty = 0;
  let tempPutQty = 0;
  const modelQty = useSelector(selectorModelQty);
  const dispatch = useDispatch();

  // let gridInstance: GridComponent | null = null;
  const rows = Object.values(strikes).map((strike) => {
    // console.log("strikes", strike);
    const rowObject: any = {};
    rowObject["putQty"] = 0;
    rowObject["callQty"] = 0;
    if ("put" in strike) {
      rowObject["symbol"] = strike.put.symbol;
      rowObject["putOptionSymbol"] = strike.put.optionSymbol;
      rowObject["putStreamerSymbol"] = strike.put.streamerSymbol;
      rowObject["strike"] = strike.put.strike;
      rowObject["id"] = strike.put.strike;
      rowObject["putDelta"] = strike.put.greeks?.delta;
      rowObject["putPrice"] = strike.put.theoPrice;
    }
    if ("call" in strike) {
      rowObject["symbol"] = strike.call.symbol;
      rowObject["callOptionSymbol"] = strike.call.optionSymbol;
      rowObject["callStreamerSymbol"] = strike.call.streamerSymbol;
      rowObject["strike"] = strike.call.strike;
      rowObject["id"] = strike.call.strike;
      rowObject["callDelta"] = strike.call.greeks?.delta;
      rowObject["callPrice"] = strike.call.theoPrice;
    }
    // console.log("rowObject", rowObject);
    return rowObject;
  });
  // const rows = [{ id: 1 }];
  // console.log(rows2);
  const columns: GridColDef[] = [
    {
      field: "callDelta",
      description: "Delta",
      headerName: "Delta",
      flex: 1,
      valueFormatter: (value) => {
        if (value != undefined) return Math.abs(Math.round(value * 100));
        else return "-";
      },
      align: "center",
    },
    {
      field: "callPrice",
      description: "Price",
      headerName: "Price",
      flex: 1,
      align: "center",
      valueFormatter: (value) => {
        if (value != undefined) return Math.round(value * 100) / 100;
        else return "-";
      },
    },
    {
      field: "callQty",
      description: "Call",
      headerName: "Call",
      flex: 1,
      editable: true,
      align: "center",
      valueFormatter: (value) => (value == 0 ? "" : value),
      valueGetter: (_, row) => {
        // console.log("callQty valueGetter");
        if (
          exp in modelQty &&
          row.strike in modelQty[exp] &&
          "call" in modelQty[exp][row.strike]
        )
          return modelQty[exp][row.strike].call;
        else return 0;
      },
      valueSetter: (value, row) => {
        // console.log("callQty valueSetter", value, row);
        if (value != "" && value != undefined) tempCallQty = value;
        return row;
      },
    },
    {
      field: "strike",
      description: "Strike",
      headerName: "Strike",
      flex: 1,
      align: "center",
    },
    {
      field: "putQty",
      description: "Put",
      headerName: "Put",
      flex: 1,
      editable: true,
      align: "center",
      valueFormatter: (value) => (value == 0 ? "" : value),
      valueGetter: (_, row) => {
        // console.log("putQty valueGetter");
        if (
          exp in modelQty &&
          row.strike in modelQty[exp] &&
          "put" in modelQty[exp][row.strike]
        )
          return modelQty[exp][row.strike].put;
        else return 0;
      },
      valueSetter: (value, row) => {
        // console.log("putQty valueSetter");
        if (value != "" && value != undefined) tempPutQty = value;
        return row;
      },
    },
    {
      field: "putPrice",
      description: "Price",
      headerName: "Price",
      flex: 1,
      align: "center",
      valueFormatter: (value) => {
        if (value != undefined) return Math.round(value * 100) / 100;
        else return "-";
      },
    },
    {
      field: "putDelta",
      description: "Delta",
      headerName: "Delta",
      flex: 1,
      valueFormatter: (value) => {
        if (value != undefined) return Math.abs(Math.round(value * 100));
        else return "-";
      },
      align: "center",
    },
  ];

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel: any) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    []
  );

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  return (
    <DataGrid
      initialState={{
        pagination: {
          paginationModel: { pageSize: 50 },
        },
      }}
      disableColumnMenu
      rows={rows}
      columns={columns}
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      onCellEditStop={(params) => {
        if (tempCallQty != null)
          dispatch(
            updateModelQty({
              exp: exp,
              strike: params.row.strike,
              qty: tempCallQty,
              putcall: "call",
            })
          );
        if (tempPutQty != null)
          dispatch(
            updateModelQty({
              exp: exp,
              strike: params.row.strike,
              qty: tempPutQty,
              putcall: "put",
            })
          );

        tempCallQty = 0;
        tempPutQty = 0;
      }}
    />
  );
};
