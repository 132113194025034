import React, { useEffect } from "react";
import { Feeds } from "components/Feeds/Feeds.component";
import { useDispatch } from "react-redux";
import { getFeed } from "redux/Feed";
import { WriteFeed } from "components";

export const NewsFeed = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeed({ clearFeed: true }));
  });
  return (
    <>
      <WriteFeed /> <Feeds />
    </>
  );
};
