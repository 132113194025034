import makeStyles from "@mui/styles/makeStyles";
import { createTheme } from "@mui/material";
const theme = createTheme();

export const useAddEntryStyles = makeStyles(() => ({
  list: {
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  fullList: {
    width: "auto",
  },
  debit: {
    "& input": {
      color: "red",
    },
  },
  credit: {
    "& input": {
      color: "green",
    },
  },
  orderRoot: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-dataContainer.data-container": {
      // minHeight: "2000px !important",
    },
  },
  orderRow: {
    // maxHeight: "999px !important",
  },
  grayorderRow: {
    color: "rgba(0, 0, 0, 0.26) !important",
  },
  orderCell: {
    // maxHeight: "999px !important",
    // paddingTop: "1px !important",
    alignItems: "unset !important",
    "& > .MuiButtonBase-root": {
      alignItems: "unset !important",
      //   "& > span": {
      //     marginTop: "-7px",
      //   },
    },
    "& #cr": {
      margin: " 2px 0 0 3px",
      color: "#28a662",
    },
    "& #db": {
      margin: " 2px 0 0 3px",
      color: "#fc3e2a",
    },
  },
  addOrderEntry: {
    "& .MuiDialog-paper": {
      // minHeight: "90vh",
      // maxHeight: "90vh",
    },
  },
}));
