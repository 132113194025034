import { useMediaQuery, useTheme } from "@mui/material";

export const useXsDownMedia = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const useSmDownMedia = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('lg'));
};

export const useLandsacpeMedia = () =>
  useMediaQuery(`screen and (orientation:landscape)`);

export const usePhoneScreenMedia = () => {
  const xs = useXsDownMedia();
  const sm = useSmDownMedia();
  const landscape = useLandsacpeMedia();
  return xs || (sm && landscape);
};
