import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";
import { setFilterByDate } from "./UI.actions";

// function* filterSaga() {
//   // yield put(getEntries());
// }

// function* watchAddFilterBySymbol() {
//   yield takeLatest(addFilterBySymbol, filterSaga);
// }
// function* watchRemoveFilterBySymbol() {
//   yield takeLatest(removeFilterBySymbol, filterSaga);
// }
// function* watchSetFilterBySymbol() {
//   yield takeLatest(setFilterBySymbols, filterSaga);
// }
// function* watchsetFilterByStatus() {
//   yield takeLatest(setFilterByStatus, filterSaga);
// }
// function* watchSetFilterByAccount() {
//   yield takeLatest(setFilterByAccount, filterSaga);
// }
function* watchSetFilterByDate() {
  yield takeLatest(setFilterByDate, setFilterByDateSaga);
}
function* setFilterByDateSaga(action: AnyAction) {
  // console.log(action.payload);
  // if (action.payload == "TodayTrades") {
  //   yield setFilterByStatus("All");
  // }
}

const sagaArray = [
  // watchAddFilterBySymbol(),
  // watchRemoveFilterBySymbol(),
  // watchSetFilterBySymbol(),
  // watchsetFilterByStatus(),
  // watchSetFilterByAccount(),
  watchSetFilterByDate(),
];

export default sagaArray;
