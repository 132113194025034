import { Box } from "@mui/material";
import { currency2DigitFormatter } from "utils";
import { StrategyEnum } from "journal-lib";
import moment from "moment";
import { ActivityDetailProps } from "components/ActivityDetail";

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: "15px 16px",
//     "& button": {
//       display: "none",
//     },
//     "&:hover button": {
//       display: "inline-flex",
//       position: "absolute",
//       top: "-2px",
//       right: "3px",
//       width: "25px",
//       height: "25px",
//       minHeight: "20px",
//     },
//   },
//   hidden: {
//     display: "none",
//   },
//   debit: {
//     color: "red",
//   },
//   credit: {
//     color: "green",
//   },
//   legGrid: {
//     paddingBottom: "3px",
//   },
// }));

export const FeedContent = ({
  activity,
  menu,
  entry,
  ...otherProps
}: ActivityDetailProps) => {
  //   const classes = useStyles();
  const symbol = entry?.symbol;

  return entry ? (
    <Box>
      <div>
        <span style={{ fontSize: "12pt" }}>
          <strong>
            {`$${symbol} ${moment(activity.date).format("M/D")} ${
              activity.type === "closing"
                ? "Closing - "
                : activity.type === "roll"
                ? "Roll - "
                : activity.type === "add"
                ? "Add - "
                : "Open - "
            } ${
              entry.position?.legs?.length > 0 &&
              entry.position.legs[0].option?.expDate
                ? `${moment(entry.position.legs[0].option.expDate).format(
                    "M/D"
                  )} `
                : ""
            }${activity.desc}`}
          </strong>
        </span>
      </div>
      <div>
        <div>
          {activity.note && activity.note.trim().length > 0 ? (
            <>
              <div>​</div>
              {activity.note}
            </>
          ) : null}
          <div>​</div>
          {activity.legs?.map((leg) => (
            <div>
              {`${leg.type.toUpperCase()} ${
                leg.leg?.option.expDate
                  ? moment(leg.leg?.option.expDate).format("M/D/YY")
                  : ""
              } `}
              <u>{leg.leg?.option.strike}</u>
              {` ${
                leg.leg?.option.type === "call"
                  ? "Call"
                  : leg.leg?.option.type === "put"
                  ? "Put"
                  : leg.leg?.option.type === "stock"
                  ? "Stock"
                  : leg.leg?.option.type === "future"
                  ? "Future"
                  : ""
              } x ${leg.leg?.qty}`}
            </div>
          ))}
          <div>{`${
            (activity.price ?? 0) > 0 ? "Credits" : "Debits"
          }: ${currency2DigitFormatter.format(
            activity.desc === "Long Stock" || activity.desc === "Short Stock"
              ? Math.abs(+(activity.price ?? 0) * 100)
              : Math.abs(+(activity.price ?? 0))
          )}`}</div>
        </div>
        <div>
          <div>​</div>
        </div>
        <div>
          <div>
            <strong>
              {entry.position.legs.length > 0 ? "Current Position" : "Closed"}
            </strong>
          </div>
          {entry.position.legs.map((leg) => (
            <div>
              {`${
                leg.option.expDate
                  ? moment(leg.option.expDate).format("M/D/YY")
                  : ""
              } `}
              <u>{leg.option.strike}</u>

              {` ${
                leg.option.type === "call"
                  ? "Call"
                  : leg.option.type === "put"
                  ? "Put"
                  : leg.option.type === "stock"
                  ? "Stock"
                  : leg.option.type === "future"
                  ? "Future"
                  : ""
              } x ${leg.qty}`}
            </div>
          ))}
          <div>
            {`Total ${
              entry.position.legs.length > 0
                ? entry.position.cumPrice > 0
                  ? "Credits"
                  : "Debits"
                : entry.position.cumPrice > 0
                ? "Profit"
                : "Loss"
            }: `}
            {currency2DigitFormatter.format(
              (entry?.position
                ?.name as unknown as keyof typeof StrategyEnum) ===
                "LongStock" ||
                (entry?.position
                  ?.name as unknown as keyof typeof StrategyEnum) ===
                  "ShortStock"
                ? Math.abs(+entry?.position?.cumPrice * 100)
                : Math.abs(+entry?.position?.cumPrice)
            )}
          </div>
        </div>
      </div>
    </Box>
  ) : (
    <></>
  );
};
