import { useContext, createContext, Dispatch, SetStateAction } from "react";

type ContextProps = {
  isAuthenticated: Boolean;
  userHasAuthenticated: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = createContext<Partial<ContextProps>>({
  isAuthenticated: false,
  userHasAuthenticated: () => {},
});

export function useAppContext() {
  return useContext(AppContext);
}
