import { API } from "aws-amplify";
import Line from "components/Charts/Line/Line";
// import { D3 } from "components/D3";
import { Leg, theoPricesFromEntry } from "journal-lib";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorOptionChains,
  selectorAnalysisStore,
} from "redux/Analysis/Analysis.selectors";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { ThreePartPageWrapper } from "pages/ThreePartPageWrapper";
import useWindowSize from "utils/hooks/windowSize";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid2 as Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Settings } from "components/Charts/Settings/Settings";
import { setOptionChains } from "redux/Analysis";
import Draggable from "react-draggable";
import { useParams } from "react-router-dom";
import { analysisEntryById } from "redux/JournalEntries";

export const Analysis = () => {
  const { entryid } = useParams<{ entryid: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (entryid != undefined) dispatch(analysisEntryById(entryid));
  }, [entryid]);
  const [multiline, setMultiline] = useState<any>();
  const [multilineModeling, setMultilineModeling] = useState<any>();
  const [stepper, setStepper] = useState<number>();
  // const [standDeviation, setStandardDeviation] = useState<number>();
  const [marketPrice, setMarketPrice] = useState<number>();
  const [symbol, setSymbol] = useState<string>("");
  const [originalLegs, setOriginalLegs] = useState<Leg[]>([]);
  const [addedLegs, setAddedLegs] = useState<Leg[]>([]);

  const analysisStore = useSelector(selectorAnalysisStore);
  const entries = analysisStore.entries;
  // console.log(entries);
  const t =
    analysisStore?.parameters?.t == undefined
      ? []
      : analysisStore?.parameters.t;
  const includeExpiration =
    analysisStore?.parameters?.includeExpiration == undefined
      ? false
      : analysisStore?.parameters.includeExpiration;
  const rate = analysisStore.rate;
  const enabledModeling =
    analysisStore?.modeling?.volatilityModeling == undefined
      ? false
      : analysisStore.modeling.volatilityModeling;
  const volatilityAdjustment =
    analysisStore?.modeling?.volatilityAdjustment == undefined
      ? 0
      : analysisStore.modeling.volatilityAdjustment;
  const volatilityPointPercent =
    analysisStore?.modeling?.volatilityPointPercent == undefined
      ? "Percent"
      : analysisStore.modeling.volatilityPointPercent;
  const modelQty =
    analysisStore?.modeling?.modelQty == undefined
      ? undefined
      : analysisStore.modeling.modelQty;
  const positionModeling =
    analysisStore?.modeling?.positionModeling == undefined
      ? undefined
      : analysisStore.modeling.positionModeling;

  const [status, setStatus] = React.useState({ hideDialog: false });
  const { width, height } = useWindowSize();

  const optionsChains = useSelector(selectorOptionChains);
  const standardDeviation = useMemo(() => {
    if (
      entries == undefined ||
      !Array.isArray(entries) ||
      entries.length == 0
    ) {
      return 0;
    }
    let exp = "";
    for (const leg of entries[0].position.legs) {
      if (leg.option.type == "call" || leg.option.type == "put") {
        if (leg.option.expDate) {
          exp = DateTime.fromISO(leg.option.expDate).toFormat("yyyy-MM-dd");
          break;
        }
      }
    }
    if (optionsChains && exp in optionsChains) {
      const expChains = optionsChains[exp];
      let std = 0;
      for (const strike in optionsChains[exp]) {
        const delta = expChains[strike]?.put?.greeks?.delta;
        if (Math.abs(delta) < 0.16) {
          std = expChains[strike].put.strike;
        } else break;
      }
      if (std == 0) return 0;
      std = (entries[0].symbolMarketPrice ?? 0) - std;
      // console.log("standard deviation: ", std);
      return std;
    }
    return 0;
  }, [analysisStore]);

  useEffect(() => {
    if (
      entries == undefined ||
      !Array.isArray(entries) ||
      entries.length == 0
    ) {
      console.log("entires is not ready");
      return;
    }

    if (rate == undefined) {
      console.log("rate is not ready");
      return;
    }

    if (symbol != entries[0].symbol) {
      setSymbol(entries[0].symbol);
      API.put("optionOrder", "/journalEntry/getOptionChain", {
        body: {
          symbol: entries[0].symbol,
          type: "putCall",
        },
      }).then((res) => dispatch(setOptionChains(res)));
    }

    let combined = entries[0];
    setMarketPrice(combined.symbolMarketPrice);
    for (let i = 1; i < entries.length; i++) {
      combined = {
        ...combined,
        position: {
          ...combined.position,
          cumPrice: combined.position.cumPrice + entries[i].position.cumPrice,
          legs: [...combined.position.legs, ...entries[i].position.legs],
        },
      };
    }
    setOriginalLegs(combined.position.legs);
    let adjustEntries = {
      ...combined,
    };
    if (positionModeling) {
      if (modelQty != undefined) {
        const addedLegs: Leg[] = [];
        let costAdjustment = 0;
        Object.keys(modelQty).map((exp) => {
          return Object.keys(modelQty[exp]).map((strike) => {
            if (modelQty[exp][strike].put) {
              const putQty = modelQty[exp][strike].put as number;
              if (putQty != 0)
                addedLegs.push({
                  qty: putQty,
                  option: {
                    ...optionsChains[exp][strike].put,
                    expDate:
                      DateTime.fromFormat(
                        optionsChains[exp][strike].put.expDate,
                        "yyyy-MM-dd"
                      ).toISO() ?? "",
                    volatility:
                      optionsChains[exp][strike].put?.greeks?.volatility,
                  },
                });
              costAdjustment +=
                putQty * -1 * +optionsChains[exp][strike].put.theoPrice;
            }
            if (modelQty[exp][strike].call) {
              const callQty = modelQty[exp][strike].call as number;
              if (callQty != 0)
                addedLegs.push({
                  qty: callQty,
                  option: {
                    ...optionsChains[exp][strike].call,
                    expDate:
                      DateTime.fromFormat(
                        optionsChains[exp][strike].call.expDate,
                        "yyyy-MM-dd"
                      ).toISO() ?? "",
                    volatility:
                      optionsChains[exp][strike].call?.greeks?.volatility,
                  },
                });
              costAdjustment +=
                callQty * -1 * +optionsChains[exp][strike].call.theoPrice;
            }
          });
        });
        // console.log("addedLegs", addedLegs);
        setAddedLegs(addedLegs);
        adjustEntries = {
          ...adjustEntries,
          position: {
            ...adjustEntries.position,
            cumPrice: adjustEntries.position.cumPrice + costAdjustment,
            legs: [...adjustEntries.position.legs, ...addedLegs],
          },
        };
        // console.log("adjustEntries", adjustEntries);
      }
    }
    if (enabledModeling) {
      if (volatilityAdjustment != undefined) {
        adjustEntries = {
          ...adjustEntries,
          position: {
            ...adjustEntries.position,
            legs: adjustEntries.position.legs.map((leg) => {
              const adjustedVol =
                volatilityPointPercent == "Percent"
                  ? (leg.option.volatility ?? 0) *
                    (1 + volatilityAdjustment / 100)
                  : (leg.option.volatility ?? 0) + volatilityAdjustment / 100;
              console.log(
                leg.option.volatility,
                volatilityAdjustment,
                volatilityPointPercent,
                adjustedVol
              );
              return {
                ...leg,
                option: {
                  ...leg.option,
                  volatility: adjustedVol,
                },
              };
            }),
          },
        };
      }

      // console.log("combined", combined, "adjusted", adjustEntries);
    }
    if (enabledModeling || positionModeling) {
      // console.log("adjustEntries", adjustEntries);
      const { prices: adjustedPrices } = theoPricesFromEntry(
        adjustEntries,
        rate,
        t,
        includeExpiration
      );
      setMultilineModeling(adjustedPrices);
    }
    // console.log("all ready");
    const { prices, stepper } = theoPricesFromEntry(
      combined,
      rate,
      t,
      includeExpiration
    );
    // console.log(prices);
    setStepper(stepper);
    setMultiline(prices);

    // setStandardDeviation(firstStdDev);
  }, [analysisStore, dispatch]);

  let buttons: any = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat noDrag",
      },
      click: () => {
        setStatus({ hideDialog: false });
      },
    },
  ];

  function onOverlayClick() {
    setStatus({ hideDialog: false });
  }

  function dialogClose() {
    setStatus({ hideDialog: false });
  }

  return (
    <ThreePartPageWrapper
      title={"Analysis"}
      hideNews={true}
      alwaysCollapse={true}
      titleFooter={
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setStatus({ hideDialog: true })}
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      }
    >
      <div style={{ height: `${height - 80}px` }} id="dialog-target">
        {/* <h1>Analysis : {entryid}</h1> */}
        {/* <D3 line={line} multiline={multiline}></D3> */}
        <Line
          t={t}
          multiline={multiline}
          multilineModeling={multilineModeling}
          height={height - 65}
          marketPrice={marketPrice ?? 0}
          stepper={stepper ?? 0}
          standDeviation={standardDeviation}
        />
        <Draggable cancel=".noDrag">
          <DialogComponent
            header="Settings"
            style={{ maxWidth: "741px" }}
            width={`${width * 0.7}px`}
            isModal={false}
            target="#dialog-target"
            visible={status.hideDialog}
            close={dialogClose}
            overlayClick={onOverlayClick}
            // allowDragging={true}
            // showCloseIcon={true}
            buttons={buttons}
          >
            <Settings />
          </DialogComponent>
        </Draggable>
      </div>
      <Draggable cancel=".noDrag">
        <Paper
          sx={{
            width: 220,
            position: "fixed",
            top: 100,
            // left: 0,
            right: 10,
            m: 0,
            p: 1,
            borderWidth: 0,
            borderTopWidth: 1,
            maxHeight: "75vh",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <Accordion defaultExpanded>
            <AccordionSummary
              className="noDrag"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Positions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {originalLegs.map((leg, index) => {
                const bHasExpDate = leg.option?.expDate != undefined;
                const expDate = bHasExpDate
                  ? DateTime.fromISO(leg.option?.expDate ?? "")
                  : DateTime.utc();
                return (
                  <Grid
                    size={12}
                    container
                    spacing={0}
                    style={{ height: "19px" }}
                    key={`${index} ${leg.qty} ${expDate
                      .toLocal()
                      .toFormat("MMM dd")} ${leg.option?.strike}`}
                  >
                    <Grid
                      flex={0.5}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography variant="caption">{leg.qty}</Typography>
                    </Grid>
                    <Grid
                      flex={1}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography variant="caption">
                        {bHasExpDate
                          ? expDate.toLocal().toFormat("MMM dd")
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      flex={1}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography variant="caption">
                        {bHasExpDate
                          ? `${Math.round(expDate.diffNow("days").days)}d`
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      flex={1}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography variant="caption">
                        {leg.option?.strike}
                      </Typography>
                    </Grid>
                    <Grid
                      flex={0.5}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography variant="caption">
                        {leg.option?.type == "put"
                          ? "P"
                          : leg.option?.type == "call"
                          ? "C"
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              {positionModeling && addedLegs.length > 0 ? (
                <Divider>
                  <Typography variant="caption"> Modeling</Typography>
                </Divider>
              ) : null}
              {positionModeling && addedLegs.length > 0
                ? addedLegs.map((leg, index) => {
                    const bHasExpDate = leg.option?.expDate != undefined;
                    const expDate = bHasExpDate
                      ? DateTime.fromISO(leg.option?.expDate ?? "")
                      : DateTime.utc();
                    return (
                      <Grid
                        size={12}
                        container
                        spacing={0}
                        style={{ height: "19px" }}
                        key={`${index} ${leg.qty} ${expDate
                          .toLocal()
                          .toFormat("MMM dd")} ${leg.option?.strike}`}
                      >
                        <Grid
                          flex={0.5}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography variant="caption">{leg.qty}</Typography>
                        </Grid>
                        <Grid
                          flex={1}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography variant="caption">
                            {bHasExpDate
                              ? expDate.toLocal().toFormat("MMM dd")
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          display="flex"
                          flex={1}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography variant="caption">
                            {bHasExpDate
                              ? `${Math.round(expDate.diffNow("days").days)}d`
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          display="flex"
                          flex={1}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography variant="caption">
                            {leg.option?.strike}
                          </Typography>
                        </Grid>
                        <Grid
                          flex={0.5}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography variant="caption">
                            {leg.option?.type == "put"
                              ? "P"
                              : leg.option?.type == "call"
                              ? "C"
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })
                : null}
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Draggable>
    </ThreePartPageWrapper>
  );
};

export default Analysis;
