import { useEffect, useState, useRef } from "react";

/**
 * @credits https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
 */

interface ExtendedEntry extends IntersectionObserverEntry {
  isVisible: boolean;
}

interface Args extends IntersectionObserverInit {
  onAppearOnly?: boolean;
}

type Return<T> = [(node: T) => void, ExtendedEntry?];

function useIntersectionObserver<T extends HTMLElement = HTMLDivElement>({
  threshold = 0.1,
  root = null,
  rootMargin = "0%",
  onAppearOnly = false,
}: Args): Return<T> {
  const [entry, setEntry] = useState<ExtendedEntry>();
  const [node, setNode] = useState<T>();
  const observer = useRef<IntersectionObserver | null>(null);

  const noUpdate = entry?.isVisible && onAppearOnly;

  useEffect(() => {
    if (!window?.IntersectionObserver || noUpdate) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const isVisible = entries[0].intersectionRatio > 0;
        setEntry({ ...entries[0], isVisible });
      },
      {
        threshold,
        root,
        rootMargin,
      }
    );

    // Ensure the rest of useEffect use the same observer
    const { current: currentObserver } = observer;

    if (node) currentObserver.observe(node);

    return () => {
      currentObserver.disconnect();
    };
  }, [node, threshold, root, rootMargin, noUpdate]);

  return [setNode, entry];
}

export default useIntersectionObserver;

// This React Hook detects visibility of a component on the viewport using the
// IntersectionObserver API natively present in the browser.

// It can be very useful to lazy-loading of images, implementing "infinite scrolling"
//  or starting animations for example.

// Your must pass the ref element (from useRef()).

// It takes optionally root, rootMargin and threshold arguments from the native
//  IntersectionObserver API and freezeOnceVisible to only catch the first appearance too.

// It returns the full IntersectionObserver's entry object.

// Source:

// I discovered this way of using IntersectionObserver via this post medium while
//  playing to build a lazy-loaded collection of images.

//  import React, { FC, useRef } from 'react'

// import useIntersectionObserver from './useIntersectionObserver'

// const Section: FC = ({ children }) => {
//   const ref = useRef<HTMLDivElement | null>(null)
//   const entry = useIntersectionObserver(ref, {})
//   const isVisible = !!entry?.isIntersecting

//   console.log(`Render Section ${children?.toString()}`, { isVisible })

//   return (
//     <div
//       ref={ref}
//       style={{
//         minHeight: '100vh',
//         display: 'flex',
//         border: '1px dashed #000',
//       }}
//     >
//       <div style={{ margin: 'auto' }}>{children}</div>
//     </div>
//   )
// }

// export default function Component() {
//   return (
//     <>
//       <Section key="1">div n°1</Section>
//       <Section key="2">div n°2</Section>
//       <Section key="3">div n°3</Section>
//       <Section key="4">div n°4</Section>
//       <Section key="5">div n°5</Section>
//     </>
//   )
// }
