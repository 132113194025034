import { createAction } from "@reduxjs/toolkit";
import { AddEntryPostBody, Quote, RollPostBody } from "journal-lib";
import {
  chartPoint,
  getExpDateResponse,
  getStockChartParam,
  ohlc,
} from "./Stock.types";

export const resetStock = createAction("stock/reset");

export const getStockChart = createAction<getStockChartParam>(
  "stock/getStockChart"
);

export const getStockChartResolved = createAction<ohlc[]>(
  "stock/getStockChartResolved"
);

export const getStockChartRejected = createAction(
  "stock/getStockChartRejected"
);

export const getStockQuotes = createAction<string[]>("stock/getStockQuotes");

export const getStockQuotesResolved = createAction<Quote[]>(
  "stock/getStockQuotesResolved"
);

export const getStockQuotesRejected = createAction(
  "stock/getStockQuotesRejected"
);

export const setChartProps = createAction<{
  interval?: "1min" | "15min" | "daily" | "weekly" | "monthly";
  from?: Date;
  to?: Date;
}>("stock/setChartProps");

export const clearExpDate = createAction("stock/clearExpDate");
export const getExpDate = createAction<string>("stock/getExpDate");
export const getExpDateResolved = createAction<getExpDateResponse>(
  "stock/getExpDateResolved"
);
export const getExpDateRejected = createAction("stock/getExpDateRejected");

export const getEntryPLChart = createAction<string>("stock/getEntryPLChart");
export const getEntryPLChartResolved = createAction<chartPoint[]>(
  "stock/getEntryPLChartResolved"
);
export const getEntryPLChartRejected = createAction(
  "stock/getEntryPLChartRejected"
);

export const clearEntryPreviewPLChart = createAction(
  "stock/clearEntryPreviewPLChart"
);
export const getEntryPreviewPLChart = createAction<RollPostBody>(
  "stock/getEntryPreviewPLChart"
);
export const getEntryPreviewPLChartOnNew = createAction<AddEntryPostBody>(
  "stock/getEntryPreviewPLChartOnNew"
);
export const getEntryPreviewPLChartResolved = createAction<chartPoint[]>(
  "stock/getEntryPreviewPLChartResolved"
);
export const getEntryPreviewPLChartRejected = createAction(
  "stock/getEntryPreviewPLChartRejected"
);
