import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectorMonthly } from "redux/Stats/Monthly/Monthly.selectors";
import { currency2DigitFormatter } from "utils";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#6392F6",
      color: "#fff",
      textAlign: "center",
    },
    body: {
      fontSize: 14,
      textAlign: "center",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const MonthlyIncome = () => {
  const classes = useStyles();

  // useEffect(() => {
  //   const TastyWorks = require("../../../node_modules/tasty-works-api/lib/index");
  //   const credentials = {
  //     username: "nivlekwat",
  //     password: "Dimshum5201314.",
  //   };

  //   let TASTY_ACCOUNT_ID: any;

  //   // Set the username and password
  //   TastyWorks.setUser(credentials);
  //   // Before making any calls, get the session-token via the authorization endpoint
  //   TastyWorks.authorization()
  //     .then((token: string) => {
  //       // Set the authorization in the headers
  //       TastyWorks.setAuthorizationToken(token);
  //       console.log("Session is active, continue with other calls.");
  //       return true;
  //     })
  //     // .then(() => TastyWorks.accounts())
  //     // .catch((err: any) => console.log(err.status))
  //     // .then((accounts: any) => {
  //     //   TASTY_ACCOUNT_ID = accounts[0]["account-number"];
  //     //   TastyWorks.setUser({ accounts });
  //     // })
  //     // .then(() => {
  //     //   console.log("======= USER OBJECT =======");
  //     //   console.log(TastyWorks.getUser());
  //     // })
  //     // .then(() => TastyWorks.balances(TASTY_ACCOUNT_ID))
  //     // .catch((err: any) => console.log(err.status))
  //     // .then((balances: any) => {
  //     //   console.log("======= ACCOUNT BALANCES =======");
  //     //   console.log(balances);
  //     // })
  //     // .then(() => TastyWorks.positions(TASTY_ACCOUNT_ID))
  //     // .catch((err: any) => console.log(err.status))
  //     // .then((positions: any) => {
  //     //   console.log("======= ACCOUNT POSITIONS =======");
  //     //   console.log(positions);
  //     // })
  //     // .then(() => TastyWorks.liveOrders(TASTY_ACCOUNT_ID))
  //     // .catch((err: any) => console.log(err.status))
  //     // .then((liveOrders: any) => {
  //     //   console.log("======= ACCOUNT LIVEORDERS =======");
  //     //   console.log(liveOrders);
  //     // })
  //     // .then(() =>
  //     //   TastyWorks.history(TASTY_ACCOUNT_ID, "05/01/2019", "06/01/2019")
  //     // )
  //     // .catch((err: any) => console.log(err.status))
  //     // .then((history: any) => {
  //     //   console.log("======= ACCOUNT HISTORY =======");
  //     //   console.log(history);
  //     // })
  //     .then(() => TastyWorks.marketMetrics(["AMZN", "SPX"]))
  //     .then((marketData: any) => {
  //       console.log("======= Market Data =======");
  //       console.log(marketData);
  //     })
  //     .then(() => TastyWorks.optionChain("TSLA"))
  //     .then((chain: any) => {
  //       console.log("======= Option chain =======");
  //       console.log(chain);
  //       console.table(chain.items[0].expirations);
  //     });
  //   // .then(() => TastyWorks.streamer())
  //   // .then((streamer: any) => {
  //   //   console.log("======= STREAMER =======");
  //   //   console.log(streamer);
  //   // });
  // }, []);

  const monthlys = useSelector(selectorMonthly);
  let sortMonthly = [...monthlys];
  sortMonthly = sortMonthly?.sort((a, b) => {
    if (a.date > b.date) return 1;
    else return -1;
  });
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Year-Month</StyledTableCell>
            <StyledTableCell>Income</StyledTableCell>
            <StyledTableCell>Expiring</StyledTableCell>
            <StyledTableCell>Commissions</StyledTableCell>
            <StyledTableCell>Fee</StyledTableCell>
            <StyledTableCell>Dividends</StyledTableCell>
            <StyledTableCell>Net</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortMonthly.map((row) => (
            <StyledTableRow key={moment(row.date).format("YY/M")}>
              <StyledTableCell component="th" scope="row">
                {moment(row.date).format("YYYY-MM")}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.income)}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.expiring)}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.comissions)}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.fee)}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.dividends)}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.net)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
