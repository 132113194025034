import { AnyAction, createReducer } from "@reduxjs/toolkit";
import { Quote } from "journal-lib";
import { StockStoreState } from "./Stock.types";
import moment from "moment";
import {
  clearEntryPreviewPLChart,
  clearExpDate,
  getEntryPLChart,
  getEntryPLChartResolved,
  getEntryPreviewPLChart,
  getEntryPreviewPLChartOnNew,
  getEntryPreviewPLChartResolved,
  getExpDate,
  getExpDateResolved,
  getStockChart,
  getStockChartResolved,
  getStockQuotes,
  getStockQuotesResolved,
  resetStock,
  setChartProps,
} from "./Stock.actions";

const emptyQuote = (): Quote => ({
  symbol: "",
  description: "",
  exch: "",
  type: "stock",
  last: 0,
  change: 0,
  volume: 0,
  open: 0,
  high: 0,
  low: 0,
  close: 0,
  bid: 0,
  ask: 0,
  underlying: "",
  strike: 0,
  greeks: {
    delta: 0,
    gamma: 0,
    theta: 0,
    vega: 0,
    rho: 0,
    phi: 0,
    bid_iv: 0,
    mid_iv: 0,
    ask_iv: 0,
    smv_vol: 0,
  },
  change_percentage: 0,
  average_volume: 0,
  last_volume: 0,
  prevclose: 0,
  week_52_high: 0,
  week_52_low: 0,
  bidsize: 0,
  bidexch: "",
  asksize: 0,
  askexch: 0,
  open_interest: 0,
  contract_size: 0,
  expiration_date: "",
  expiration_type: "",
  option_type: "",
  root_symbol: "",
});

const initialState: StockStoreState = {
  symbol: "",
  interval: "daily",
  from: moment().endOf("day").subtract(1, "year").toDate(),
  to: moment().endOf("day").toDate(),
  isReady: false,
  data: [
    {
      date: moment().format("YYYY-MM-DD"),
      open: 0,
      high: 0,
      close: 0,
      low: 0,
      volume: 0,
    },
  ],
  quote: emptyQuote(),
  quoteReady: false,
  expirations: [],
  expirationsReady: false,
  PLChart: [],
  PLChartReady: false,
  PreviewPLChart: [],
  PreviewPLChartReady: false,
};

interface StockReducer {
  [x: string]: (
    state: StockStoreState,
    action: AnyAction
  ) => StockStoreState | undefined;
}

const reducer: StockReducer = {
  [resetStock.toString()]: () => {
    return initialState;
  },
  [getStockChart.toString()]: (state, action) => ({
    ...state,
    ...action.payload,
    isReady: false,
    data: [],
  }),
  [getStockChartResolved.toString()]: (state, action) => ({
    ...state,
    isReady: true,
    data: action.payload,
  }),
  [getStockQuotes.toString()]: (state) => ({
    ...state,
    quote: emptyQuote(),
    quoteReady: false,
  }),
  [getStockQuotesResolved.toString()]: (state, action) => ({
    ...state,
    quote:
      action.payload && action.payload.length > 0 ? action.payload[0] : null,
    quoteReady: true,
  }),
  [setChartProps.toString()]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [clearExpDate.toString()]: (state) => ({
    ...state,
    expirations: [],
  }),
  [getExpDate.toString()]: (state) => ({
    ...state,
    expirationsReady: false,
    expirations: [],
  }),
  [getExpDateResolved.toString()]: (state, action) => ({
    ...state,
    expirationsReady: true,
    expirations: action.payload.expirations.date,
  }),
  [getEntryPLChart.toString()]: (state) => ({
    ...state,
    PLChartReady: false,
    PLChart: [],
  }),
  [getEntryPLChartResolved.toString()]: (state, action) => ({
    ...state,
    PLChartReady: true,
    PLChart: action.payload,
  }),
  [clearEntryPreviewPLChart.toString()]: (state) => ({
    ...state,
    PreviewPLChart: [],
  }),
  [getEntryPreviewPLChart.toString()]: (state) => ({
    ...state,
    PreviewPLChartReady: false,
    PreviewPLChart: [],
  }),
  [getEntryPreviewPLChartOnNew.toString()]: (state) => ({
    ...state,
    PreviewPLChartReady: false,
    PreviewPLChart: [],
  }),
  [getEntryPreviewPLChartResolved.toString()]: (state, action) => ({
    ...state,
    PreviewPLChartReady: true,
    PreviewPLChart: action.payload,
  }),
};

export default createReducer(initialState, reducer);
