import {
  GridColumnVisibilityModel,
  GridRowId,
  GridSortModel,
} from "@mui/x-data-grid";
import { createAction } from "@reduxjs/toolkit";

export const resetUI = createAction("ui/reset");

export const toggleChangeCustomStrateyDialog = createAction(
  "ui/toggleChangeCustomStrateyDialog"
);
export const toggleAddEntryDrawer = createAction("ui/toggleAddEntryDrawer");
export const toggleAddEntryFromOrdersDrawer = createAction(
  "ui/toggleAddEntryFromOrdersDrawer"
);

export const toggleRollDrawer = createAction<boolean>("ui/toggleRollDrawer");

export const toggleCloseDrawer = createAction("ui/toggleCloseDrawer");

export const toggleDetailsDrawer = createAction("ui/toggleDetailsDrawer");
export const setDetailsDrawer = createAction<boolean>("ui/setDetailsDrawer");

export const toggleDeleteDrawer = createAction("ui/toggleDeleteDrawer");

export const toggleAddLegsDrawer = createAction<boolean>(
  "ui/toggleAddLegsDrawer"
);
export const toggleAddLegsFromOrderDrawer = createAction<boolean>(
  "ui/toggleAddLegsFromOrderDrawer"
);

export const setSelectionModel = createAction<GridRowId[]>(
  "ui/setSelectionModel"
);

export const addFilterBySymbol = createAction<string>("ui/addFilterBySymbol");

export const removeFilterBySymbol = createAction<string>(
  "ui/removeFilterBySymbol"
);

export const setFilterBySymbols = createAction<string[]>(
  "ui/setFilteryBySymbols"
);

export const setFilterByStatus = createAction<string>("ui/setFilterByStatus");

export const setFilterByAccount = createAction<string>("ui/setFilterByAccount");

export const setFilterByDate = createAction<string>("ui/setFilterByDate");

export const setSortModel = createAction<GridSortModel>("ui/setSortModel");

export const setDetailsStockChart = createAction<boolean>(
  "ui/setDetailsStockChart"
);

export const toggleDetailsStockChart = createAction(
  "ui/toggleDetailsStockChart"
);

export const setPage = createAction<number>("ui/setPage");
export const setPageSize = createAction<number>("ui/setPageSize");

export const setPLChart = createAction<boolean>("ui/setPLChart");
export const togglePLChart = createAction("ui/togglePLChart");

export const setJournalTableColumnHidden = createAction<string[]>(
  "ui/setJournalTableColumnHidden"
);
export const setJournalTableColumnShown = createAction<string[]>(
  "ui/setJournalTableColumnShown"
);
export const setJournalTableVisibilityModel =
  createAction<GridColumnVisibilityModel>("ui/setJournalTableVisibilityModel");

export const setBar = createAction<{
  show: boolean;
  sessage: string;
  severity: string;
}>("ui/showBar");
