import makeStyles from "@mui/styles/makeStyles";
import { createTheme } from "@mui/material";
const theme = createTheme();

export const useAddEntryStyles = makeStyles(() => ({
  list: {
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  fullList: {
    width: "auto",
  },
  debit: {
    "& input": {
      color: "red",
    },
  },
  credit: {
    "& input": {
      color: "green",
    },
  },
}));
