import {
  AppBar,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { MenuList } from "components/MenuList/MenuList.component";
import React from "react";
import { useSmDownMedia } from "utils";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme } from "@mui/material";
const theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  displayNone: {
    display: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  appBar: {
    boxShadow:
      "0px 2px 4px -4px rgb(0 0 0 / 26%), 0px 4px 5px -4px rgb(0 0 0 / 17%), 0px 1px 10px -10px rgb(0 0 0 / 14%)",
    background: "white",
  },
}));

type Anchor = "left";

export const TwoPartPageWrapper = ({ ...otherProps }) => {
  const classes = useStyles();

  const isSmDown = useSmDownMedia();
  const isMobile = useMediaQuery("(max-width:1264px)");
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MenuList />
    </div>
  );

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid
        item
        style={{ width: "275px" }}
        className={clsx({ [classes.displayNone]: isSmDown || isMobile })}
      >
        <MenuList />
      </Grid>
      <Grid item style={{ width: "990px" }}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={clsx(
                { [classes.displayNone]: !isSmDown && !isMobile },
                classes.menuButton
              )}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer("left", true)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {otherProps.title}
            </Typography>
          </Toolbar>
        </AppBar>
        {otherProps.children}
      </Grid>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </Grid>
  );
};
