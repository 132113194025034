import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import LoaderButton from "components/LoaderButton";
import { useAppContext } from "libs/contextLib";
import { useFormFields } from "libs/hooksLib";
import { onError } from "libs/errorLib";
import "./Signup.css";
import { Alert, TextField } from "@mui/material";
import { addUser } from "redux/User/User.actions";
import { useDispatch } from "react-redux";
import { getAccount } from "redux/Account/Account.actions";
import { getUser } from "redux/User/User.actions";
import { getEntries } from "redux/JournalEntries";
import Snackbar from "@mui/material/Snackbar";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    userName: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState<any>(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorBar, setOpenErrorBar] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  const showError = (msg: string) => {
    setErrorMsg(msg);
    setOpenErrorBar(true);
  };

  async function handleSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e: any) {
      onError(e);
      showError(e.toString());
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);

      if (userHasAuthenticated != undefined) userHasAuthenticated(true);
      dispatch(
        addUser({
          userName: fields.userName,
          name: fields.name,
        })
      );
      dispatch(getAccount());
      dispatch(getUser());
      dispatch(getEntries({}));
      navigate("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <TextField
          autoFocus
          id="confirmationCode"
          label="Confirmation Code"
          type="tel"
          value={fields.confirmationCode}
          onChange={handleFieldChange}
          helperText={`Please check your email for the code.`}
        />
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <TextField
          autoFocus
          id="userName"
          label="User Name"
          type="test"
          value={fields.username}
          onChange={handleFieldChange}
        />
        <TextField
          autoFocus
          id="name"
          label="Display Name"
          type="text"
          value={fields.name}
          onChange={handleFieldChange}
        />
        <TextField
          autoFocus
          id="email"
          label="Email"
          type="email"
          value={fields.email}
          onChange={handleFieldChange}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          value={fields.password}
          onChange={handleFieldChange}
        />
        <TextField
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          value={fields.confirmPassword}
          onChange={handleFieldChange}
        />
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
    );
  }

  const handleClose = () => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    setOpenErrorBar(false);
  };

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}

      <Snackbar
        open={openErrorBar}
        autoHideDuration={6000}
        message={errorMsg}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
