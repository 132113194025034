import { Box, Grid2 as Grid, TextField } from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export const AWSKey = () => {
  const [awsCreds, setAWSCreds] = useState<any>();
  useEffect(() => {
    Auth.currentCredentials().then((credentials) => {
      // console.log(credentials);
      const awsCreds = Auth.essentialCredentials(credentials);
      setAWSCreds(awsCreds);
    });
    //   console.log("awsCreds", awsCreds);
  }, []);

  return (
    <Box sx={{ width: 400 }} component="form" noValidate autoComplete="off">
      <Grid container spacing={2} padding={2}>
        <Grid size={12}>
          <TextField
            sx={{ width: "100%" }}
            variant="standard"
            id={"identityId"}
            label="Identy Id"
            defaultValue={awsCreds?.identityId}
            value={awsCreds?.identityId || ""}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            sx={{ width: "100%" }}
            variant="standard"
            id={"accessKeyId"}
            label="Access Key"
            defaultValue={awsCreds?.accessKeyId}
            value={awsCreds?.accessKeyId || ""}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            sx={{ width: "100%" }}
            variant="standard"
            id={"secretAccessKey"}
            label="Secret Access Key"
            defaultValue={awsCreds?.secretAccessKey}
            value={awsCreds?.secretAccessKey || ""}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            sx={{ width: "100%" }}
            variant="standard"
            id={"sessionToken"}
            label="Session Token"
            defaultValue={awsCreds?.sessionToken}
            value={awsCreds?.sessionToken || ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
