import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { MonthlyIncome } from "components";
import { YearlyIncome } from "components/YearlyIncome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectorAccountStore } from "redux/Account/Account.selectors";
import { getMonthly } from "redux/Stats/Monthly/Monthly.actions";
import { getYearly } from "redux/Stats/Yearly/Yearly.actions";

export const Monthly = () => {
  const dispatch = useDispatch();
  const [account, setAccount] = useState("all");

  useEffect(() => {
    dispatch(getMonthly(account));
    dispatch(getYearly(account));
  });

  const accounts = useSelector(selectorAccountStore);
  const accountArray = Object.keys(accounts).map((key) => ({
    accountId: key,
    desc: accounts[key].description,
  }));
  accountArray.push({ accountId: "all", desc: "All" });

  return (
    <Grid container spacing={3}>
      <Grid
        xs={12}
        item
        container
        alignItems="center"
        style={{ paddingTop: "25px" }}
      >
        <Typography variant="h6" style={{ padding: "0 25px 0 0" }}>
          Filters:
        </Typography>
        <TextField
          style={{ width: "200px" }}
          id="account"
          select
          label="Account:"
          value={account}
          onChange={(e) => setAccount(e.target.value)}
        >
          {accountArray.map((account) => (
            <MenuItem key={account.accountId} value={account.accountId}>
              {account.desc}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid xs={12} item>
        <MonthlyIncome />
      </Grid>
      <Grid xs={12} item>
        <YearlyIncome />
      </Grid>
    </Grid>
  );
};
