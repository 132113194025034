import React, { useEffect, useRef } from "react";

export const SymbolInfo = ({
  symbol = "NASDAQ:AAPL",
  width = "100%",
  locale = "en",
  colorTheme = "light",
  isTransparent = false,
}: {
  symbol: string;
  width?: number | string;
  locale?: string;
  colorTheme?: string;
  isTransparent?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbol: symbol,
      width: width,
      locale: locale,
      colorTheme: colorTheme,
      isTransparent: isTransparent,
      largeChartUrl: "https://www.tradingview.com/chart/?symbol=NASDAQ%3AQQQ",
    });
    if (ref && ref.current && ref.current !== null)
      ref.current!.appendChild(script);
  }, [colorTheme, isTransparent, locale, symbol, width]);

  return (
    <div className="tradingview-widget-container" ref={ref}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};
