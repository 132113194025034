import { API } from "aws-amplify";
import { put, takeLatest } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  addEntries,
  addEntriesRejected,
  addEntriesResolved,
  addT,
  addTRejected,
  addTResolved,
  deleteT,
  deleteTRejected,
  deleteTResolved,
  setEntries,
  setEntriesRejected,
  setEntriesResolved,
  setModelQty,
  setOptionChains,
  setOptionChainsRejected,
  setOptionChainsResolved,
  setRate,
  setT,
  setTRejected,
  setTResolved,
  updateOptionChains,
  updateOptionChainsRejected,
  updateOptionChainsResolved,
} from "./Analysis.actions";
import {
  selectorEntries,
  selectorOptionChains,
  selectorRateSetDate,
} from "./Analysis.selectors";
import { select } from "redux-saga/effects";
import { DateTime } from "luxon";
import { JournalEntry } from "journal-lib";
import { OptionChains } from "./Analysis.types";

function* setEntriesSaga(action: AnyAction) {
  try {
    let rateSetDate: DateTime = DateTime.fromISO(
      yield select(selectorRateSetDate)
    );

    if (rateSetDate.diffNow("days").days < 1) {
      const rate: { price: number } = yield API.get(
        "optionOrder",
        `/marketData/quote/TNX`,
        {}
      );
      yield put(setRate(rate.price / 100));
    }
    yield put(setModelQty({}));
    yield put(setEntriesResolved(action.payload));
  } catch (e) {
    console.log("setEntriesSaga error", e);
    yield put(setEntriesRejected());
  }
}

function* watchSetEntries() {
  yield takeLatest(setEntries, setEntriesSaga);
}

function* addEntriesSaga(action: AnyAction) {
  try {
    const rateSetDate: DateTime = yield select(selectorRateSetDate);
    if (rateSetDate.diffNow("days").days < 1) {
      const rate: { price: number } = yield API.get(
        "optionOrder",
        `/marketData/quote/TNX`,
        {}
      );
      yield put(setRate(rate.price / 100));
    }

    const journalEntries: JournalEntry[] = yield select(selectorEntries);
    const combinedEntries = journalEntries.concat(action.payload);

    // Create a Map to filter out duplicates based on 'id'
    const uniqueEntries = new Map<string, JournalEntry>();

    combinedEntries.forEach((entry) => {
      uniqueEntries.set(entry.entryId ?? "", entry); // The Map will automatically keep the latest unique 'id'
    });

    yield put(setEntriesResolved(Array.from(uniqueEntries.values())));
    yield put(addEntriesResolved());
  } catch (e) {
    console.log("addEntriesSaga error", e);
    yield put(addEntriesRejected());
  }
}

function* watchAddEntries() {
  yield takeLatest(addEntries, addEntriesSaga);
}

function* setTSaga(action: AnyAction) {
  try {
    yield put(setTResolved(action.payload));
  } catch (e) {
    yield put(setTRejected());
  }
}

function* watchsetT() {
  yield takeLatest(setT, setTSaga);
}

function* addTSaga(action: AnyAction) {
  try {
    yield put(addTResolved(action.payload));
  } catch (e) {
    console.log("addTSaga error", e);
    yield put(addTRejected());
  }
}

function* watchaddT() {
  yield takeLatest(addT, addTSaga);
}

function* deleteTSaga(action: AnyAction) {
  try {
    yield put(deleteTResolved(action.payload));
  } catch (e) {
    console.log("deleteTSaga error", e);
    yield put(deleteTRejected());
  }
}

function* watchdeleteT() {
  yield takeLatest(deleteT, deleteTSaga);
}

function* setOptionChainsSaga(action: AnyAction) {
  try {
    let res = action.payload;
    if (res == undefined || !Array.isArray(res) || res.length == 0)
      throw new Error("Option Chains not ready");
    res.sort((a: any, b: any) => {
      if (a.expDate == b.expDate) {
        return a.strike - b.strike;
      } else {
        return new Date(a.expDate).getTime() - new Date(b.expDate).getTime();
      }
    });
    let optionChains: any = {};
    // console.log(res);
    res.map((item: any) => {
      if (optionChains[item.expDate] == undefined) {
        optionChains[item.expDate] = {};
      }
      if (optionChains[item.expDate][item.strike] == undefined) {
        optionChains[item.expDate][item.strike] = {};
      }
      item.isOption = true;
      optionChains[item.expDate][item.strike][item.type] = { ...item };
    });
    // console.log(optionChains);

    const entries: JournalEntry[] = yield select(selectorEntries);
    if (entries != null && entries.length > 0) {
      let exp = "";
      for (const leg of entries[0].position.legs) {
        if (leg.option.type == "call" || leg.option.type == "put") {
          if (leg.option.expDate) {
            exp = DateTime.fromISO(leg.option.expDate).toFormat("yyyy-MM-dd");
            break;
          }
        }
      }
      // console.log("exp", exp);
      if (exp != "" && exp in optionChains) {
        const data: OptionChains[] = yield API.post(
          "optionOrder",
          "/marketData/quotes",
          {
            body: {
              symbols: Object.values(optionChains[exp])
                .map((item: any) => Object.values(item))
                .flat(),
            },
          }
        );
        // console.log("data", data);
        // const optionChains2: any = { ...optionChains };
        data.map((item: any) => {
          // console.log("item", item);
          if (optionChains[exp] == undefined) {
            optionChains[exp] = {};
          }
          if (optionChains[exp][item.strike] == undefined) {
            optionChains[exp][item.strike] = {};
          }
          optionChains[exp][item.strike][item.type] = item;
        });
        // console.log("optionChains2", optionChains);
        // yield put(setOptionChainsResolved(optionChains));
      }
      yield put(setOptionChainsResolved(optionChains));
    }
  } catch (e) {
    console.log("setOptionChiansSaga error", e);
    yield put(setOptionChainsRejected());
  }
}

function* watchSetOptionChains() {
  yield takeLatest(setOptionChains, setOptionChainsSaga);
}

function* updateOptionChainsSaga(action: AnyAction) {
  try {
    const needGreeks: string[] = [];
    let optionChains: OptionChains = yield select(selectorOptionChains);
    for (const item of action.payload) {
      // console.log("item", item);
      if (item in optionChains) {
        for (const strike in optionChains[item]) {
          if (optionChains[item][strike].put.greeks == undefined)
            needGreeks.push(item);
          break;
        }
      }
    }

    // console.log("needGreeks", needGreeks);
    for (const item of needGreeks) {
      const data: OptionChains[] = yield API.post(
        "optionOrder",
        "/marketData/quotes",
        {
          body: {
            symbols: Object.values(optionChains[item])
              .map((item: any) =>
                Object.values(item).filter((item: any) => item.type == "put")
              )
              .flat(),
          },
        }
      );
      // console.log("data", data);
      // const optionChains2: any = { ...optionChains };
      data.map((putItem: any) => {
        // console.log("item", item);
        if (optionChains[item] == undefined) {
          optionChains[item] = {};
        }
        if (optionChains[item][putItem.strike] == undefined) {
          optionChains[item][putItem.strike] = {};
        }
        optionChains = {
          ...optionChains,
          [item]: {
            ...optionChains[item],
            [putItem.strike]: {
              ...optionChains[item][putItem.strike],
              put: { ...putItem },
            },
          },
        };
        optionChains[item][putItem.strike] = {
          ...optionChains[item][putItem.strike],
          put: { ...putItem },
        };
      });
      const data2: OptionChains[] = yield API.post(
        "optionOrder",
        "/marketData/quotes",
        {
          body: {
            symbols: Object.values(optionChains[item])
              .map((item: any) =>
                Object.values(item).filter((item: any) => item.type == "call")
              )
              .flat(),
          },
        }
      );
      // console.log("data", data);
      // const optionChains2: any = { ...optionChains };
      data2.map((callItem: any) => {
        // console.log("item", item);
        if (optionChains[item] == undefined) {
          optionChains[item] = {};
        }
        if (optionChains[item][callItem.strike] == undefined) {
          optionChains[item][callItem.strike] = {};
        }
        optionChains = {
          ...optionChains,
          [item]: {
            ...optionChains[item],
            [callItem.strike]: {
              ...optionChains[item][callItem.strike],
              call: { ...callItem },
            },
          },
        };
      });
    }
    yield put(setOptionChainsResolved(optionChains));
    yield put(updateOptionChainsResolved());
  } catch (e) {
    console.log("error updateOptionChainsSaga", e);
    yield put(updateOptionChainsRejected());
  }
}
function* watchUpdateOptionChains() {
  yield takeLatest(updateOptionChains, updateOptionChainsSaga);
}

const sagaArray = [
  watchSetEntries(),
  watchAddEntries(),
  watchsetT(),
  watchaddT(),
  watchdeleteT(),
  watchSetOptionChains(),
  watchUpdateOptionChains(),
];
export default sagaArray;
