import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorChangeCustomStrategyDialog,
  toggleChangeCustomStrateyDialog,
} from "redux/UI";
import { usePhoneScreenMedia } from "utils";
import { API } from "aws-amplify";
import { getEntries, SelectSelectedEntry } from "redux/JournalEntries";

export const ChangeCustomStrategy = () => {
  const isOpen = useSelector(selectorChangeCustomStrategyDialog);
  const dispatch = useDispatch();

  const entry = SelectSelectedEntry();
  const [customStrategy, setCustomStrategy] = useState<string>("");
  const isPhoneScreen = usePhoneScreenMedia();
  const handleClose = () => {
    dispatch(toggleChangeCustomStrateyDialog());
  };
  const handleChange = () => {
    API.put("optionOrder", "/journalEntry/positionName", {
      body: { entryId: entry.entryId, customName: customStrategy },
    })
      .then(() => dispatch(toggleChangeCustomStrateyDialog()))
      .catch(() => dispatch(toggleChangeCustomStrateyDialog()))
      .finally(() => {
        setCustomStrategy("");
        dispatch(getEntries({}));
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      fullScreen={isPhoneScreen}
    >
      <DialogTitle id="form-dialog-title">Custom Strategy Name</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Strategy Name"
          type="text"
          value={customStrategy}
          onChange={(e) => setCustomStrategy(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleChange} color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeCustomStrategy;
