import {
  AppBar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { MenuList } from "components/MenuList/MenuList.component";
import React, { PropsWithChildren } from "react";
import { useSmDownMedia } from "utils";
import MenuIcon from "@mui/icons-material/Menu";
import { EarningCalendar } from "components";
import { createTheme } from "@mui/material";
const theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  displayNone: {
    display: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  appBar: {
    boxShadow:
      "0px 2px 4px -4px rgb(0 0 0 / 26%), 0px 4px 5px -4px rgb(0 0 0 / 17%), 0px 1px 10px -10px rgb(0 0 0 / 14%)",
    background: "white",
  },
  whatsNew: {
    backgroundColor: "rgb(235, 238, 240)",
    margin: "20px",
    padding: "20px",
  },
  sticky: {
    position: "sticky",
    top: "0px",
    zIndex: 999,
  },
}));

type Anchor = "left";

interface ThreePartPageWrapperProp {
  hideNews?: boolean;
  alwaysCollapse?: boolean;
  titleFooter?: React.ReactNode;
  title: string;
}

export const ThreePartPageWrapper = ({
  hideNews = false,
  alwaysCollapse = false,
  titleFooter,
  ...otherProps
}: PropsWithChildren<ThreePartPageWrapperProp>) => {
  const classes = useStyles();

  const isSmDown = useSmDownMedia();
  const isMobile = useMediaQuery("(max-width:1024px)");
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MenuList />
    </div>
  );

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid
        item
        xs={2}
        className={clsx({
          [classes.displayNone]: isSmDown || isMobile || alwaysCollapse,
        })}
      >
        <MenuList sticky={true} />
      </Grid>
      <Grid
        container
        item
        xs={isSmDown || isMobile || alwaysCollapse ? 12 : 10}
      >
        <Grid item xs={12} container className={classes.sticky}>
          <Grid item xs={isSmDown || isMobile || alwaysCollapse ? 12 : 9}>
            <AppBar
              position="static"
              color="default"
              className={clsx(classes.appBar, { [classes.sticky]: true })}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  className={clsx(
                    {
                      [classes.displayNone]:
                        !alwaysCollapse && !isSmDown && !isMobile,
                    },
                    classes.menuButton
                  )}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer("left", true)}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {otherProps.title}
                </Typography>
                {titleFooter}
              </Toolbar>
            </AppBar>
          </Grid>
          <Grid
            item
            xs={3}
            className={clsx({
              [classes.displayNone]: isSmDown || isMobile || alwaysCollapse,
            })}
          >
            <div
              className={clsx(classes.toolbar, classes.appBar, classes.sticky)}
            />
            <Divider className={classes.sticky} />
          </Grid>
        </Grid>
        <Grid item xs={isSmDown || isMobile || alwaysCollapse ? 12 : 9}>
          {otherProps.children}
        </Grid>
        {hideNews ? (
          <></>
        ) : (
          <Grid
            item
            xs={3}
            className={clsx({ [classes.displayNone]: isSmDown || isMobile })}
          >
            <Paper
              elevation={0}
              className={clsx(classes.whatsNew, classes.sticky)}
              style={{ top: "85px" }}
            >
              <Grid container>
                <Grid xs={12} item style={{ paddingBottom: "20px" }}>
                  <Typography variant="h6" style={{ paddingBottom: "15px" }}>
                    Earning Calendar
                  </Typography>
                  <EarningCalendar />
                </Grid>
                <Grid xs={12} item>
                  <Typography variant="h6" style={{ paddingBottom: "15px" }}>
                    What's New
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      5/9
                      <br />
                      Added earning badge in journal when there are earning
                      within 2 weeks. Also added an earning calendar in the new
                      feed page.
                    </Grid>
                    <Grid item xs={12}>
                      5/7
                      <br />
                      Added a new page to show monthly and yearly income. The
                      "P/L Stats" page can be access on the upper left menu.
                    </Grid>
                    <Grid item xs={12}>
                      5/4
                      <br />
                      Add three new pages: a page to show all feeds posted by a
                      user, a page to show all feed related to a symbol, and a
                      page to show all replies to one feed. Clicking user's
                      avatar will show you all feed posted by that user.
                      Clicking on each post header will take you to the feed
                      detail page to see all its replies. Stock ticker with $
                      will automically be turned into hyperlink for stock page.
                    </Grid>
                    <Grid item xs={12}>
                      5/1
                      <br />
                      Added feed editor for editing feed and posting reply.
                      Display is updated to show first 5 replies. If there are
                      even more replies, we'll have a dedicated page for each
                      feed to show all conversations. Likes are also
                      implemented. Eash user can likes a single post 1 time.
                      Liking an already liked post will remove the like.
                    </Grid>
                    <Grid item xs={12}>
                      4/30
                      <br />
                      New trade post will automatically be posted onto discord
                      for live notification. Use can click the link on the title
                      to go to our web app.
                      <br />
                      Feed's action (Edit & Delete) will only be avaliable if it
                      is your feeds, otherwise the entire action menu will not
                      be visible.
                    </Grid>
                    <Grid item xs={12}>
                      4/28
                      <br />
                      Refresh button in the Journal page will now refresh both
                      entry lists and recalculate all states e.g. market price,
                      % gain, etc...
                    </Grid>
                    <Grid item xs={12}>
                      4/25<br></br>
                      Feed can now be deleted in the menu. There is no checking
                      on who can delete which feed yet because we don't have a
                      current user object. So everyone can delete anyone's feed.
                      Later on when we do have current user object, then I can
                      limit the delete menu to show only when current user
                      matches the owner of the feed.
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </Grid>
  );
};
