import React, { useEffect, useRef } from "react";

export const TechnicalAnalysis = ({
  interval = "1D",
  width = "100%",
  isTransparent = false,
  height = "100%",
  symbol = "AAPL",
  showIntervalTabs = true,
  locale = "en",
  colorTheme = "light",
}: {
  interval?: string;
  width?: number | string;
  isTransparent?: boolean;
  height?: number | string;
  symbol: string;
  showIntervalTabs?: boolean;
  locale?: string;
  colorTheme?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      interval: interval,
      width: width,
      isTransparent: isTransparent,
      height: height,
      symbol: symbol,
      showIntervalTabs: showIntervalTabs,
      locale: locale,
      colorTheme: colorTheme,
      largeChartUrl: "https://www.tradingview.com/chart/?symbol=NASDAQ%3AQQQ",
    });
    if (ref && ref.current && ref.current !== null)
      ref.current!.appendChild(script);
  }, [
    colorTheme,
    isTransparent,
    locale,
    symbol,
    width,
    height,
    interval,
    showIntervalTabs,
  ]);

  return (
    <div className="tradingview-widget-container" ref={ref}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};
