import { useMemo } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  ILoadedEventArgs,
  ChartTheme,
  LineSeries,
  Legend,
  Tooltip,
  Highlight,
  Zoom,
  Category,
  Crosshair,
  StripLine,
  RowsDirective,
  RowDirective,
  AxesDirective,
  AxisDirective,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";
import { useSelector } from "react-redux";
import {
  selectorEnabledModeling,
  selectorPositionModeling,
} from "redux/Analysis/Analysis.selectors";
// const SAMPLE_CSS = `
//     .control-fluid {
//         padding: 0px !important;
//     }
//     .charts {
//         align :center
//     }`;
const Line = ({
  multiline,
  multilineModeling,
  stepper,
  t,
  height,
  marketPrice,
  standDeviation,
}: {
  multiline: any;
  multilineModeling: any;
  stepper: number;
  t: number[];
  height: number;
  marketPrice: number;
  standDeviation: number;
}) => {
  // console.log("multiline", multiline);
  // console.log("multilineModeling", multilineModeling);
  // console.log("marketPrice", marketPrice);
  // console.log("standDeviation", standDeviation);
  const enableModeling = useSelector(selectorEnabledModeling);
  const positionModeling = useSelector(selectorPositionModeling);
  const onChartLoad = (args: ILoadedEventArgs): void => {
    let chart: Element | null = document.getElementById("charts");
    if (chart) chart.setAttribute("title", "");
  };
  const load = (args: ILoadedEventArgs): void => {
    // let selectedTheme: string = location.hash.split("/")[1];
    // selectedTheme = selectedTheme ? selectedTheme : "Material";
    let selectedTheme: string = "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast")
      .replace(/-highContrast/i, "HighContrast") as ChartTheme;
  };

  const lines = useMemo(() => {
    let lines = [];
    for (const t in multiline) {
      // console.log(t, multiline[t]);
      lines.push(
        <SeriesDirective
          key={t}
          dataSource={multiline[t]}
          xName="price"
          yName="profit"
          name={`T${t}`}
          width={2}
          marker={{
            visible: t == "0" ? true : false,
            // visible: true,
            // width: 6,
            // height: 6,
            // shape: "Triangle",
            // isFilled: true,
          }}
          type="Line"
          animation={{ enable: true }}
          yAxisName="secondary"
        ></SeriesDirective>
      );
    }
    return lines;
  }, [multiline]);
  const linesModeling = useMemo(() => {
    if (!enableModeling && !positionModeling) {
      return [];
    } else {
      let lines = [];
      for (const t in multilineModeling) {
        // console.log(t, multilineModeling[t]);
        lines.push(
          <SeriesDirective
            key={t}
            dataSource={multilineModeling[t]}
            xName="price"
            yName="profit"
            name={`T${t}`}
            width={2}
            marker={{
              visible: false,
              // visible: true,
              // width: 6,
              // height: 6,
              // shape: "Triangle",
              // isFilled: true,
            }}
            type="Line"
            animation={{ enable: true }}
            yAxisName="secondary"
            dashArray="15"
            opacity={0.5}
          ></SeriesDirective>
        );
      }
      return lines;
    }
  }, [enableModeling, positionModeling, multilineModeling]);

  const deltaBar = useMemo(() => {
    if (
      multiline != undefined &&
      Object.keys(multiline).length > 0 &&
      multiline[Object.keys(multiline)[0]].length > 0
    ) {
      return (
        <SeriesDirective
          type="Column"
          dataSource={multiline[0]}
          animation={{ enable: true }}
          xName="price"
          yName="delta"
          enableTooltip={false}
          name="Delta"
        />
      );
    }
  }, [multiline]);

  const vegaBar = useMemo(() => {
    if (
      multiline != undefined &&
      Object.keys(multiline).length > 0 &&
      multiline[Object.keys(multiline)[0]].length > 0
    ) {
      // console.log("deltaBar", multiline[0]);
      return (
        <SeriesDirective
          type="Column"
          dataSource={multiline[0]}
          animation={{ enable: true }}
          xName="price"
          yName="vega"
          enableTooltip={false}
          name="Vega"
          yAxisName="third"
        />
      );
    }
  }, [multiline]);

  const thetaBar = useMemo(() => {
    if (
      multiline != undefined &&
      Object.keys(multiline).length > 0 &&
      multiline[Object.keys(multiline)[0]].length > 0
    ) {
      // console.log("deltaBar", multiline[0]);
      return (
        <SeriesDirective
          type="Column"
          dataSource={multiline[0]}
          animation={{ enable: true }}
          xName="price"
          yName="theta"
          enableTooltip={false}
          name="Theta"
          yAxisName="forth"
        />
      );
    }
  }, [multiline]);

  return (
    <div className="control-pane">
      {/* <style>{SAMPLE_CSS}</style> */}
      <div className="control-section">
        <ChartComponent
          useGroupingSeparator={true}
          id="charts"
          style={{ textAlign: "center" }}
          primaryXAxis={{
            valueType: "Double",
            enableAutoIntervalOnZooming: true,
            crosshairTooltip: { enable: true },
            stripLines: [
              {
                start: marketPrice - standDeviation,
                size: standDeviation * 2,
                color: "#0079f312",
                visible: true,
                zIndex: "Behind",
              },
              {
                start: marketPrice - standDeviation * 2,
                size: standDeviation * 4,
                color: "#0079f312",
                visible: true,
                zIndex: "Behind",
              },
            ],
            // crossesAt: 0,
            // edgeLabelPlacement: "Shift",
            // majorGridLines: { width: 0 },
            // labelFormat: "y",
          }}
          load={load.bind(this)}
          primaryYAxis={{
            // title: "Profits",
            // rangePadding: "None",
            crosshairTooltip: { enable: true },
            // crossesAt: 0,
            // stripLines: [{ start: 0, size: 2, color: "red", visible: true }],
            // minimum: -50000,
            // maximum: 20000,
            // interval: 1000,
            // lineStyle: { width: 0 },
            // majorTickLines: { width: 0 },
            // minorTickLines: { width: 0 },
            // name: "secondary",
            rangePadding: "Auto",
            // maximum={150}
            // minimum={55}
            // majorGridLines: { width: 1 },
            // labelFormat: "n0",
            title: "Delta",
            // plotOffset: 30,
            // lineStyle: { width: 0 },
            labelIntersectAction: "Rotate45",
            plotOffsetTop: 5,
            plotOffsetBottom: 5,
          }}
          chartArea={{ border: { width: 0 } }}
          tooltip={{
            enable: true,
            shared: true,
            format: "${series.name} : ${point.y}",
          }}
          legendSettings={{ enableHighlight: true }}
          width={Browser.isDevice ? "100%" : "95%"}
          height={`${height - 20}px`}
          // title="Crude Steel Production Annual Growth"
          loaded={onChartLoad.bind(this)}
          zoomSettings={{
            enableMouseWheelZooming: false,
            enablePinchZooming: true,
            enableSelectionZooming: true,
            enablePan: true,
          }}
          crosshair={{ enable: true }}
          pointRender={(args) => {
            // console.log("args", args);
            if (args.series.index == 3) {
              // console.log(Math.round(marketPrice / stepper) * stepper);
              if (args.point.x != Math.round(marketPrice / stepper) * stepper)
                args.cancel = true;
              else {
                // console.log("args", args);
                args.fill = "red";
                args.point.marker.visible = true;
                args.shape = "Circle";
              }
            }
          }}
        >
          <Inject
            services={[
              LineSeries,
              ColumnSeries,
              Legend,
              Tooltip,
              Zoom,
              Highlight,
              Crosshair,
              Category,
              StripLine,
              // DataLabel,
            ]}
          />
          <RowsDirective>
            <RowDirective height={"10%"} />
            <RowDirective height={"10%"} />
            <RowDirective height={"10%"} />
            <RowDirective height={"90%"} />
          </RowsDirective>
          <AxesDirective>
            <AxisDirective
              name="forth"
              title="Theta"
              rangePadding="None"
              crosshairTooltip={{ enable: true }}
              // crossesAt="0"
              rowIndex={1}
              labelFormat="n0"
              plotOffsetTop={5}
              plotOffsetBottom={5}
            />
            <AxisDirective
              name="third"
              title="Vega"
              rangePadding="None"
              crosshairTooltip={{ enable: true }}
              // crossesAt="0"
              rowIndex={2}
              labelFormat="n0"
              plotOffsetTop={5}
              plotOffsetBottom={5}
            />
            <AxisDirective
              name="secondary"
              title="Profits"
              rangePadding="None"
              crosshairTooltip={{ enable: true }}
              stripLines={[
                {
                  start: -15,
                  size: 30,
                  color: "red",
                  visible: true,
                  text: "0",
                  horizontalAlignment: "Start",
                  textStyle: {
                    color: "red",
                    size: "16px",
                  },
                  zIndex: "Over",
                },
              ]}
              // crossesAt="0"
              rowIndex={3}
              labelFormat="n0"
              labelIntersectAction="Rotate45"
              plotOffsetTop={5}
              plotOffsetBottom={5}
            />
          </AxesDirective>
          <SeriesCollectionDirective>
            {thetaBar}
            {vegaBar}
            {deltaBar}
            {lines}
            {linesModeling}
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};
export default Line;
