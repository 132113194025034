import { AnyAction, createReducer } from "@reduxjs/toolkit";
import { FeedStoreState } from ".";
import {
  checkNewFeedResolved,
  deleteFeedResolved,
  editFeedResolved,
  getFeed,
  getFeedResolved,
  getNewFeed,
  getNewFeedResolved,
  likeFeedResolved,
  postFeedResolved,
  replyFeedResolved,
  resetFeed,
  setNewFeed,
  unlikeFeedResolved,
} from "./Feed.actions";

export const initialState: FeedStoreState = {
  symbol: "",
  feeds: [],
  newFeed: false,
  isLoading: false,
  isThereNewPage: false,
};

interface FeedReducer {
  [x: string]: (
    state: FeedStoreState,
    action: AnyAction
  ) => FeedStoreState | undefined;
}

const reducer: FeedReducer = {
  [resetFeed.toString()]: () => {
    return initialState;
  },
  [getFeed.toString()]: (state, action) => {
    return {
      ...state,
      newFeed: false,
      isLoading: true,
      symbol: action.payload.symbol,
      feeds: action.payload.clearFeed ? [] : state.feeds,
    };
  },
  [editFeedResolved.toString()]: (state, action) => ({
    ...state,
    feeds: state.feeds.map((feed) =>
      feed.feedId === action.payload.feedId ? action.payload : feed
    ),
  }),
  [getFeedResolved.toString()]: (state, action) => {
    return {
      ...state,
      feeds:
        action.payload.skip === 0
          ? action.payload.items
          : [...state.feeds, ...action.payload.items],
      newFeed: false,
      isLoading: false,
      isThereNewPage: action.payload?.hasMore,
    };
  },
  [getNewFeed.toString()]: (state, action) => ({
    ...state,
    newFeed: false,
  }),
  [getNewFeedResolved.toString()]: (state, action) => ({
    ...state,
    feeds: [...action.payload, ...state.feeds],
    newFeed: false,
  }),
  [postFeedResolved.toString()]: (state, action) => ({
    ...state,
    feeds: state.feeds?.splice(0, 0, action.payload),
  }),
  [deleteFeedResolved.toString()]: (state, action) => ({
    ...state,
    feeds: state.feeds
      .filter((feed) => feed.feedId !== action.payload)
      .map((feed) => {
        if (
          feed.replies &&
          feed.replies.length > 0 &&
          feed.replies.findIndex((value) => value.feedId === action.payload) >=
            0
        ) {
          return {
            ...feed,
            replies: feed.replies.filter(
              (replyFeed) => replyFeed !== action.payload
            ),
          };
        } else return feed;
      }),
  }),
  [checkNewFeedResolved.toString()]: (state, action) => ({
    ...state,
    newFeed: action.payload,
  }),
  [setNewFeed.toString()]: (state, action) => ({
    ...state,
    newFeed: action.payload,
  }),
  [replyFeedResolved.toString()]: (state, action) => ({
    ...state,
    feeds: state.feeds.map((feed) =>
      feed.feedId === action.payload.feedId ? action.payload : feed
    ),
  }),
  [likeFeedResolved.toString()]: (state, action) => ({
    ...state,
    feeds: state.feeds.map((feed) =>
      feed.feedId === action.payload.feedId ? action.payload : feed
    ),
  }),
  [unlikeFeedResolved.toString()]: (state, action) => ({
    ...state,
    feeds: state.feeds.map((feed) =>
      feed.feedId === action.payload.feedId ? action.payload : feed
    ),
  }),
};

export default createReducer(initialState, reducer);
