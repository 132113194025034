import { StoreState } from "redux/store.types";
import { createSelector } from "reselect";

const storeState = (state: StoreState) => state;

export const selectStatsStore = createSelector(
  storeState,
  (storeState) => storeState?.stats
);

export const selectorYearly = createSelector(
  [selectStatsStore],
  (statsStore) => statsStore?.yearly
);
