import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormDialogProps } from "./FormDialog.type";
import Grid from "@mui/material/Grid2";
import { Forms } from "components";

export default function FormDialog({
  SubmitButtonLabel = "Submit",
  CancelButtonLabel = "Cancel",
  HideButton = false,
  ContentText = "",
  ...props
}: FormDialogProps) {
  const [open_, setOpen_] = React.useState(false);
  const open = props.OpenState ? props.OpenState : open_;
  const setOpen = props.SetOpenState ? props.SetOpenState : setOpen_;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event: any) => {
    if (props.onSubmit) props.onSubmit(event);
    handleClose();
  };

  const handleCancel = (event: any) => {
    if (props.onCancel) props.onCancel(event);
    handleClose();
  };

  return (
    <div>
      {HideButton ? (
        <div />
      ) : (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {props.OpenButtonLabel}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionProps={{
          onEnter: props.onDialogEnter,
          onExited: props.onDialogExit,
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">{props.DialogTitle}</DialogTitle>
          <DialogContent>
            <Grid container>
              {ContentText !== "" ? (
                <DialogContentText>{ContentText}</DialogContentText>
              ) : null}
              <Forms includeFormTag={false} formArray={props.FormArray}></Forms>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              {SubmitButtonLabel}
            </Button>
            <Button onClick={handleCancel} color="primary">
              {CancelButtonLabel}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
