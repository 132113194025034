import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { selectorYearly } from "redux/Stats/Yearly/Yearly.selectors";
import { currency2DigitFormatter } from "utils";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#6392F6",
      color: "#fff",
      textAlign: "center",
    },
    body: {
      fontSize: 14,
      textAlign: "center",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const YearlyIncome = () => {
  const classes = useStyles();

  const yearly = useSelector(selectorYearly);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Year</StyledTableCell>
            <StyledTableCell>Net Income</StyledTableCell>
            <StyledTableCell>Start Value</StyledTableCell>
            <StyledTableCell>Dividends</StyledTableCell>
            <StyledTableCell>Commission + Fee</StyledTableCell>
            <StyledTableCell>Annual Return</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {yearly.map((row) => (
            <StyledTableRow key={moment(row.date).format("YYYY")}>
              <StyledTableCell component="th" scope="row">
                {moment(row.date).format("YYYY")}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.income)}
              </StyledTableCell>
              <StyledTableCell>0</StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.dividends)}
              </StyledTableCell>
              <StyledTableCell>
                {currency2DigitFormatter.format(row.comissions + row.fee)}
              </StyledTableCell>
              <StyledTableCell>0</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
