import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { toggleDeleteDrawer } from "redux/UI";
import { selectorDeleteDrawer } from "redux/UI/UI.selectors";
import { deleteEntry, SelectSelectedEntry } from "redux/JournalEntries";
import LoaderButton from "components/LoaderButton";

export const DeleteEntry = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(toggleDeleteDrawer());
    setIsLoading(false);
  };

  const entry = SelectSelectedEntry();
  const DeleteClicker = () => {
    setIsLoading(true);

    if (entry && entry.entryId)
      dispatch(deleteEntry({ entryIds: [entry.entryId] }));
    setIsLoading(false);
  };
  return (
    <Dialog
      open={useSelector(selectorDeleteDrawer)}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Entry</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the selected entry?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoaderButton
          onClick={DeleteClicker}
          color="secondary"
          isLoading={isLoading}
        >
          Yes
        </LoaderButton>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
