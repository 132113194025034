import { createAction } from "@reduxjs/toolkit";
import { PLStat } from "../Stats.types";

export const resetStat = createAction("stats/reset");

export const getMonthly = createAction<string>("stats/getMonthly");

export const getMonthlyResolved = createAction<PLStat[]>(
  "feed/getMonthlyResolved"
);

export const getMonthlyRejected = createAction("feed/getMonthlyRejected");
