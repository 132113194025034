import { Monthly, SymbolPage, TagPage, UserFeeds, UserSetting } from "pages";
import ChangeEmail from "pages/ChangeEmail/ChangeEmail";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import { FeedPage } from "pages/FeedPage";
import Login from "pages/Login/Login";
import { NewsFeed } from "pages/NewsFeed";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import Signup from "pages/Signup/Signup";
import { ThreePartPageWrapper } from "pages/ThreePartPageWrapper";
import {
  Route,
  useLocation,
  Routes as RouterRoutes,
  Navigate,
} from "react-router-dom";
import { useAppContext } from "libs/contextLib";

const getTitle = (path: string) => {
  switch (path) {
    case "/analysis":
      return "Risk Analysis";
    case "/newsfeed":
      return "News Feed";
    case "/plstats":
      return "P/L Stats";
    case "/settings":
      return "Settings";
    default:
      const match = path.match("//feed//g");
      if (match && match?.length > 0) return "News Feed";
      else return "Trader Journal";
  }
};

export default function Routes() {
  const location = useLocation();
  const { isAuthenticated } = useAppContext();

  return (
    <ThreePartPageWrapper title={getTitle(location.pathname)}>
      {isAuthenticated ? (
        <RouterRoutes>
          <Route path="/settings" element={<UserSetting />}></Route>
          <Route path="/settings/email" element={<ChangeEmail />}></Route>
          <Route path="/settings/password" element={<ChangePassword />}></Route>
          <Route path="/plstats" element={<Monthly />}></Route>
          <Route path="/symbol/:symbol" element={<SymbolPage />}></Route>
          <Route path="/symbol" element={<SymbolPage />}></Route>
          <Route path="/newsfeed" element={<NewsFeed />}></Route>
          <Route path="/:userName/feed/:feedId" element={<FeedPage />}></Route>
          <Route path="/:userName" element={<UserFeeds />}></Route>
          <Route path="/hashtag/:tag" element={<TagPage />}></Route>
          <Route path="/login" element={<Navigate to="/" />}></Route>
          <Route path="/login/reset" element={<Navigate to="/" />}></Route>
          <Route path="/signup" element={<Navigate to="/" />}></Route>
          <Route path="/*" element={<Navigate to="/" />}></Route>
        </RouterRoutes>
      ) : (
        <RouterRoutes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/login/reset" element={<ResetPassword />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/*" element={<Navigate to="/login" />}></Route>
        </RouterRoutes>
      )}
      {/* <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/login/reset">
          <ResetPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/signup">
          <Signup />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/settings">
          <UserSetting />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/email">
          <ChangeEmail />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/password">
          <ChangePassword />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/plstats">
          <Monthly />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/symbol/:symbol">
          <SymbolPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/symbol">
          <SymbolPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/newsfeed">
          <NewsFeed />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/:userName/feed/:feedId">
          <FeedPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/:userName">
          <UserFeeds />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/hashtag/:tag">
          <TagPage />
        </AuthenticatedRoute> */}
      {/* Finally, catch all unmatched routes */}
      {/* <Route>
        <NotFound />
      </Route> */}
      {/* </RouterRoutes> */}
    </ThreePartPageWrapper>
  );
}
