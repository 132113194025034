import { useState } from "react";
import clsx from "clsx";
import Divider from "@mui/material/Divider";
import { useFormFields } from "libs/hooksLib";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoaderButton from "components/LoaderButton";
import { getStrategyTemplate, StrategyEnum, GetCommonDiv } from "journal-lib";
import { useDispatch, useSelector } from "react-redux";
import { addEntry } from "redux/JournalEntries";
import { selectorAccountStore } from "redux/Account/Account.selectors";
import { selectorAddEntryDrawer } from "redux/UI/UI.selectors";
import { toggleAddEntryDrawer } from "redux/UI/UI.actions";
import {
  getGridSize,
  getNowDateString,
  stringToUTC,
  usePhoneScreenMedia,
} from "utils";
import Autocomplete from "@mui/material/Autocomplete";
import { selectorStockExpDate } from "redux/Stock/Stock.selectors";
import {
  clearExpDate,
  getEntryPreviewPLChartOnNew,
  getExpDate,
} from "redux/Stock/Stock.actions";
import {
  formValidation,
  getFieldErrorMsg,
  validateField,
} from "utils/formValidation";
import moment from "moment";
import { useEffect } from "react";
import { forwardRef, useImperativeHandle } from "react";
import { useAddEntryStyles } from "./AddEntrr.styles";
import { PLChart } from "components/PLChart/PLChart.components";

export const AddEntry = forwardRef(
  (
    {
      isDialog = true,
      fields_parent,
      handleFieldChange_parent,
      totalPrice_parent,
      setTotalPrice_parent,
      validateMode = false,
    }: {
      isDialog?: boolean;
      fields_parent?: any;
      handleFieldChange_parent?: any;
      totalPrice_parent?: number;
      setTotalPrice_parent?: any;
      validateMode?: boolean;
    },
    ref
  ) => {
    const classes = useAddEntryStyles();
    const dispatch = useDispatch();

    const [showError, setShowError] = useState(false);
    const [showPL, setShowPL] = useState(false);

    useEffect(() => {
      setShowError(validateMode);
    }, [validateMode]);

    const accounts = useSelector(selectorAccountStore);
    const accountArray: { value: string; label: string }[] = Object.keys(
      accounts
    ).map((accountId) => {
      return {
        value: accountId,
        label: accounts[accountId].description,
      };
    });

    const expDates = useSelector(selectorStockExpDate);
    const expDatesSelectOptions = expDates?.map((string) => ({
      label: string,
      value: string,
    }));
    const [postToFeed, setPostToFeed] = useState(true);
    const [totalPrice_, setTotalPrice_] = useState(0);
    const totalPrice = totalPrice_parent ? totalPrice_parent : totalPrice_;
    const setTotalPrice = setTotalPrice_parent
      ? setTotalPrice_parent
      : setTotalPrice_;
    const [fields_, handleFieldChange_] = useFormFields({
      symbol: "",
      name: { value: "ShortPut", label: "Short Put" },
      entryDate: getNowDateString(),
      price: 0,
      debitCredit: { value: "credit", label: "Credit" },
      size: 1,
      account:
        accountArray?.length > 0
          ? { value: accountArray[0].value, label: accountArray[0].label }
          : { value: "", label: "" },
      expDate: { label: "", value: "" },
      expDateInput: "",
      note: "",
      strikes: "",

      type1: { value: "sto", label: "Sell to Open" },
      strike1: 0,
      expDate1: { label: "", value: "" },
      expDate1Input: "",
      qty1: 0,
      putCall1: { value: "put", label: "Put" },
      type2: { value: "sto", label: "Sell to Open" },
      strike2: 0,
      expDate2: { label: "", value: "" },
      expDate2Input: "",
      qty2: 0,
      putCall2: { value: "put", label: "Put" },
      type3: { value: "sto", label: "Sell to Open" },
      strike3: 0,
      expDate3: { label: "", value: "" },
      expDate3Input: "",
      qty3: 0,
      putCall3: { value: "put", label: "Put" },
      type4: { value: "sto", label: "Sell to Open" },
      strike4: 0,
      expDate4: { label: "", value: "" },
      expDate4Input: "",
      qty4: 0,
      putCall4: { value: "put", label: "Put" },
    });
    // const [fields_label, handleFieldChange_label] = useFormFields({
    //   name: { value: "ShortPut", label: "Short Put" },
    //   debitCredit: { value: "credit", label: "Credit" },
    //   account: "",
    //   type1: { value: "sto", label: "Sell to Open" },
    //   type2: { value: "sto", label: "Sell to Open" },
    //   type3: { value: "sto", label: "Sell to Open" },
    //   type4: { value: "sto", label: "Sell to Open" },
    // });
    const fields = isDialog ? fields_ : fields_parent;

    const previewPL = () => {
      if (validateForm.all.isValid()) {
        dispatch(getEntryPreviewPLChartOnNew(getAddBody()));
        setShowPL(true);
      }
    };

    const handleFieldChange = isDialog
      ? handleFieldChange_
      : handleFieldChange_parent;

    const [isLoading, setIsLoading] = useState(false);

    // const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
    //   if (
    //     event.type === "keydown" &&
    //     (event.key === "Tab" || event.key === "Shift")
    //   ) {
    //     return;
    //   }

    //   dispatch(toggleAddEntryDrawer());
    // };

    const validateForm: formValidation = {
      account: {
        isValid: () => {
          if (!isDialog) return true;

          return fields["account"]?.value?.length > 0;
        },
        msg: "Please choose an account",
      },
      symbol: {
        isValid: () => {
          if (!isDialog) return true;
          return fields["symbol"]?.length > 0;
        },
        msg: "Please enter a symbol",
      },
      price: {
        isValid: () => {
          if (!isDialog) return true;
          if (fields["name"].value != "Custom") return fields["price"] >= 0;
          else return true;
        },
        msg: "Price must be positive",
      },
      size: {
        isValid: () => {
          if (fields["name"].value != "Custom") return fields["size"] >= 1;
          else return true;
        },
        msg: "Size must be greater than 1",
      },
      strikes: {
        isValid: () => {
          if (fields["name"].value == "Custom") return true;

          const strikes: string = fields["strikes"];

          const key = fields.name.value as keyof typeof StrategyEnum;

          if (!getStrategyTemplate(StrategyEnum[key])) return false;

          const strategyObject = getStrategyTemplate(StrategyEnum[key]);
          const strikeArray = strikes.split("/");

          if (!strategyObject.legs) return true;

          if (strikes.trim().length == 0) return false;

          if (fields.name.value == "CoveredCall") {
            if (strikeArray.length != 1) return false;
          } else if (strikeArray.length != strategyObject.legs.length) {
            return false;
          }

          let sType = "";
          let nMin = 0;

          for (let nIndex = 0; nIndex < strategyObject.legs.length; nIndex++) {
            if (
              nMin == 0 ||
              sType == "" ||
              sType != strategyObject.legs[nIndex].option.type
            ) {
              nMin = Number(strikeArray[nIndex]);
              sType = strategyObject.legs[nIndex].option.type;
              continue;
            }

            if (nMin > Number(strikeArray[nIndex])) {
              return false;
            } else {
              nMin = Number(strikeArray[nIndex]);
            }
          }

          return true;
        },
        msg: "",
      },
      expDate: {
        isValid: () => {
          const input = fields["expDateInput"];
          const strategy = fields["name"].value;

          if (
            strategy !== "LongStock" &&
            strategy !== "ShortStock" &&
            strategy !== "LongFuture" &&
            strategy !== "ShortFuture" &&
            strategy !== "Custom"
          ) {
            if (input && input.trim().length > 0) {
              const inputDate = moment(input, "MM/DD/YYYY");
              if (!inputDate.isValid()) return false;

              if (inputDate >= moment().startOf("d")) {
                if (expDates.length > 1) {
                  if (expDates.indexOf(input) >= 0) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            } else return false;
          }

          return true;
        },
        msg: "Please select a valid expiration date.",
      },
      expDate1: {
        isValid: () => {
          const input = fields["expDate1Input"];
          const strategy = fields["name"].value;

          if (strategy === "Custom") {
            if (input && input.trim().length > 0) {
              const inputDate = moment(input, "MM/DD/YYYY");
              if (!inputDate.isValid()) return false;

              if (inputDate >= moment().startOf("d")) {
                if (expDates.length > 1) {
                  if (expDates.indexOf(input) >= 0) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            } else return false;
          }

          return true;
        },
        msg: "Please select a valid expiration date.",
      },
      strike1: {
        isValid: () => {
          if (fields["name"].value != "Custom") return true;

          return fields["strike1"] >= 1;
        },
        msg: "Strike must be greater than 0",
      },
      qty1: {
        isValid: () => {
          return fields["strike1"] >= 1 ? fields["qty1"] != 0 : true;
        },
        msg: "QTY must not be 0",
      },
      expDate2: {
        isValid: () => {
          const input = fields["expDate2Input"];
          const strategy = fields["name"].value;

          if (strategy === "Custom") {
            if (input && input.trim().length > 0) {
              const inputDate = moment(input, "MM/DD/YYYY");
              if (!inputDate.isValid()) return false;

              if (inputDate >= moment().startOf("d")) {
                if (expDates.length > 1) {
                  if (expDates.indexOf(input) >= 0) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            }
          }

          return true;
        },
        msg: "Please select a valid expiration date.",
      },
      strike2: {
        isValid: () => {
          if (fields["expDate2Input"].trim().length > 0)
            return fields["strike2"] >= 1;

          return true;
        },
        msg: "Strike must be greater than 0",
      },
      qty2: {
        isValid: () => {
          return fields["strike2"] >= 1 ? fields["qty2"] != 0 : true;
        },
        msg: "QTY must not be 0",
      },
      expDate3: {
        isValid: () => {
          const input = fields["expDate3Input"];
          const strategy = fields["name"].value;

          if (strategy === "Custom") {
            if (input && input.trim().length > 0) {
              const inputDate = moment(input, "MM/DD/YYYY");
              if (!inputDate.isValid()) return false;

              if (inputDate >= moment().startOf("d")) {
                if (expDates.length > 1) {
                  if (expDates.indexOf(input) >= 0) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            }
          }

          return true;
        },
        msg: "Please select a valid expiration date.",
      },
      strike3: {
        isValid: () => {
          if (fields["expDate3Input"].trim().length > 0)
            return fields["strike3"] >= 1;

          return true;
        },
        msg: "Strike must be greater than 0",
      },
      qty3: {
        isValid: () => {
          return fields["strike3"] >= 1 ? fields["qty3"] != 0 : true;
        },
        msg: "QTY must not be 0",
      },
      expDate4: {
        isValid: () => {
          const input = fields["expDate4Input"];
          const strategy = fields["name"].value;

          if (strategy === "Custom") {
            if (input && input.trim().length > 0) {
              const inputDate = moment(input, "MM/DD/YYYY");
              if (!inputDate.isValid()) return false;

              if (inputDate >= moment().startOf("d")) {
                if (expDates.length > 1) {
                  if (expDates.indexOf(input) >= 0) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            }
          }

          return true;
        },
        msg: "Please select a valid expiration date.",
      },
      strike4: {
        isValid: () => {
          if (fields["expDate4Input"].trim().length > 0)
            return fields["strike4"] >= 1;

          return true;
        },
        msg: "Strike must be greater than 0",
      },
      qty4: {
        isValid: () => {
          return fields["strike4"] >= 1 ? fields["qty4"] != 0 : true;
        },
        msg: "QTY must not be 0",
      },

      all: {
        isValid: () => {
          for (const key in validateForm) {
            // console.log(key);
            if (key != "all" && !validateForm[key].isValid()) return false;
          }
          return true;
        },
        msg: "",
      },
    };

    const isFieldValid = (sField: string) =>
      validateField(sField, validateForm);
    const fieldErrorMsg = (sField: string) =>
      getFieldErrorMsg(sField, validateForm);

    useImperativeHandle(ref, () => ({
      validateAll() {
        return validateForm.all.isValid();
      },
    }));

    const getAddBody = () => {
      let price = totalPrice;
      if (
        fields.name.value === "ShortStock" ||
        fields.name.value === "LongStock"
      )
        price = totalPrice / 100;

      return {
        ...fields,
        symbol: fields.symbol.toUpperCase(),
        account: fields.account.value,
        name: fields.name.value,
        type1: fields.type1.value,
        type2: fields.type2.value,
        type3: fields.type3.value,
        type4: fields.type4.value,
        putCall1: fields.putCall1.value,
        putCall2: fields.putCall2.value,
        putCall3: fields.putCall3.value,
        putCall4: fields.putCall4.value,
        price: fields["debitCredit"].value === "credit" ? price : price * -1,
        entryDate: stringToUTC(fields.entryDate),
        expDate:
          fields.expDate.value.trim().length > 0
            ? stringToUTC(fields.expDate.value)
            : stringToUTC(fields["expDateInput"]),
        expDate1:
          fields.expDate1.value.trim().length > 0
            ? stringToUTC(fields.expDate1.value)
            : stringToUTC(fields["expDate1Input"]),
        expDate2:
          fields.expDate2.value.trim().length > 0
            ? stringToUTC(fields.expDate2.value)
            : stringToUTC(fields["expDate2Input"]),
        expDate3:
          fields.expDate3.value.trim().length > 0
            ? stringToUTC(fields.expDate3.value)
            : stringToUTC(fields["expDate3Input"]),
        expDate4:
          fields.expDate4.value.trim().length > 0
            ? stringToUTC(fields.expDate4.value)
            : stringToUTC(fields["expDate4Input"]),

        postToFeed: postToFeed,
        note: fields.note,
      };
    };

    async function handleSubmit(event: any) {
      setIsLoading(true);
      event.preventDefault();

      if (validateForm.all.isValid()) {
        try {
          dispatch(addEntry(getAddBody()));
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      } else {
        setShowError(true);
        setIsLoading(false);
      }
    }

    let strategyItemList: { value: string; label: string }[] = [];
    for (const strategy in StrategyEnum) {
      const strategyKey = strategy as keyof typeof StrategyEnum;
      if (!Number.isInteger(+strategyKey))
        if (getStrategyTemplate(StrategyEnum[strategyKey])?.name)
          strategyItemList.push({
            value: strategy,
            label: getStrategyTemplate(StrategyEnum[strategyKey])?.name,
          });
    }

    const getStrikeHelperText = () => {
      if (fields.name && fields.name.value && fields.name.value.length > 0) {
        const key = fields.name.value as keyof typeof StrategyEnum;
        return getStrategyTemplate(StrategyEnum[key]).example;
      } else return "";
    };

    const getTotalPriceMultiplier = (e: any) => {
      const qtyArray = (
        e.target.id === "price"
          ? [fields.qty1, fields.qty2, fields.qty3, fields.qty4]
          : [
              e.target.id === "qty1" ? e.target.value : fields.qty1,
              e.target.id === "qty2" ? e.target.value : fields.qty2,
              e.target.id === "qty3" ? e.target.value : fields.qty3,
              e.target.id === "qty4" ? e.target.value : fields.qty4,
            ]
      ).filter((qty) => qty !== 0 && qty !== "");

      if (qtyArray.length > 1) {
        return Math.abs(GetCommonDiv(qtyArray));
      } else if (qtyArray.length === 1) {
        return Math.abs(qtyArray[0]);
      } else {
        return 0;
      }
    };

    const list = (anchor: string) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        //   onClick={toggleDrawer(anchor, false)}
        //   onKeyDown={toggleDrawer(anchor, false)}
      >
        <Grid container spacing={3} style={{ padding: "0 20px" }}>
          {[
            {
              id: "account",
              label: "Account",
              type: "select",
              items: accountArray,
              xsSize: getGridSize(6),
              gridSize: getGridSize(3),
              error: !isFieldValid("account"),
              helperText: fieldErrorMsg("account"),
              dialogOnly: true,
              autoFocus: true,
            },
            {
              id: "symbol",
              label: "Symbol",
              type: "text",
              xsSize: getGridSize(6),
              gridSize: getGridSize(3),
              error: !isFieldValid("symbol"),
              helperText: fieldErrorMsg("symbol"),
              dialogOnly: true,
            },
            {
              id: "name",
              label: "Strategy",
              type: "select",
              items: strategyItemList,
              xsSize: getGridSize(6),
              gridSize: isDialog ? getGridSize(4) : getGridSize(12),
              dialogOnly: false,
            },
            {
              id: "debitCredit",
              label: "Debit/Credit",
              type: "select",
              items: [
                { value: "debit", label: "Debit" },
                { value: "credit", label: "Credit" },
              ],
              xsSize: getGridSize(6),
              gridSize: getGridSize(2),
              dialogOnly: true,
            },
            {
              id: "entryDate",
              label: "Entry Date",
              type: "date",
              xsSize: getGridSize(6),
              gridSize: getGridSize(4),
              dialogOnly: true,
            },
            {
              id: "price",
              label: "Entry Price",
              type: "number",
              xsSize: getGridSize(6),
              gridSize: getGridSize(4),
              error: !isFieldValid("price"),
              helperText: fieldErrorMsg("price"),
              dialogOnly: true,
            },
            {
              id: "totalPrice",
              label: "Total Price",
              type: "number",
              xsSize: getGridSize(6),
              gridSize: getGridSize(4),
              helperText: "Price must be positive",
              dialogOnly: true,
            },
          ].map((text) =>
            !isDialog && text.dialogOnly ? (
              <div key={text.id} />
            ) : text.type === "select" ? (
              <Grid
                size={{
                  xs: text.xsSize ? text.xsSize : text.gridSize,
                  sm: text.xsSize ? text.gridSize : undefined,
                }}
                key={text.id}
              >
                <Autocomplete
                  value={fields[text.id]}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleFieldChange(
                        { target: { id: text.id, value: newValue } },
                        text.id
                      );
                    } else {
                      handleFieldChange(
                        { target: { id: text.id, value: "" } },
                        text.id
                      );
                    }
                  }}
                  // inputValue={fields_label[text.id]}
                  // onInputChange={(event, newValue) => {
                  //   handleFieldChange_label(
                  //     { target: { id: text.id, value: newValue } },
                  //     text.id
                  //   );
                  // }}
                  autoSelect
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                  }}
                  id={text.id}
                  options={text.items ? text.items : []}
                  getOptionLabel={(option: any) =>
                    option?.label ? option.label : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={text.label}
                      autoFocus={text.autoFocus ? true : false}
                      // variant="outlined"
                    />
                  )}
                  // fullWidth
                />
                {/* <FormControl fullWidth>
                <InputLabel id={`${text.id}-label`}>{text.label}</InputLabel>
                <Select
                  fullWidth
                  labelId={`${text.id}-label`}
                  id={text.id}
                  value={fields[text.id]}
                  onChange={(event) => {
                    handleFieldChange(event, text.id);
                  }}
                >
                  {text.items ? (
                    text.items.map((menuItem) => (
                      <MenuItem
                        value={menuItem.value}
                        id={text.id}
                        key={text.id}
                      >
                        {menuItem.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={" "}> </MenuItem>
                  )}
                </Select>
              </FormControl> */}
              </Grid>
            ) : text.type === "date" ? (
              <Grid
                size={{
                  xs: text.xsSize ? text.xsSize : text.gridSize,
                  sm: text.xsSize ? text.gridSize : undefined,
                }}
                key={text.id}
              >
                <TextField
                  fullWidth
                  id={text.id}
                  label={text.label}
                  value={fields[text.id]}
                  onChange={handleFieldChange}
                  type="date"
                />
              </Grid>
            ) : (
              <Grid
                size={{
                  xs: text.xsSize ? text.xsSize : text.gridSize,
                  sm: text.xsSize ? text.gridSize : undefined,
                }}
                key={text.id}
              >
                <TextField
                  className={clsx({
                    [classes.debit]:
                      (text.id === "price" || text.id === "totalPrice") &&
                      fields.debitCredit.value === "debit",
                    [classes.credit]:
                      (text.id === "price" || text.id === "totalPrice") &&
                      fields.debitCredit.value === "credit",
                  })}
                  error={showError && text.error ? text.error : false}
                  helperText={showError && text.error ? text.helperText : ""}
                  fullWidth
                  id={text.id}
                  label={text.label}
                  type={text.type}
                  value={
                    text.id === "totalPrice" ? totalPrice : fields[text.id]
                  }
                  onBlur={() => {
                    if (
                      text.id === "symbol" &&
                      fields.symbol &&
                      fields.symbol.trim().length > 0
                    )
                      dispatch(getExpDate(fields.symbol));
                  }}
                  onChange={(e) => {
                    if (text.id === "totalPrice") {
                      setTotalPrice(+e.target.value);
                    } else {
                      handleFieldChange(e);

                      if (text.id === "price") {
                        setTotalPrice(
                          Math.round(
                            +e.target.value *
                              (fields.name.value === "Custom"
                                ? getTotalPriceMultiplier(e)
                                : fields.size) *
                              100
                          ) / 100
                        );
                      }
                    }
                  }}
                  inputProps={
                    text.id === "symbol"
                      ? { style: { textTransform: "uppercase" } }
                      : text.id === "totalPrice"
                      ? { readOnly: true }
                      : text.type === "number"
                      ? { step: 0.01, inputMode: "decimal" }
                      : { step: 0.01 }
                  }
                />
              </Grid>
            )
          )}

          <Divider />

          {fields["name"].value === "Custom"
            ? [
                // {
                //   id: "type1",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate1",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate1"),
                  helperText:
                    showError && !isFieldValid("expDate1")
                      ? fieldErrorMsg("expDate1")
                      : "",
                },
                {
                  id: "strike1",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike1"),
                  helperText:
                    showError && !isFieldValid("strike1")
                      ? fieldErrorMsg("strike1")
                      : "",
                },
                {
                  id: "putCall1",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty1",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty1"),
                  helperText:
                    showError && !isFieldValid("qty1")
                      ? fieldErrorMsg("qty1")
                      : "",
                },
                // {
                //   id: "type2",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate2",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate2"),
                  helperText:
                    showError && !isFieldValid("expDate2")
                      ? fieldErrorMsg("expDate2")
                      : "",
                },
                {
                  id: "strike2",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike2"),
                  helperText:
                    showError && !isFieldValid("strike2")
                      ? fieldErrorMsg("strike2")
                      : "",
                },
                {
                  id: "putCall2",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty2",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty2"),
                  helperText:
                    showError && !isFieldValid("qty2")
                      ? fieldErrorMsg("qty2")
                      : "",
                },
                // {
                //   id: "type3",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate3",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate3"),
                  helperText:
                    showError && !isFieldValid("expDate3")
                      ? fieldErrorMsg("expDate3")
                      : "",
                },
                {
                  id: "strike3",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike3"),
                  helperText:
                    showError && !isFieldValid("strike3")
                      ? fieldErrorMsg("strike3")
                      : "",
                },
                {
                  id: "putCall3",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty3",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty3"),
                  helperText:
                    showError && !isFieldValid("qty3")
                      ? fieldErrorMsg("qty3")
                      : "",
                },
                // {
                //   id: "type4",
                //   label: "Side & Open/Close",
                //   type: "select",
                //   gridSize: getGridSize(3),
                // },
                {
                  id: "expDate4",
                  label: "Exp. Date",
                  type: "select",
                  items: expDatesSelectOptions,
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("expDate4"),
                  helperText:
                    showError && !isFieldValid("expDate4")
                      ? fieldErrorMsg("expDate4")
                      : "",
                },
                {
                  id: "strike4",
                  label: "Strike",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("strike4"),
                  helperText:
                    showError && !isFieldValid("strike4")
                      ? fieldErrorMsg("strike4")
                      : "",
                },
                {
                  id: "putCall4",
                  label: "Put/Call",
                  type: "select",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                },
                {
                  id: "qty4",
                  label: "QTY",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("qty4"),
                  helperText:
                    showError && !isFieldValid("qty4")
                      ? fieldErrorMsg("qty4")
                      : "",
                },
              ].map((text) => (
                <Grid
                  size={{
                    xs: text.xsSize ? text.xsSize : text.gridSize,
                    sm: text.xsSize ? text.gridSize : undefined,
                  }}
                  key={text.id}
                >
                  {text.type === "select" ? (
                    <Autocomplete
                      value={fields[text.id]}
                      freeSolo
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleFieldChange(
                            { target: { id: text.id, value: newValue } },
                            text.id
                          );
                        } else {
                          handleFieldChange(
                            { target: { id: text.id, value: "" } },
                            text.id
                          );
                        }
                      }}
                      // inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        handleFieldChange(
                          {
                            target: {
                              id: `${text.id}Input`,
                              value: newInputValue,
                            },
                          },
                          `${text.id}Input`
                        );
                      }}
                      id={text.id}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      options={
                        text.items
                          ? text.items
                          : text.id.includes("type")
                          ? [
                              { label: "Sell to Open", value: "sto" },
                              { label: "Buy to Open", value: "bto" },
                            ]
                          : [
                              { label: "Put", value: "put" },
                              { label: "Call", value: "call" },
                            ]
                      }
                      getOptionLabel={(option: any) =>
                        option && option.label ? option.label : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={text.label}
                          error={text.error}
                          helperText={text.helperText}
                        />
                      )}
                      // fullWidth
                    />
                  ) : (
                    // <FormControl fullWidth>
                    //   <InputLabel id={`${text.id}-label`}>
                    //     {text.label}
                    //   </InputLabel>
                    //   <Select
                    //     fullWidth
                    //     labelId={`${text.id}-label`}
                    //     id={text.id}
                    //     value={fields[text.id]}
                    //     onChange={(e) => {
                    //       handleFieldChange(e, text.id);
                    //     }}
                    //   >
                    //     <MenuItem value={"sto"}>Sell to Open</MenuItem>
                    //     <MenuItem value={"bto"}>Buy to Open</MenuItem>
                    //   </Select>
                    // </FormControl>
                    // text.type == "date" ? (
                    //   <KeyboardDatePicker
                    //     disableToolbar
                    //     variant="inline"
                    //     format="MM/dd/yyyy"
                    //     margin="normal"
                    //     id={text.id}
                    //     label={text.label}
                    //     value={fields[text.id]}
                    //     onChange={handleFieldChange}
                    //     KeyboardButtonProps={{
                    //       "aria-label": text.label,
                    //     }}
                    //   />
                    // ) :
                    <TextField
                      fullWidth
                      id={text.id}
                      label={text.label}
                      type={text.type}
                      value={fields[text.id]}
                      onChange={(e) => {
                        handleFieldChange(e);
                        if (
                          text.id === "qty1" ||
                          text.id === "qty2" ||
                          text.id === "qty3" ||
                          text.id === "qty4"
                        ) {
                          setTotalPrice(
                            Math.round(
                              fields.price * getTotalPriceMultiplier(e) * 100
                            ) / 100
                          );
                        }
                      }}
                      InputLabelProps={
                        text.type === "date"
                          ? {
                              shrink: true,
                            }
                          : {}
                      }
                      inputProps={
                        text.type === "number" ? { inputMode: "tel" } : {}
                      }
                      error={text.error}
                      helperText={text.helperText}
                    />
                  )}
                </Grid>
              ))
            : [
                {
                  id: "expDate",
                  label: "Expiration Date",
                  type: "select",
                  items: expDates?.map((string) => ({
                    label: string,
                    value: string,
                  })),
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(4),
                  error: showError && !isFieldValid("expDate"),
                  helperText:
                    showError && !isFieldValid("expDate")
                      ? fieldErrorMsg("expDate")
                      : "",
                },
                {
                  id: "strikes",
                  label: "Strikes",
                  type: "text",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(5),
                  error: showError && !isFieldValid("strikes"),
                  helperText: getStrikeHelperText(),
                },
                {
                  id: "size",
                  label: "Size",
                  type: "number",
                  xsSize: getGridSize(6),
                  gridSize: getGridSize(3),
                  error: showError && !isFieldValid("size"),
                  helperText:
                    showError && !isFieldValid("size")
                      ? fieldErrorMsg("size")
                      : "",
                },
              ].map((text) => (
                <Grid
                  size={{
                    xs: text.xsSize ? text.xsSize : text.gridSize,
                    sm: text.xsSize ? text.gridSize : undefined,
                  }}
                  key={text.id}
                >
                  {text.type === "select" ? (
                    <Autocomplete
                      value={fields[text.id]}
                      freeSolo
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleFieldChange(
                            { target: { id: text.id, value: newValue } },
                            text.id
                          );
                        } else {
                          handleFieldChange(
                            { target: { id: text.id, value: "" } },
                            text.id
                          );
                        }
                      }}
                      // inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        handleFieldChange(
                          {
                            target: {
                              id: `${text.id}Input`,
                              value: newInputValue,
                            },
                          },
                          `${text.id}Input`
                        );
                      }}
                      id={text.id}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      options={
                        text.items ? text.items : [{ label: "", value: "" }]
                      }
                      getOptionLabel={(option: any) =>
                        option && option.label ? option.label : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={text.label}
                          error={text.error}
                          helperText={text.helperText}
                        />
                      )}
                      // fullWidth
                    />
                  ) : (
                    <TextField
                      fullWidth
                      id={text.id}
                      label={text.label}
                      type={text.type}
                      value={fields[text.id]}
                      onChange={(e) => {
                        handleFieldChange(e);

                        if (text.id === "size")
                          setTotalPrice(
                            Math.round(fields.price * +e.target.value * 100) /
                              100
                          );
                      }}
                      InputLabelProps={
                        text.type === "date"
                          ? {
                              shrink: true,
                            }
                          : {}
                      }
                      error={text.error}
                      helperText={text.helperText}
                      inputProps={
                        text.type === "number" ? { inputMode: "numeric" } : {}
                      }
                    />
                  )}
                </Grid>
              ))}
          <Grid size={12}>
            <TextField
              fullWidth
              id="note"
              label="Notes"
              value={fields.note}
              onChange={handleFieldChange}
            />
          </Grid>
        </Grid>
      </div>
    );

    const onClosing = () => {
      dispatch(toggleAddEntryDrawer());
      setIsLoading(false);
      setShowError(false);
    };

    const isPhoneScreen = usePhoneScreenMedia();

    const isOpen = useSelector(selectorAddEntryDrawer);

    const reset = () => {
      handleFieldChange(
        {
          target: {
            id: "symbol",
            value: "",
          },
        },
        "symbol"
      );
      handleFieldChange(
        {
          target: {
            id: "size",
            value: 1,
          },
        },
        "size"
      );
      handleFieldChange(
        {
          target: {
            id: "entryDate",
            value: getNowDateString(),
          },
        },
        "entryDate"
      );
      handleFieldChange(
        {
          target: {
            id: "price",
            value: 0,
          },
        },
        "price"
      );
      setTotalPrice(0);
      handleFieldChange(
        {
          target: {
            id: "expDate",
            value: { label: "", value: "" },
          },
        },
        "expDate"
      );
      handleFieldChange(
        {
          target: {
            id: "expDateInput",
            value: "",
          },
        },
        "expDateInput"
      );
      handleFieldChange(
        {
          target: {
            id: "note",
            value: "",
          },
        },
        "note"
      );
      handleFieldChange(
        {
          target: {
            id: "strikes",
            value: "",
          },
        },
        "strikes"
      );
      handleFieldChange(
        {
          target: {
            id: "type1",
            value: { value: "sto", label: "Sell to Open" },
          },
        },
        "type1"
      );
      handleFieldChange(
        {
          target: {
            id: "strike1",
            value: 0,
          },
        },
        "strike1"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate1",
            value: { label: "", value: "" },
          },
        },
        "expDate1"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate1Input",
            value: "",
          },
        },
        "expDate1Input"
      );
      handleFieldChange(
        {
          target: {
            id: "qty1",
            value: 0,
          },
        },
        "qty1"
      );
      handleFieldChange(
        {
          target: {
            id: "putCall1",
            value: { value: "put", label: "Put" },
          },
        },
        "putCall1"
      );
      handleFieldChange(
        {
          target: {
            id: "type2",
            value: { value: "sto", label: "Sell to Open" },
          },
        },
        "type2"
      );
      handleFieldChange(
        {
          target: {
            id: "strike2",
            value: 0,
          },
        },
        "strike2"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate2",
            value: { label: "", value: "" },
          },
        },
        "expDate2"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate2Input",
            value: "",
          },
        },
        "expDate2Input"
      );
      handleFieldChange(
        {
          target: {
            id: "qty2",
            value: 0,
          },
        },
        "qty2"
      );
      handleFieldChange(
        {
          target: {
            id: "putCall2",
            value: { value: "put", label: "Put" },
          },
        },
        "putCall2"
      );
      handleFieldChange(
        {
          target: {
            id: "type3",
            value: { value: "sto", label: "Sell to Open" },
          },
        },
        "type3"
      );
      handleFieldChange(
        {
          target: {
            id: "strike3",
            value: 0,
          },
        },
        "strike3"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate3",
            value: { label: "", value: "" },
          },
        },
        "expDate3"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate3Input",
            value: "",
          },
        },
        "expDate3Input"
      );
      handleFieldChange(
        {
          target: {
            id: "qty3",
            value: 0,
          },
        },
        "qty3"
      );
      handleFieldChange(
        {
          target: {
            id: "putCall3",
            value: { value: "put", label: "Put" },
          },
        },
        "putCall3"
      );
      handleFieldChange(
        {
          target: {
            id: "type4",
            value: { value: "sto", label: "Sell to Open" },
          },
        },
        "type4"
      );
      handleFieldChange(
        {
          target: {
            id: "strike4",
            value: 0,
          },
        },
        "strike4"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate4",
            value: { label: "", value: "" },
          },
        },
        "expDate4"
      );
      handleFieldChange(
        {
          target: {
            id: "expDate4Input",
            value: "",
          },
        },
        "expDate4Input"
      );
      handleFieldChange(
        {
          target: {
            id: "qty4",
            value: 0,
          },
        },
        "qty4"
      );
      handleFieldChange(
        {
          target: {
            id: "putCall4",
            value: { value: "put", label: "Put" },
          },
        },
        "putCall4"
      );
      dispatch(clearExpDate());
      setShowPL(false);
    };

    return isDialog ? (
      <Dialog
        open={isOpen}
        onClose={onClosing}
        fullWidth
        maxWidth="md"
        fullScreen={isPhoneScreen}
        TransitionProps={{
          onExited: reset,
        }}
      >
        <form
          className="AddForm"
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <DialogTitle id="add-entry-dialog-title">Add Entry</DialogTitle>

          <DialogContent style={{ overflow: "hidden" }}>
            <br />
            {list("right")}
            {showPL ? (
              <Grid
                size={12}
                container
                justifyContent="flex-end"
                style={{ height: "250px" }}
              >
                <PLChart isPreview={true} />
              </Grid>
            ) : null}
          </DialogContent>

          <DialogActions>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <Button onClick={previewPL} className={classes.button}>
                  Preview P/L
                </Button>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <Box>
                    Post activity to news feed?
                    <Checkbox
                      value={postToFeed}
                      defaultChecked
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={(event, checked) => setPostToFeed(checked)}
                    />
                  </Box>
                  <LoaderButton
                    type="submit"
                    // bsSize="large"
                    isLoading={isLoading}
                    color="primary"
                    // disabled={!validateform.all()}
                  >
                    Add Entry
                  </LoaderButton>
                  <Button color="secondary" onClick={onClosing}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    ) : (
      list("right")
    );
  }
);

export default AddEntry;
