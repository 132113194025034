import { createAction } from "@reduxjs/toolkit";
import { AccountStoreState, GetAccountResponse } from "./Account.types";

export const resetAccount = createAction("account/reset");
export const getAccount = createAction("journalEntry/getAccount");

export const getAccountResolved = createAction<AccountStoreState>(
  "journalEntry/getAccountResolved"
);

export const getAccountRejected = createAction(
  "journalEntry/getAccountRejected"
);

export const addAccount = createAction<{ description: string }>(
  "journalEntry/addAccount"
);

export const addAccountResolved = createAction<GetAccountResponse>(
  "journalEntry/addAccountResolved"
);

export const addAccountRejected = createAction(
  "journalEntry/addAccountRejected"
);

export const deleteAccount = createAction<string>("journalEntry/deleteAccount");

export const deleteAccountResolved = createAction<string>(
  "journalEntry/deleteAccountResolved"
);

export const deleteAccountRejected = createAction(
  "journalEntry/deleteAccountRejected"
);

export const editAccount = createAction<GetAccountResponse>(
  "journalEntry/editAccount"
);

export const editAccountResolved = createAction<GetAccountResponse>(
  "journalEntry/editAccountResolved"
);

export const editAccountRejected = createAction(
  "journalEntry/editAccountRejected"
);
