import moment from "moment";
import { StoreState } from "redux/store.types";
import { createSelector } from "reselect";

const storeState = (state: StoreState) => state;

export const selectorStockStore = createSelector(
  storeState,
  (state) => state.stock
);

export const selectorStockData = createSelector(
  selectorStockStore,
  (stockStore) =>
    stockStore.data && stockStore.data.length > 0
      ? stockStore.data
      : [
          {
            date: moment().format("YYYY-MM-DD"),
            open: 0,
            high: 0,
            close: 0,
            low: 0,
            volume: 0,
          },
        ]
);

export const selectorStockDataReady = createSelector(
  selectorStockStore,
  (stockStore) => stockStore.isReady
);

export const selectorStockQuote = createSelector(
  selectorStockStore,
  (stockStore) => stockStore.quote
);

export const selectorStockQuoteReady = createSelector(
  selectorStockStore,
  (stockStore) => stockStore.quoteReady
);

export const selectorStockExpDateReady = createSelector(
  selectorStockStore,
  (stockStore) => stockStore.expirationsReady
);

export const selectorStockExpDate = createSelector(
  selectorStockStore,
  (stockStore) =>
    stockStore.expirations && stockStore.expirations.length > 0
      ? stockStore.expirations
      : [""]
);
