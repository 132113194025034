import { createAction } from "@reduxjs/toolkit";
import {
  Activity,
  ActivityDeleteBody,
  ActivityEditPriceBody,
  AddEntryPostBody,
  ClosePostBody,
  DeleteBody,
  JournalEntry,
  RollPostBody,
} from "journal-lib";
import {
  GetJournalEntriesParams,
  GetJournalEntriesResponse,
} from "./JournalEntries.types";

export const resetJournalEntries = createAction("journalEntry/reset");

export const analysisEntryById = createAction<string>(
  "journalEntry/analysisEntryById"
);
export const getEntries = createAction<GetJournalEntriesParams>(
  "journalEntry/getEntries"
);

export const getEntriesResolved = createAction<GetJournalEntriesResponse>(
  "journalEntry/getEntriesResolved"
);

export const refreshEntries = createAction("journalEntry/refreshEntries");

export const refreshEntriesResolved = createAction(
  "journalEntry/refreshEntriesResolved"
);

export const addEntry = createAction<AddEntryPostBody>("journalEntry/addEntry");

export const addEntryResolved = createAction<JournalEntry>(
  "journalEntry/addEntryResolved"
);

export const addEntryRejected = createAction("journalEntry/addEntryRejected");

export const deleteEntry = createAction<DeleteBody>("journalEntry/deleteEntry");

export const deleteEntryResolved = createAction<DeleteBody>(
  "journalEntry/deleteEntryResolved"
);

export const deleteEntryRejected = createAction(
  "journalEntry/deleteEntryRejected"
);

export const editEntry = createAction<Activity>("journalEntry/editEntry");

export const editEntryResolved = createAction("journalEntry/editEntryResolved");

export const editEntryRejected = createAction("journalEntry/editEntryRejected");

export const rollEntry = createAction<RollPostBody>("journalEntry/rollEntry");

export const rollEntryResolved = createAction("journalEntry/rollEntryResolved");

export const rollEntryRejected = createAction("journalEntry/rollEntryRejected");

export const closeEntry = createAction<ClosePostBody>(
  "journalEntry/closeEntry"
);

export const closeEntryResolved = createAction<ClosePostBody>(
  "journalEntry/closeEntryResolved"
);

export const closeEntryRejected = createAction(
  "journalEntry/closeEntryRejected"
);

export const deleteActivity = createAction<ActivityDeleteBody>(
  "journalEntry/deleteActivity"
);

export const deleteActivityResolved = createAction(
  "journalEntry/deleteActivityResolved"
);

export const deleteActivityRejected = createAction(
  "journalEntry/deleteActivityRejected"
);

export const editActivity = createAction<ActivityEditPriceBody>(
  "journalEntry/editActivity"
);

export const editActivityResolved = createAction(
  "journalEntry/editActivityResolved"
);

export const editActivityRejected = createAction(
  "journalEntry/editActivityRejected"
);

export const updateJournalEntry = createAction<JournalEntry>(
  "journalEntry/updateJournalEntry"
);

export const getEarning = createAction("journalEntry/getEarning");

export const getEarningResolved = createAction<{ [symbol: string]: string }[]>(
  "journalEntry/getEarningResolved"
);

export const getEarningRejected = createAction(
  "journalEntry/getEarningRejected"
);

export const editJournalEntry = createAction<{ [key: string]: any }>(
  "journalEntry/editJournalEntry"
);

export const editJournalEntryResolved = createAction<{ [key: string]: any }>(
  "journalEntry/editJournalEntryResolved"
);

export const editJournalEntryRejected = createAction(
  "journalEntry/editJournalEntryRejected"
);
