import { API } from "aws-amplify";
import { onError } from "libs/errorLib";
import moment from "moment";
import { AnyAction } from "redux";
import { put, takeLatest } from "redux-saga/effects";
import {
  getYearly,
  getYearlyRejected,
  getYearlyResolved,
} from "./Yearly.actions";
import { GetYearlyResponse } from "./Yearly.types";

function* getYearlySaga(action: AnyAction) {
  try {
    const responses: GetYearlyResponse[] = yield API.get(
      "optionOrder",
      `/journalEntry/yearly${
        action.payload === "all" ? "" : `?accountId=${action.payload}`
      }`,
      {}
    );

    const monthlyArray = responses.map((response) => ({
      date: moment()
        .set({
          year: response._id.year,
          month: 1,
          date: 0,
        })
        .toDate(),
      income: response.gain,
      expiring: response.maxProfit,
      comissions: response.commission,
      net: response.net,
      fee: 0,
      dividends: 0,
    }));

    yield put(getYearlyResolved(monthlyArray));
  } catch (e) {
    onError(e);
    yield put(getYearlyRejected());
  }
}

function* watchGetYearly() {
  yield takeLatest(getYearly, getYearlySaga);
}

const sagaArray = [watchGetYearly()];
export default sagaArray;
