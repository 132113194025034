import { AnyAction, createReducer } from "@reduxjs/toolkit";
import { AnalysisStoreState } from "./Analysis.types";
import {
  setEntries,
  setRate,
  addTResolved,
  setTResolved,
  deleteTResolved,
  setOptionChainsResolved,
  toggleEnableModeling,
  setVolatilityAdjustment,
  setIncludeExpiration,
  setModelQty,
  updateModelQty,
  setVolatilityPointPercent,
  setPositionModeling,
  resetAnalysis,
} from "./Analysis.actions";
import { DateTime } from "luxon";

export const initialState: AnalysisStoreState = {
  parameters: {
    t: [],
    includeExpiration: true,
  },
  entries: [],
  rate: 0,
  rateSetDate: DateTime.local(1900, 1, 1, 1, 1),
  optionChains: {},
  modeling: {
    volatilityModeling: false,
    volatilityAdjustment: 0,
    volatilityPointPercent: "Percent",
    modelQty: {},
    positionModeling: false,
  },
};

interface AnalysisReducer {
  [x: string]: (
    state: AnalysisStoreState,
    action: AnyAction
  ) => AnalysisStoreState | undefined;
}

const reducer: AnalysisReducer = {
  [resetAnalysis.toString()]: () => {
    return initialState;
  },
  [setTResolved.toString()]: (state, action) => {
    return {
      ...state,
      parameters: {
        ...state.parameters,
        t: action.payload,
      },
    };
  },
  [addTResolved.toString()]: (state, action) => {
    let numbers: number[] = [];
    if (state.parameters?.t != undefined) numbers = [...state.parameters.t];
    const newT = addAndSortWithoutDuplicates(numbers, action.payload);
    return {
      ...state,
      parameters: {
        ...state.parameters,
        t: newT,
      },
    };
  },
  [deleteTResolved.toString()]: (state, action) => {
    // console.log("delete started");
    let numbers = [...state.parameters.t];
    let numberToDelete = action.payload;

    // Method 1: Using splice() (if you know the index)
    let index = numbers?.indexOf(numberToDelete);
    if (index > -1) {
      numbers.splice(index, 1);
    }

    // Method 2: Using filter() (if you don't know the index)
    numbers = numbers.filter((number) => number !== numberToDelete);
    // console.log("delete end", numbers);
    return {
      ...state,
      parameters: {
        ...state.parameters,
        t: [...numbers],
      },
    };
  },
  [setEntries.toString()]: (state, action) => ({
    ...state,
    entries: action.payload,
  }),
  [setRate.toString()]: (state, action) => ({
    ...state,
    rate: action.payload,
    rateSetDate: DateTime.now(),
  }),
  [setOptionChainsResolved.toString()]: (state, action) => ({
    ...state,
    optionChains: action.payload,
  }),
  [toggleEnableModeling.toString()]: (state) => ({
    ...state,
    modeling: {
      ...state.modeling,
      volatilityModeling:
        state.modeling?.volatilityModeling == undefined
          ? false
          : !state.modeling.volatilityModeling,
    },
  }),
  [setVolatilityAdjustment.toString()]: (state, action) => ({
    ...state,
    modeling: {
      ...state.modeling,
      volatilityAdjustment: action.payload,
    },
  }),
  [setVolatilityPointPercent.toString()]: (state, action) => ({
    ...state,
    modeling: {
      ...state.modeling,
      volatilityPointPercent: action.payload,
    },
  }),
  [setIncludeExpiration.toString()]: (state, action) => ({
    ...state,
    parameters: {
      ...state.parameters,
      includeExpiration: action.payload,
    },
  }),
  [setModelQty.toString()]: (state, action) => ({
    ...state,
    modeling: {
      ...state.modeling,
      modelQty: action.payload,
    },
  }),
  [updateModelQty.toString()]: (state, action: AnyAction) => {
    if (
      state.modeling != undefined &&
      state.modeling.modelQty != undefined &&
      action.payload.exp in state.modeling.modelQty
    )
      return {
        ...state,
        modeling: {
          ...state.modeling,
          modelQty: {
            ...state.modeling.modelQty,
            [action.payload.exp]: {
              ...state.modeling.modelQty[action.payload.exp],
              [action.payload.strike]: {
                ...state.modeling.modelQty[action.payload.exp][
                  action.payload.strike
                ],
                [action.payload.putcall]: action.payload.qty,
              },
            },
          },
        },
      };
    else
      return {
        ...state,
        modeling: {
          ...state.modeling,
          modelQty: {
            ...state.modeling.modelQty,
            [action.payload.exp]: {
              [action.payload.strike]: {
                [action.payload.putcall]: action.payload.qty,
              },
            },
          },
        },
      };
  },
  [setPositionModeling.toString()]: (state, action) => ({
    ...state,
    modeling: {
      ...state.modeling,
      positionModeling: action.payload,
    },
  }),
};

export default createReducer(initialState, reducer);

const addAndSortWithoutDuplicates = (arr: number[], num: number) => {
  if (arr == undefined) arr = [];
  // Add the number to the array
  arr.push(num);

  // Remove duplicates using a Set
  const uniqueSet = new Set(arr);
  const uniqueArr = Array.from(uniqueSet);

  // Sort the array in ascending order
  uniqueArr.sort((a, b) => a - b);

  return uniqueArr;
};
