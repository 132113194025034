import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { selectJournalEntries } from "selectors";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  getEarning,
  getEntries,
  selectorEarningDates,
  selectorJournalEntries,
  selectorJournalEntriesStore,
} from "redux/JournalEntries";
import { getStrategyTemplate, StrategyEnum } from "journal-lib";
import {
  Badge,
  Button,
  Hidden,
  IconButton,
  lighten,
  LinearProgress,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { selectorAccountStore } from "redux/Account/Account.selectors";
import {
  Add,
  DeleteOutline,
  ReceiptOutlined,
  Refresh,
} from "@mui/icons-material";
import {
  setJournalTableVisibilityModel,
  setPage,
  setPageSize,
  setSelectionModel,
  setSortModel,
  toggleAddEntryFromOrdersDrawer,
  toggleChangeCustomStrateyDialog,
  toggleDeleteDrawer,
  toggleDetailsDrawer,
} from "redux/UI";
import {
  selectorJournalTableColumnHidden,
  selectorJournalTableColumnShown,
  selectorJournalTableVisibilityModel,
  selectorPage,
  selectorPageSize,
  selectorSelectionModel,
  selectorSortModel,
} from "redux/UI/UI.selectors";
import {
  grid2Decimal,
  gridDateFormatter,
  gridInfinity,
  gridInteger,
  gridPercentFormatter,
} from "utils";
import { FilterEntries } from "components/FilterEntries";
import clsx from "clsx";
import moment from "moment";
import {
  selectorFilterByAccount,
  selectorFilterByDate,
  selectorFilterByStatus,
  selectorFilterBySymbols,
} from "redux/UI";
import useInterval from "utils/hooks/interval";
import useBrowserActive from "utils/hooks/browserActive";
import { DateTime } from "luxon";

const useStyles = makeStyles(() => {
  const getBackgroundColor = (color: any) => lighten(color, 0.4);

  const getHoverBackgroundColor = (color: any) => lighten(color, 0.3);

  return {
    root: {
      "& .MuiDataGrid-columnHeaderSortable .MuiDataGrid-iconButtonContainer": {
        display: "none",
      },

      "& .MuiDataGrid-columnHeaderSortable.MuiDataGrid-columnHeaderSorted .MuiDataGrid-iconButtonContainer":
        {
          display: "inherit",
        },
      "& .MuiDataGrid-selectedRowCount": {
        color: "#fff",
      },
      "&>div>div:nth-child(1)": {
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "white",
      },
      "& .MuiDataGrid-columnsContainer": {
        position: "sticky",
        top: "40px",
        zIndex: 2,
      },
      "& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04);",
        },
      },
      "& div": {
        fontSize: "12px",
      },
      "& .columnHeader": {
        height: "48px",
      },
      "& .columnHeader, & .MuiDataGrid-colCellWrapper": {
        backgroundColor: "#5B95F9",
        color: "white",
        padding: "0 5px",
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        alignItems: "center",
      },
      "& .columnHeader .MuiDataGrid-columnHeaderTitle": {
        overflow: "hidden",
        lineHeight: "12px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        fontSize: "11px",
      },
      "& .MuiDataGrid-cell": {
        padding: "0px 0px",
      },
      "& .MuiDataGrid-row.Mui-odd": {
        // backgroundColor: "#E8F0FE",
      },
      "& .profit": {
        backgroundColor: "#538C2A",
        color: "white",
      },
      "& .loss": {
        backgroundColor: "#99291F",
        color: "white",
      },
      "& .openTrade": {
        backgroundColor: "#faffcc",
        color: "#000",
      },
      "& .journal-table-row--darkGreen": {
        backgroundColor: getBackgroundColor("#ACFE70") + " !important",
        // color: "#fff",
        "&:hover": {
          backgroundColor: getHoverBackgroundColor("#ACFE70") + " !important",
        },
      },
      "& .journal-table-row--lightGreen": {
        backgroundColor: getBackgroundColor("#ECFFDF") + " !important",
        // color: "#fff",
        "&:hover": {
          backgroundColor: getHoverBackgroundColor("#ECFFDF") + " !important",
        },
      },
      "& .journal-table-row--darkRed": {
        backgroundColor: getBackgroundColor("#ffd1ce") + " !important",
        // color: "#fff",
        "&:hover": {
          backgroundColor: getHoverBackgroundColor("#ffd1ce") + " !important",
        },
      },
      "& .journal-table-row--lightRed": {
        backgroundColor: getBackgroundColor("#fcf8e6") + " !important",
        // color: "#fff",
        "&:hover": {
          backgroundColor: getHoverBackgroundColor("#fff0dc") + " !important",
        },
      },
    },
    loadingOverlayRoot: {
      zIndex: 2,
    },
  };
});

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: -10,
      top: 16,
      border: `2px solid `,
      padding: "0 4px",
    },
  })
)(Badge);

export const JournalTable = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const hiddenColumn = useSelector(selectorJournalTableColumnHidden);
  const shownColumn = useSelector(selectorJournalTableColumnShown);
  const visibilityModel = useSelector(selectorJournalTableVisibilityModel);

  const statusFilter = useSelector(selectorFilterByStatus);

  const accountFilter = useSelector(selectorFilterByAccount);

  const dateFilter = useSelector(selectorFilterByDate);

  const symbolFilter = useSelector(selectorFilterBySymbols);

  const browserActive = useBrowserActive();

  const page = useSelector(selectorPage);
  const pageSize = useSelector(selectorPageSize);
  const { totalCount } = useSelector(selectorJournalEntriesStore);

  const sortModel = useSelector(selectorSortModel);

  function CustomLoadingOverlay() {
    return (
      <GridOverlay className={classes.loadingOverlayRoot}>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  useEffect(() => {
    if (browserActive) {
      dispatch(getEntries({}));
    }
  }, [
    statusFilter,
    accountFilter,
    dateFilter,
    symbolFilter,
    browserActive,
    dispatch,
    page,
    pageSize,
    sortModel,
  ]);
  useEffect(() => {
    dispatch(getEarning());
  }, [statusFilter, accountFilter, dateFilter, symbolFilter, dispatch]);

  useInterval(
    () => {
      dispatch(getEntries({}));
    },
    browserActive
      ? 60000
      : !DateTime.utc().isWeekend &&
        DateTime.utc().hour >= 13 &&
        DateTime.utc().hour <= 21
      ? 300000
      : null
  );

  const journalEntries = useSelector(selectorJournalEntries);
  const account = useSelector(selectorAccountStore);

  const rows2 =
    journalEntries && journalEntries.length > 0
      ? journalEntries?.map((entry) => {
          if (!entry || entry.entryId?.trim()?.length === 0) return {};

          const strategyKey = entry?.position
            ?.name as unknown as keyof typeof StrategyEnum;
          return {
            ...entry,
            id: entry.entryId,
            name: entry?.position?.customName
              ? entry?.position?.customName
              : strategyKey && getStrategyTemplate(StrategyEnum[strategyKey])
              ? getStrategyTemplate(StrategyEnum[strategyKey]).name
              : entry.position.name,
            strikeDesc: entry?.position?.strikeDesc,
            // qtyDesc: entry?.position?.qtyDesc,
            position: "",
            accountDesc:
              entry.account && account[entry.account]
                ? account[entry.account].description
                : "",
            entryPrice:
              strategyKey === "LongStock" || strategyKey === "ShortStock"
                ? entry?.position?.cumPrice * 100
                : entry?.position?.cumPrice,
            marketPrice:
              entry?.marketPrice &&
              (strategyKey === "LongStock" || strategyKey === "ShortStock")
                ? entry?.marketPrice * 100
                : entry?.marketPrice,
          };
        })
      : [];

  const earningDate = useSelector(selectorEarningDates);

  const isEarning = (
    symbol: string,
    earningObject: { [symbol: string]: string }
  ) => {
    if (
      earningObject &&
      earningObject[symbol] &&
      moment(earningObject[symbol], "YYYY-M-D").diff(moment(), "days") <= 14
    ) {
      return "E";
    } else {
      return undefined;
    }
  };

  const allColumns: { [key: string]: GridColDef } = {
    symbol: {
      field: "symbol",
      headerName: "Symbol",
      width: 80,
      type: "string",
      cellClassName: "uppercase",
      renderCell: (params: GridCellParams) => (
        <StyledBadge
          badgeContent={isEarning(params.value as string, earningDate)}
          color="secondary"
        >
          {params.value}
        </StyledBadge>
      ),
    },
    name: { field: "name", headerName: "Strategy", type: "string" },
    strikeDesc: {
      field: "strikeDesc",
      headerName: "Strikes",
      type: "string",
    },
    initialDate: {
      field: "initialDate",
      headerName: "Initiate Date",
      valueFormatter: gridDateFormatter,
      width: 60,
      type: "date",
    },
    entryPrice: {
      field: "entryPrice",
      headerName: "Price",
      width: 60,
      type: "number",
      valueFormatter: grid2Decimal,
    },
    marketPrice: {
      field: "marketPrice",
      headerName: "Market Price",
      width: 60,
      type: "number",
      valueFormatter: grid2Decimal,
    },
    mostRecentExp: {
      field: "mostRecentExp",
      headerName: "Exp. Date",
      valueFormatter: gridDateFormatter,
      width: 60,
      type: "date",
    },
    maxProfit: {
      field: "maxProfit",
      headerName: "Max Profit",
      width: 60,
      type: "number",
      valueFormatter: gridInfinity,
    },
    maxLoss: {
      field: "maxLoss",
      headerName: "Max Loss",
      width: 58,
      type: "number",
      valueFormatter: gridInfinity,
    },
    buyingPowerReduction: {
      field: "buyingPowerReduction",
      headerName: "BPR",
      width: 58,
      type: "number",
      valueFormatter: gridInteger,
    },
    // { field: "qtyDesc", headerName: "QTY", width: 60 },
    percentRoi: {
      field: "percentRoi",
      headerName: "% ROI",
      valueFormatter: gridPercentFormatter,
      width: 60,
      type: "number",
    },
    gain: {
      field: "gain",
      headerName: "Gain",
      width: 60,
      type: "number",
      cellClassName: (params: GridCellParams) => {
        const value = params.value as number;
        return params.value ? clsx({ profit: value > 0, loss: value < 0 }) : "";
      },
      valueFormatter: gridInteger,
    },
    percentGain: {
      field: "percentGain",
      headerName: "% Gain",
      valueFormatter: gridPercentFormatter,
      width: 60,
      type: "number",
    },
    endDate: {
      field: "endDate",
      headerName: "Close Date",
      valueFormatter: gridDateFormatter,
      width: 60,
      type: "date",
    },
    netGain: {
      field: "netGain",
      headerName: "Net Gain",
      width: 60,
      type: "number",
      valueFormatter: gridInteger,
    },
    netRoi: {
      field: "netRoi",
      headerName: "Net ROI",
      valueFormatter: gridPercentFormatter,
      width: 60,
      type: "number",
    },
    annualRoi: {
      field: "annualRoi",
      headerName: "Annual ROI",
      valueFormatter: gridPercentFormatter,
      width: 60,
      type: "number",
    },
    status: {
      field: "status",
      headerName: "Status",
      width: 60,
      type: "string",
      cellClassName: (params: GridCellParams) =>
        params.value ? clsx({ openTrade: params.value === "Open" }) : "",
    },
    //{ field: "notes", headerName: "Notes", width: 60 },
    daysInTrade: {
      field: "daysInTrade",
      headerName: "Days In Trade",
      width: 60,
      type: "number",
    },
    commission: {
      field: "commission",
      headerName: "Commission",
      width: 50,
      type: "number",
    },
    // {
    //   field: "notionalValue",
    //   headerName: "Notional Value",
    //   width: 60,
    //   type: "number",
    // },
    percentItm: {
      field: "percentItm",
      headerName: "% ITM",
      width: 60,
      type: "number",
      valueFormatter: gridPercentFormatter,
    },
    // {
    //   field: "marketValuation",
    //   headerName: "Valuation",
    //   valueFormatter: gridPercentFormatter,
    //   width: 60,
    //   type: "number",
    // },
    // { field: "marketCap", headerName: "Market Cap", width: 60, type: "number" },
    // { field: "industry", headerName: "Industry", width: 60 },
    // { field: "profit", headerName: "Profit", width: 60, type: "number" },
    // {
    //   field: "daysSinceOpen",
    //   headerName: "Days Since Open",
    //   width: 60,
    //   type: "number",
    // },
    // {
    //   field: "specialMargin",
    //   headerName: "Special Margin",
    //   valueFormatter: gridPercentFormatter,
    //   width: 60,
    //   type: "number",
    // },
    accountDesc: { field: "accountDesc", headerName: "Account", width: 100 },
    notes: {
      field: "notes",
      headerName: "Notes",
      type: "string",
    },
  };
  const columns = useMemo(() => {
    if (shownColumn == undefined || shownColumn.length == 0)
      return Object.values(allColumns);

    return shownColumn.map((col) => allColumns[col]);
  }, [hiddenColumn, shownColumn]);

  columns.forEach((column) => {
    column.headerAlign = "center";
    column.align = "center";
    column.headerClassName = "columnHeader";
    // if (!column.renderCell) {
    //   column.renderCell = DataGridCellExpand;
    // }
  });

  const add = () => {
    dispatch(toggleAddEntryFromOrdersDrawer());
  };

  const deleteEntry = () => {
    dispatch(toggleDeleteDrawer());
  };

  const entryDetails = () => {
    dispatch(toggleDetailsDrawer());
  };

  const refresh = () => {
    // dispatch(refreshEntries());
    dispatch(getEntries({}));
  };

  const selectModel = useSelector(selectorSelectionModel);
  const rowDoubleClick = () => {
    if (selectModel && selectModel?.length > 0) {
      dispatch(toggleDetailsDrawer());
    }
  };

  const getRowStatus = (gain: string, percentItm: string) => {
    if (+gain > 0.4) {
      return "darkGreen";
    } else if (+gain > 0.1) {
      return "lightGreen";
    } else if (+percentItm < 0) {
      return "darkRed";
    } else if (+percentItm < 0.03) {
      return "lightRed";
    } else {
      return "normal";
    }
  };

  const customToolBar = () => (
    <GridToolbarContainer>
      <Hidden smUp>
        <IconButton aria-label="refresh" onClick={refresh} size="large">
          <Refresh />
        </IconButton>
        <FilterEntries />
        <IconButton aria-label="add" onClick={add} size="large">
          <Add />
        </IconButton>
        {/* <IconButton aria-label="close" onClick={close} disabled={!canCloseRoll}>
          <Close />
        </IconButton>
        <IconButton aria-label="roll" onClick={roll} disabled={!canCloseRoll}>
          <Loop />
        </IconButton> */}
        <IconButton aria-label="deleteEntry" onClick={deleteEntry} size="large">
          <DeleteOutline />
        </IconButton>
        <IconButton
          aria-label="entryDetails"
          onClick={entryDetails}
          size="large"
        >
          <ReceiptOutlined />
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <Button startIcon={<Refresh />} onClick={refresh}>
          REFRESH
        </Button>
        <FilterEntries />
        <Button startIcon={<Add />} onClick={add}>
          ADD
        </Button>
        {/* <Button startIcon={<Close />} onClick={close} disabled={!canCloseRoll}>
          CLOSE
        </Button>
        <Button startIcon={<Loop />} onClick={roll} disabled={!canCloseRoll}>
          ROLL
      </Button> */}
        <Button startIcon={<DeleteOutline />} onClick={deleteEntry}>
          DELETE
        </Button>
        <Button startIcon={<ReceiptOutlined />} onClick={entryDetails}>
          DETAILS
        </Button>
        <GridToolbarColumnsButton />
        {/* <Button startIcon={<ReceiptOutlined />} onClick={() => {}}>
          DETAILS
        </Button> */}
      </Hidden>
    </GridToolbarContainer>
  );

  // useQuery(gql`
  //   query GetMe {
  //     getMe {
  //       userName
  //       name
  //       email
  //     }
  //   }
  // `);
  return (
    <div style={{ height: "100%", width: "100%" }} className={classes.root}>
      {/* {rows2 && rows2.length > 0 ? ( */}
      <DataGrid
        disableColumnMenu
        autoHeight
        rows={rows2}
        columns={columns}
        density="compact"
        columnHeaderHeight={70}
        // disableColumnMenu={disableMenu}
        // onColumnHeaderEnter={() => setDisableMenu(false)}
        // onColumnHeaderLeave={() => setDisableMenu(true)}
        slots={{
          toolbar: customToolBar,
          loadingOverlay: CustomLoadingOverlay,
          // Pagination: customPagination,
        }}
        rowSelectionModel={selectModel}
        onRowSelectionModelChange={(newSelection) => {
          dispatch(setSelectionModel([...newSelection]));
        }}
        // onRowDoubleClick={rowDoubleClick}
        onCellDoubleClick={(params) => {
          if (params?.colDef.field === "name") {
            dispatch(toggleChangeCustomStrateyDialog());
          } else {
            rowDoubleClick();
          }
        }}
        // filterModel={useSelector(selectorFilterMode)}
        // onFilterModelChange={(params) => console.log(params)}
        sortModel={sortModel}
        onSortModelChange={(sortModel: GridSortModel) => {
          dispatch(setSortModel(sortModel));
        }}
        getRowClassName={(params) => {
          return params.row.status === "Open"
            ? `journal-table-row--${getRowStatus(
                params.row.percentGain,
                params.row.percentItm
              )}`
            : "";
        }}
        paginationModel={{
          page: page,
          pageSize: pageSize,
        }}
        onPaginationModelChange={(model) => {
          dispatch(setPage(model.page));
          dispatch(setPageSize(model.pageSize));
        }}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        rowCount={totalCount}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={(model) => {
          dispatch(setJournalTableVisibilityModel(model));
        }}
        autosizeOnMount={true}
        autosizeOptions={{
          columns: ["name", "strikeDesc", "notes"],
          includeOutliers: true,
          includeHeaders: true,
        }}
      />
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

export default JournalTable;
