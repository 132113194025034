import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import config from "./config";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { initSentry } from "./libs/errorLib";
import { Amplify } from "aws-amplify";
import Box from "@mui/material/Box";
initSentry();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "journalEntry",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "optionOrder",
        endpoint: config.apiGatewayOptionOrders.URL,
        region: config.apiGatewayOptionOrders.REGION,
      },
    ],
    GraphQL: {
      endpoint:
        "https://myozhew2zzhu5ckduzicaopl4u.appsync-api.us-west-2.amazonaws.com/graphql",
      region: "us-east-2",
      // Set the default auth mode to "apiKey" and provide the API key value
      defaultAuthMode: "userPool",
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <Box
    style={{
      paddingBottom: "12px",
      // paddingLeft: "env(safe-area-inset-left)",
      // paddingRight: "env(safe-area-inset-right)",
    }}
  >
    <Provider store={store}>
      <Router>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Router>
    </Provider>
  </Box>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
