import { useState, useEffect } from "react";

function useBrowserActive() {
  const [isFocus, setIsFOcus] = useState(true);

  useEffect(() => {
    function handleFocus() {
      setIsFOcus(true);
      //   console.log("window focus");
    }
    function handleLossFocus() {
      setIsFOcus(false);
      //   console.log("window loss focus");
    }

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleLossFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleFocus);
    };
  }, []);

  return isFocus;
}

export default useBrowserActive;
