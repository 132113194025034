import { StoreState } from "redux/store.types";
import { createSelector } from "reselect";

const storeState = (state: StoreState) => state;

export const selectFeedStore = createSelector(
  storeState,
  (state) => state?.feed
);

export const selectorFeedSymbol = createSelector(
  selectFeedStore,
  (state) => state?.symbol
);

export const selectorFeeds = createSelector(
  selectFeedStore,
  (state) => state?.feeds
);

export const selectorNumberOfFeeds = createSelector(selectorFeeds, (feeds) =>
  feeds?.length > 0 ? feeds.length : 0
);

export const selectorLatestFeed = createSelector(selectorFeeds, (feeds) => {
  if (feeds && feeds.length > 0) {
    return feeds[0];
  }
});

export const selectorNewFeed = createSelector(
  selectFeedStore,
  (feedStore) => feedStore.newFeed
);

export const selectorIsFeedLoading = createSelector(
  selectFeedStore,
  (feedStore) => feedStore.isLoading
);
