import { useRef, useEffect, RefObject } from "react";

function useEventListener<T extends HTMLElement = HTMLDivElement>(
  eventName: string,
  handler: (event: Event) => void,
  element?: RefObject<T>
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    targetElement.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, handler]);
}

export default useEventListener;

// import React, { useRef } from 'react'

// import useEventListener from './useEventListener'

// export default function Component() {
//   // Define button ref
//   const buttonRef = useRef<HTMLButtonElement>(null)

//   const onScroll = (event: Event) => {
//     console.log('window scrolled!', event)
//   }

//   const onClick = (event: Event) => {
//     console.log('button clicked!', event)
//   }

//   // example with window based event
//   useEventListener('scroll', onScroll)

//   // example with element based event
//   useEventListener('click', onClick, buttonRef)

//   return (
//     <div style={{ minHeight: '200vh' }}>
//       <button ref={buttonRef}>Click me</button>
//     </div>
//   )
// }
