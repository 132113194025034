import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCloseDrawer,
  toggleDetailsDrawer,
  toggleDetailsStockChart,
  toggleRollDrawer,
  togglePLChart,
  toggleAddLegsFromOrderDrawer,
} from "redux/UI";
import { selectorDetailsDrawer, selectorUIStore } from "redux/UI/UI.selectors";
import {
  editJournalEntry,
  selectorCanCloseRoll,
  selectorEarningDates,
  SelectSelectedEntry,
} from "redux/JournalEntries";
import {
  editActivity as editActivityAction,
  deleteActivity as deleteActivityAction,
} from "redux/JournalEntries";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";

import {
  Typography,
  Paper,
  Grid2 as Grid,
  Fab,
  Menu,
  MenuItem,
  TextField,
  DialogContentText,
  IconButton,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Add,
  Close,
  Loop,
  MoreVert,
  FiberManualRecord,
  PanoramaFishEye,
  MoreHoriz,
} from "@mui/icons-material";
import clsx from "clsx";
import {
  currency2DigitFormatter,
  currencyFormatter,
  getGridSize,
  getMonthDateString,
  usePhoneScreenMedia,
  useXsDownMedia,
} from "utils";
import { ActivityDetail } from "components/ActivityDetail";
import { StrategyEnum } from "journal-lib";
import FormDialog from "components/FormDialog/FormDialog.component";
import { useFormFields } from "libs/hooksLib";
import { selectorAccountStore } from "redux/Account/Account.selectors";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { PLChart } from "components/PLChart/PLChart.components";
import { getTradingViewSymbol } from "utils/tradingView";
import ChangeEntryNotes from "components/ChangeEntryNotes/ChangeEntryNotes";
import AdvancedChart from "components/TradingView/AdvancedChart/AdvancedChart";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTimelineOppositeContent-root": {
      flex: "0.11",
    },
  },
  currentPaper: {
    backgroundColor: "#f4fff4",
    color: "black",
    fontSize: "16px",
    fontWeight: 500,
    padding: "15px 16px",
  },
  paper: {
    padding: "15px 16px",
    "& button": {
      display: "none",
    },
    "&:hover button": {
      display: "inline-flex",
      position: "absolute",
      top: "-2px",
      right: "3px",
      width: "25px",
      height: "25px",
      minHeight: "20px",
    },
  },
  legGrid: {
    paddingBottom: "3px",
  },
  debit: {
    color: "red",
  },
  credit: {
    color: "green",
  },
  hidden: {
    display: "none",
  },
  noPadding: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
}));

export const EntryDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentPos = useRef<HTMLElement>(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [needDelete, setNeedDelete] = React.useState(false);
  const [selectActivity, setSelectActivity] = React.useState(0);
  const [openAccountDialog, setOpenAccountDialog] = React.useState(false);

  const [editPrice, seteditPrice] = React.useState(0);
  const [open_edit, setOpen_edit] = React.useState(false);

  const [editNoteDialog, setEditNoteDialog] = React.useState(false);

  // const account = useSelector(selectorAccountStore);

  const isXs = useXsDownMedia();
  const handleClickOpen_edit = () => {
    seteditPrice(entry.position.activities[selectActivity].price ?? 0);
    setOpen_edit(true);
  };

  const handleClose_edit = () => {
    setOpen_edit(false);
  };

  const submitEditPrice = (e: any) => {
    e.preventDefault();
    if (entry && entry.entryId) {
      dispatch(
        editActivityAction({
          entryId: entry.entryId,
          activityId: String(entry.position.activities[selectActivity].id),
          price: editPrice,
        })
      );
    }
    handleClose_edit();
  };

  const [open_delete, setOpen_delete] = React.useState(false);

  const handleClickOpen_delete = () => {
    setOpen_delete(true);
  };

  const handleClose_delete = () => {
    setOpen_delete(false);
  };

  const submitDelete_delete = () => {
    if (entry && entry.entryId) {
      dispatch(
        deleteActivityAction({
          entryId: entry.entryId,
          activityId: String(entry.position.activities[selectActivity].id),
        })
      );
    }
    handleClose_delete();
  };

  const handleClick_menu = (
    event: any,
    needDelete: boolean,
    activityIndex: number
  ) => {
    setSelectActivity(activityIndex);
    setNeedDelete(needDelete);
    setAnchorEl(event.currentTarget);
  };

  const handleClose_menu = () => {
    setAnchorEl(null);
  };

  const handleClick_menu2 = (event: any) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose_menu2 = () => {
    setAnchorE2(null);
  };

  const entered = () => {
    if (currentPos && currentPos.current && currentPos.current != null) {
      currentPos.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClose = () => {
    dispatch(toggleDetailsDrawer());
    // dispatch(setDetailsStockChart(false));
    // setIsLoading(false);
  };

  const entry = SelectSelectedEntry();

  // useEffect(() => {
  //   dispatch(getStockQuotes([entry.symbol]));
  // }, [entry]);

  // const entryQuote = useSelector(selectorStockQuote);

  const toggleStockChart = () => {
    dispatch(toggleDetailsStockChart());
    handleClose_menu2();
  };
  const hangleTogglePLChart = () => {
    dispatch(togglePLChart());
    handleClose_menu2();
  };

  const [fields_, handleFieldChange_] = useFormFields({
    account: { value: "", label: "" },
  });

  const tradeAnalysis = () => {
    // dispatch(setEntries([entry]));
    window.open(`/analysis/${entry.entryId}`, "analysis");
  };
  const editNotes = () => {
    setEditNoteDialog(true);
  };
  const switchAccount = () => {
    setOpenAccountDialog(true);
    handleClose_menu2();
  };
  const accounts = useSelector(selectorAccountStore);
  const accountArray: { value: string; label: string }[] = Object.keys(
    accounts
  ).map((accountId) => {
    return {
      value: accountId,
      label: accounts[accountId].description,
    };
  });
  const switchAccountSubmitted = (event: any) => {
    event.preventDefault();

    dispatch(
      editJournalEntry({
        entryId: entry.entryId,
        account: fields_.account.value,
      })
    );
    handleClose_menu2();
  };

  const { detailsStockChart, PLChart: ShowPLChart } =
    useSelector(selectorUIStore);
  // const isDetailsStockChartReady = useSelector(selectorStockDataReady);

  const deleteActivity = () => {
    handleClickOpen_delete();
    handleClose_menu();
  };

  const editActivity = () => {
    handleClickOpen_edit();
    handleClose_menu();
  };

  // const DeleteClicker = () => {
  //   setIsLoading(true);

  //   if (entry && entry.entryId)
  //     dispatch(deleteEntry({ entryIds: [entry.entryId] }));
  //   setIsLoading(false);
  // };

  const isOpen = useSelector(selectorDetailsDrawer);
  let runningTotal: number = 0;

  const isPhoneScreen = usePhoneScreenMedia();

  const close = () => {
    dispatch(toggleCloseDrawer());
  };

  const roll = () => {
    dispatch(toggleRollDrawer(true));
  };

  const add = () => {
    // dispatch(toggleAddLegsDrawer(true));
    dispatch(toggleAddLegsFromOrderDrawer(true));
  };

  const canCloseRoll = useSelector(selectorCanCloseRoll);

  const earningDates = useSelector(selectorEarningDates);

  const ref = useRef(null);

  const stockInfoGrid = (
    <>
      <Grid size={2} direction="column" container alignItems="flex-start">
        <Grid>
          <Typography variant="body2" style={{ color: "gray" }}>
            Last
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body2" style={{ color: "gray" }}>{`${
            entry
              ? currency2DigitFormatter.format(entry.symbolMarketPrice ?? 0)
              : ""
          }`}</Typography>
        </Grid>
      </Grid>
      {/* <Grid item xs={2} direction="column" container alignItems="flex-start">
        <Grid item>
          <Typography variant="body2" style={{ color: "gray" }}>
            Change
          </Typography>
        </Grid>
        <Grid item>
          {isStockQuoteReady ? (
            <Typography
              variant="body2"
              // className={
              //   entryQuote?.change >= 0
              //     ? classes.credit
              //     : entryQuote?.change < 0
              //     ? classes.debit
              //     : ""
              // }
            >{`${
              entryQuote
                ? currency2DigitFormatter.format(entryQuote.change)
                : ""
            }`}</Typography>
          ) : (
            <Skeleton
              variant="rect"
              width={40}
              height={20}
              style={{ marginLeft: "10px" }}
            />
          )}
        </Grid>
      </Grid> */}
      <Grid size={2} direction="column" container alignItems="flex-start">
        <Grid>
          <Typography variant="body2" style={{ color: "gray" }}>
            T. Credit
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="body2"
            className={
              entry.position.cumPrice
                ? entry.position.cumPrice >= 0
                  ? classes.credit
                  : entry.position.cumPrice < 0
                  ? classes.debit
                  : ""
                : ""
            }
          >{`${
            entry.position.cumPrice
              ? Math.round(entry.position.cumPrice * 100) / 100
              : ""
          }`}</Typography>
        </Grid>
      </Grid>
      <Grid size={2} direction="column" container alignItems="flex-start">
        <Grid>
          <Typography variant="body2" style={{ color: "gray" }}>
            M. Price
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="body2"
            className={
              entry.marketPrice
                ? entry.marketPrice >= 0
                  ? classes.credit
                  : entry.marketPrice < 0
                  ? classes.debit
                  : ""
                : ""
            }
          >{`${
            entry.marketPrice ? Math.round(entry.marketPrice * 100) / 100 : ""
          }`}</Typography>
        </Grid>
      </Grid>
      <Grid size={2} direction="column" container alignItems="flex-start">
        <Grid>
          <Typography variant="body2" style={{ color: "gray" }}>
            Gain
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="body2"
            className={
              entry.gain
                ? entry.gain >= 0
                  ? classes.credit
                  : entry.gain < 0
                  ? classes.debit
                  : ""
                : ""
            }
          >{`${
            entry.gain ? currencyFormatter.format(entry.gain) : ""
          }`}</Typography>
        </Grid>
      </Grid>
      {earningDates && earningDates[entry.symbol] ? (
        <Grid size={2} direction="column" container alignItems="flex-start">
          <Grid>
            <Typography variant="body2" style={{ color: "gray" }}>
              Earning
            </Typography>
          </Grid>
          <Grid>
            {Object.keys(earningDates).indexOf(entry.symbol) >= 0 ? (
              <Typography
                variant="body2"
                style={{ color: "gray" }}
              >{`${DateTime.fromISO(earningDates[entry.symbol]).toFormat(
                "M/d/yy"
              )}`}</Typography>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </>
  );

  return (
    <div>
      <Dialog
        fullScreen={isPhoneScreen}
        open={isOpen}
        onClose={handleClose}
        fullWidth
        //detailsStockChart ? "lg" : "md"}
        maxWidth={"md"}
        TransitionProps={{
          onEntered: entered,
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: "0" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid container alignItems="center">
              <Grid size={12}>
                {!isXs ? `Entry Details - ` : ""}
                <Link
                  onClick={() => dispatch(toggleDetailsDrawer())}
                  to={`/symbol/${entry?.symbol}`}
                >{`$${entry?.symbol}`}</Link>
              </Grid>
              {!isXs ? stockInfoGrid : null}
            </Grid>
            <DialogActions>
              <Button
                startIcon={<Add />}
                onClick={add}
                // disabled={!canCloseRoll}
              >
                ADD
              </Button>
              <Button
                startIcon={<Close />}
                onClick={close}
                // disabled={!canCloseRoll}
                className={clsx({ [classes.hidden]: !canCloseRoll })}
              >
                CLOSE
              </Button>
              <Button
                startIcon={<Loop />}
                onClick={roll}
                // disabled={!canCloseRoll}
                className={clsx({ [classes.hidden]: !canCloseRoll })}
              >
                ROLL
              </Button>
              <IconButton onClick={handleClick_menu2} size="large">
                <MoreHoriz />
              </IconButton>
              <Menu
                id="details-menu"
                anchorEl={anchorE2}
                keepMounted
                open={Boolean(anchorE2)}
                onClose={handleClose_menu2}
              >
                <MenuItem onClick={toggleStockChart}>Stock Chart</MenuItem>
                <MenuItem onClick={hangleTogglePLChart}>P/L Chart</MenuItem>
                <MenuItem onClick={switchAccount}>Switch Account</MenuItem>
                <MenuItem onClick={editNotes}>Edit Note</MenuItem>
                <MenuItem onClick={tradeAnalysis}>Trade Analysis</MenuItem>
                {/* <MenuItem>
                  <Link to={`analysis/${entry.entryId}`} target="_blank">
                    Trade Analysis
                  </Link>
                </MenuItem> */}
                <FormDialog
                  OpenState={openAccountDialog}
                  SetOpenState={setOpenAccountDialog}
                  DialogTitle="Please select an account to change to."
                  ContentText={`Current account is: ${
                    accountArray.filter(
                      (account) => account.value === entry.account
                    )[0]?.label
                  }`}
                  HideButton={true}
                  onSubmit={switchAccountSubmitted}
                  FormArray={[
                    {
                      fieldProps: {
                        type: "select",
                        fullWidth: true,
                        id: "account",
                        label: "Account",
                        value: fields_.account,
                        onChange: (e, newValue) => {
                          if (newValue) {
                            handleFieldChange_(
                              { target: { id: "account", value: newValue } },
                              "account"
                            );
                          } else {
                            handleFieldChange_(
                              { target: { id: "account", value: "" } },
                              "account"
                            );
                          }
                        },
                        inputProps: {
                          style: { textTransform: "uppercase" },
                        },
                        options: accountArray?.filter(
                          (account) => account.value !== entry.account
                        ),
                        getOptionLabel: (option: any) =>
                          option?.label ? option.label : "",
                      },
                      gridSize: getGridSize(12),
                    },
                  ]}
                />
              </Menu>
            </DialogActions>
          </div>
          {isXs ? (
            <Grid container alignItems="center">
              {stockInfoGrid}
            </Grid>
          ) : null}
        </DialogTitle>
        <DialogContent className={clsx({ [classes.noPadding]: isXs })}>
          <Timeline className={classes.root}>
            {entry?.position.activities.map((activity, index) => {
              runningTotal = runningTotal + +(activity.price ?? 0);
              return (
                <TimelineItem key={activity.id}>
                  <TimelineOppositeContent
                    className={clsx({ [classes.hidden]: isXs })}
                    style={{ paddingTop: "18px" }}
                  >
                    <Typography variant="body2">
                      {DateTime.fromISO(String(activity.date)).toFormat(
                        "M/dd/yy"
                      )}
                    </Typography>
                    <Typography variant="caption">
                      {`${Math.abs(
                        Math.round(
                          DateTime.fromISO(String(activity.date)).diffNow(
                            "days"
                          ).days
                        )
                      )} days ago`}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    {activity.type === "open" ? (
                      <TimelineDot
                        style={{ backgroundColor: "lightslategray" }}
                      >
                        <FiberManualRecord />
                      </TimelineDot>
                    ) : activity.type === "closing" ? (
                      <TimelineDot style={{ backgroundColor: "indianred" }}>
                        <Close />
                      </TimelineDot>
                    ) : activity.type === "roll" ? (
                      <TimelineDot
                        style={{ backgroundColor: "cornflowerblue" }}
                      >
                        <Loop />
                      </TimelineDot>
                    ) : activity.type === "add" ? (
                      <TimelineDot
                        style={{ backgroundColor: "cornflowerblue" }}
                      >
                        <Add />
                      </TimelineDot>
                    ) : (
                      ""
                    )}
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <ActivityDetail
                      ref={index === 0 ? ref : null}
                      activity={activity}
                      menu={
                        <Fab
                          size="small"
                          color="primary"
                          aria-label="delete"
                          onClick={(e) =>
                            handleClick_menu(
                              e,
                              index === entry.position.activities.length - 1,
                              index
                            )
                          }
                        >
                          <MoreVert fontSize={"small"} />
                        </Fab>
                      }
                    />
                  </TimelineContent>
                </TimelineItem>
              );
            })}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose_menu}
            >
              <MenuItem onClick={editActivity}>Edit Price</MenuItem>
              {needDelete ? (
                <MenuItem onClick={deleteActivity}>Delete</MenuItem>
              ) : (
                <div />
              )}
            </Menu>
            <TimelineItem ref={currentPos}>
              <TimelineOppositeContent
                className={clsx({ [classes.hidden]: isXs })}
                style={{ paddingTop: "16px" }}
              >
                <Typography variant="body1">
                  <b>
                    {entry?.position?.legs?.length > 0 ? "Open Pos" : "Closed"}
                  </b>
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: "green" }}>
                  {entry?.position?.legs?.length > 0 ? (
                    <PanoramaFishEye />
                  ) : (
                    <FiberManualRecord />
                  )}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.currentPaper}>
                  <Grid container>
                    {detailsStockChart ? (
                      <Grid
                        size={12}
                        container
                        justifyContent="flex-end"
                        style={{ height: "600px" }}
                      >
                        {/* <StockChart
                          width={width - 30}
                          symbol={entry.symbol}
                          markerDate={entry.position.activities.map(
                            (activity) => ({
                              date: activity.date,
                              type: activity.type,
                            })
                          )}
                        /> */}
                        {/* <TradingViewWidget
                          symbol={getTradingViewSymbol(entry.symbol)}
                          autosize
                          timezone="America/Los_Angeles"
                          style="9"
                          withdateranges
                          range="ytd"
                          show_popup_button={true}
                          popup_width="1000"
                          popup_height="650"
                          allow_symbol_change={false}
                        /> */}
                        <AdvancedChart
                          symbol={getTradingViewSymbol(entry.symbol)}
                        />
                      </Grid>
                    ) : null}
                    {ShowPLChart ? (
                      <Grid
                        size={12}
                        container
                        justifyContent="flex-end"
                        style={{ height: "250px" }}
                      >
                        <PLChart entryId={entry.entryId ? entry.entryId : ""} />
                      </Grid>
                    ) : null}
                    <Grid size={12} container justifyContent="flex-end">
                      <Grid
                        size={{ xs: 8, sm: 10 }}
                        container
                        justifyContent="flex-start"
                        className={clsx({ [classes.hidden]: !isXs })}
                      >
                        {entry?.position?.legs?.length > 0
                          ? "Open Pos"
                          : "Closed"}
                      </Grid>
                      <Grid size={6} container justifyContent="flex-end">
                        <Typography
                          variant="body2"
                          style={{ marginTop: "2px", marginRight: "5px" }}
                        >
                          {`Total ${
                            entry?.position?.legs.length > 0
                              ? entry?.position?.cumPrice > 0
                                ? "Credits"
                                : "Debits"
                              : entry?.position?.cumPrice > 0
                              ? "Profit"
                              : "Loss"
                          }:`}
                        </Typography>
                        {/* </Grid>
                      <Grid item xs={2} sm={1} container justify="flex-end"> */}
                        <Typography
                          className={clsx({
                            [classes.debit]: +entry?.position?.cumPrice < 0,
                            [classes.credit]: +entry?.position?.cumPrice > 0,
                          })}
                        >
                          <strong>
                            {currency2DigitFormatter.format(
                              (entry?.position
                                ?.name as unknown as keyof typeof StrategyEnum) ===
                                "LongStock" ||
                                (entry?.position
                                  ?.name as unknown as keyof typeof StrategyEnum) ===
                                  "ShortStock"
                                ? +entry?.position?.cumPrice * 100
                                : +entry?.position?.cumPrice
                            )}
                          </strong>
                        </Typography>
                      </Grid>
                    </Grid>
                    {entry?.position?.legs?.map((leg) => (
                      // isXs ?
                      <Grid
                        size={12}
                        container
                        className={classes.legGrid}
                        key={leg.option.strike + leg.option.type}
                      >
                        <Grid
                          size={8}
                          container
                          justifyContent="flex-start"
                          sx={{ display: { xs: "block", sm: "none" } }}
                        >
                          {`${
                            leg.option.expDate
                              ? getMonthDateString(new Date(leg.option.expDate))
                              : ""
                          } ${
                            leg.option.strike
                          } ${leg.option.type.toUpperCase()}`}
                        </Grid>
                        <Grid
                          size={1}
                          container
                          justifyContent="flex-end"
                          sx={{ display: { xs: "block", sm: "none" } }}
                        >
                          {leg.qty}
                        </Grid>
                        {/* </Grid>
                      ) : (
                        <Grid
                          size={12}
                          container
                          className={classes.legGrid}
                          key={leg.option.strike + leg.option.type}
                        > */}
                        <Grid
                          size={1}
                          container
                          justifyContent="flex-end"
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          {Math.round(leg.qty * 100) / 100}
                        </Grid>
                        <Grid
                          size={2}
                          container
                          justifyContent="center"
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          {leg.option.expDate
                            ? `
                              ${DateTime.fromISO(leg.option.expDate).toFormat(
                                "M/d/yy     "
                              )}
                              `
                            : ""}
                        </Grid>
                        <Grid
                          size={1}
                          container
                          justifyContent="flex-start"
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          {leg.option.expDate
                            ? `  ${Math.round(
                                DateTime.fromISO(leg.option.expDate).diffNow(
                                  "days"
                                ).days
                              )}d
                              `
                            : ""}
                        </Grid>
                        <Grid
                          size={1}
                          container
                          justifyContent="center"
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          {leg.option.strike}
                        </Grid>
                        <Grid
                          size={1}
                          container
                          justifyContent="center"
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          {leg.option.type === "call"
                            ? "Call"
                            : leg.option.type === "put"
                            ? "Put"
                            : leg.option.type === "stock"
                            ? "Stock"
                            : leg.option.type === "future"
                            ? "Future"
                            : ""}
                        </Grid>
                        <Grid
                          size={1}
                          container
                          justifyContent="center"
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          {leg.option.delta
                            ? Math.round(
                                ((leg.option.delta * leg.qty) /
                                  Math.abs(leg.qty)) *
                                  100
                              ) + "Δ"
                            : ""}
                        </Grid>
                        <Grid
                          size={1}
                          container
                          justifyContent="flex-end"
                          className={
                            leg.price
                              ? leg.price >= 0
                                ? classes.credit
                                : leg.price < 0
                                ? classes.debit
                                : ""
                              : ""
                          }
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          <Tooltip title="Cost">
                            <Typography>
                              {Math.round((leg.price ?? 0) * 100) / 100}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid
                          size={1}
                          container
                          justifyContent="flex-end"
                          className={
                            leg.marketPrice
                              ? leg.marketPrice >= 0
                                ? classes.credit
                                : leg.marketPrice < 0
                                ? classes.debit
                                : ""
                              : ""
                          }
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          <Tooltip title="Market Price">
                            <Typography>
                              {Math.round((leg.marketPrice ?? 0) * 100) / 100}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid
                          size={2}
                          container
                          justifyContent="flex-end"
                          className={
                            (leg.price ?? 0) + (leg.marketPrice ?? 0)
                              ? (leg.price ?? 0) + (leg.marketPrice ?? 0) >= 0
                                ? classes.credit
                                : (leg.price ?? 0) + (leg.marketPrice ?? 0) < 0
                                ? classes.debit
                                : ""
                              : ""
                          }
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          <Tooltip title="Gain">
                            <Typography>
                              {Math.round(
                                ((leg.price ?? 0) + (leg.marketPrice ?? 0)) *
                                  100
                              ) / 100}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ))}

                    {entry?.notes && entry?.notes.trim() !== "" ? (
                      <Grid size={12}>{`Note: ${entry.notes}`}</Grid>
                    ) : null}
                  </Grid>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </DialogContent>
        <DialogActions>
          {/* <LoaderButton
            onClick={DeleteClicker}
            color="secondary"
            isLoading={isLoading}
          >
            Update
          </LoaderButton> */}
          <Button onClick={handleClose} color="primary">
            Close Dialog
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open_edit}
        onClose={handleClose_edit}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={submitEditPrice}>
          <DialogTitle id="form-dialog-title">Edit Price</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="newPrice"
              label="New Price"
              type="number"
              value={editPrice}
              onChange={(event) => {
                seteditPrice(+event.target.value);
              }}
              fullWidth
              inputMode="decimal"
              inputProps={{
                inputMode: "decimal",
                step: "0.01",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Edit Price
            </Button>
            <Button onClick={handleClose_edit} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={open_delete}
        onClose={handleClose_delete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitDelete_delete} color="primary" autoFocus>
            Delete
          </Button>
          <Button onClick={handleClose_delete} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ChangeEntryNotes
        isOpen={editNoteDialog}
        onClose={() => {
          setEditNoteDialog(false);
        }}
      />
    </div>
  );
};
