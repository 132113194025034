import { createAction } from "@reduxjs/toolkit";
import { PLStat } from "../Stats.types";

export const getYearly = createAction<string>("stats/getYearly");

export const getYearlyResolved = createAction<PLStat[]>(
  "feed/getYearlyResolved"
);

export const getYearlyRejected = createAction("feed/getYearlyRejected");
