import { API } from "aws-amplify";
import { onError } from "libs/errorLib";
import moment from "moment";
import { AnyAction } from "redux";
import { put, takeLatest } from "redux-saga/effects";
import {
  getMonthly,
  getMonthlyRejected,
  getMonthlyResolved,
} from "./Monthly.actions";
import { GetMonthlyResponse } from "./Monthly.types";

function* getMonthlySaga(action: AnyAction) {
  try {
    const responses: GetMonthlyResponse[] = yield API.get(
      "optionOrder",
      `/journalEntry/monthly${
        action.payload === "all" ? "" : `?accountId=${action.payload}`
      }`,
      {}
    );

    const monthlyArray = responses.map((response) => ({
      date: moment()
        .set({
          year: response._id.year,
          month: response._id.month,
          date: 0,
        })
        .toDate(),
      income: response.gain,
      expiring: response.maxProfit,
      comissions: response.commission,
      net: response.net,
      fee: 0,
      dividends: 0,
    }));

    yield put(getMonthlyResolved(monthlyArray));
  } catch (e) {
    onError(e);
    yield put(getMonthlyRejected());
  }
}

function* watchGetMonthly() {
  yield takeLatest(getMonthly, getMonthlySaga);
}

const sagaArray = [watchGetMonthly()];
export default sagaArray;
