import { AnyAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { Activity, Feed, JournalEntry, User } from "journal-lib";
import { onError } from "libs/errorLib";
import { put, select, takeLatest } from "redux-saga/effects";
import { selectorUser } from "redux/User";
import {
  getFeedResolved,
  getFeedRejected,
  getFeed,
  postFeed,
  postFeedRejected,
  postFeedResolved,
  deleteFeed,
  deleteFeedRejected,
  deleteFeedResolved,
  getReplies,
  getRepliesRejected,
  likeFeed,
  likeFeedRejected,
  likeFeedResolved,
  replyFeed,
  replyFeedRejected,
  replyFeedResolved,
  checkNewFeed,
} from ".";
import {
  checkNewFeedRejected,
  checkNewFeedResolved,
  editFeed,
  editFeedRejected,
  editFeedResolved,
  getNewFeed,
  getNewFeedRejected,
  getNewFeedResolved,
  setNewFeed,
  unlikeFeed,
  unlikeFeedRejected,
  unlikeFeedResolved,
} from "./Feed.actions";
import { selectorLatestFeed, selectorNumberOfFeeds } from "./Feed.selectors";
import { GetFeedParams, GetFeedResponse } from "./Feed.types";
import { getDiscord } from "libs/journalEntry";

function* getFeedSaga(action: AnyAction) {
  try {
    const getFeedParams: GetFeedParams = action.payload;
    const numberOfFeeds: number = yield select(selectorNumberOfFeeds);
    let response: GetFeedResponse;
    if (getFeedParams.feedId) {
      response = yield API.get(
        "optionOrder",
        `/feed?feedId=${getFeedParams.feedId}`,
        {}
      );
    } else if (
      getFeedParams.symbol ||
      getFeedParams.tag ||
      getFeedParams.userName
    ) {
      let url = `/feed?${
        getFeedParams.symbol ? `symbol=${getFeedParams.symbol}&` : ""
      }${getFeedParams.tag ? `tag=${getFeedParams.tag}&` : ""}${
        getFeedParams.userName ? `userName=${getFeedParams.userName}&` : ""
      }${`limit=20&skip=${numberOfFeeds}&`}`;
      // }`;
      url = url.substr(0, url.length - 1);
      response = yield API.get("optionOrder", url, {});
    } else {
      response = yield API.get(
        "optionOrder",
        `/feed?${`limit=20&skip=${numberOfFeeds}`}`,
        // `/feed`,
        {}
      );
    }
    yield put(getFeedResolved(response));
  } catch (e) {
    onError(e);
    yield put(getFeedRejected());
  }
}

function* watchGetFeed() {
  yield takeLatest(getFeed, getFeedSaga);
}

export function* getDiscordParam(activity: Activity, entry: JournalEntry) {
  const user: User = yield select(selectorUser);

  return getDiscord(activity, entry, user);
}

function* editFeedSaga(action: AnyAction) {
  try {
    const response: Feed = yield API.put("optionOrder", "/feed", {
      body: action.payload,
    });
    yield put(editFeedResolved(response));
  } catch (e) {
    onError(e);
    yield put(editFeedRejected());
  }
}

function* watchEditFeed() {
  yield takeLatest(editFeed, editFeedSaga);
}

function* postFeedSaga(action: AnyAction) {
  try {
    const response: Feed = yield API.post("optionOrder", "/feed", {
      body: action.payload,
    });
    yield put(postFeedResolved(response));
  } catch (e) {
    onError(e);
    yield put(postFeedRejected());
  }
}

function* watchPostFeed() {
  yield takeLatest(postFeed, postFeedSaga);
}

function* deleteFeedSaga(action: AnyAction) {
  try {
    yield API.del("optionOrder", "/feed", {
      body: {
        feedIds: [action.payload],
      },
    });
    yield put(deleteFeedResolved(action.payload));
  } catch (e) {
    onError(e);
    yield put(deleteFeedRejected());
  }
}

function* watchDeleteFeed() {
  yield takeLatest(deleteFeed, deleteFeedSaga);
}

function* likeFeedSaga(action: AnyAction) {
  try {
    const response: Feed = yield API.post("optionOrder", "/feed/like", {
      body: action.payload,
    });
    yield put(likeFeedResolved(response));
  } catch (e) {
    onError(e);
    yield put(likeFeedRejected());
  }
}

function* watchLikeFeed() {
  yield takeLatest(likeFeed, likeFeedSaga);
}

function* unlikeFeedSaga(action: AnyAction) {
  try {
    const response: Feed = yield API.post("optionOrder", "/feed/like", {
      body: action.payload,
    });
    yield put(unlikeFeedResolved(response));
  } catch (e) {
    onError(e);
    yield put(unlikeFeedRejected());
  }
}

function* watchunLikeFeed() {
  yield takeLatest(unlikeFeed, unlikeFeedSaga);
}

function* replyFeedSaga(action: AnyAction) {
  try {
    const response: Feed = yield API.post("optionOrder", "/feed/reply", {
      body: action.payload,
    });
    yield put(replyFeedResolved(response));
  } catch (e) {
    onError(e);
    yield put(replyFeedRejected());
  }
}

function* watchReplyFeed() {
  yield takeLatest(replyFeed, replyFeedSaga);
}

function* getRepliesSaga(action: AnyAction) {
  try {
    // yield put(getRepliesResolved());
  } catch (e) {
    onError(e);
    yield put(getRepliesRejected());
  }
}

function* watchGetReplies() {
  yield takeLatest(getReplies, getRepliesSaga);
}

function* watchCheckNewFeed() {
  yield takeLatest(checkNewFeed, checkNewFeedSaga);
}

function* checkNewFeedSaga() {
  try {
    const latestFeed: Feed | undefined = yield select(selectorLatestFeed);
    if (latestFeed) {
      const response: boolean = yield API.post(
        "optionOrder",
        "/feed/checkNewFeed",
        {
          body: { time: latestFeed.createDate },
        }
      );
      yield put(checkNewFeedResolved(response));
    } else {
      yield put(checkNewFeedResolved(false));
    }
  } catch (e) {
    onError(e);
    yield put(checkNewFeedRejected());
  }
}

function* watchGetNewFeed() {
  yield takeLatest(getNewFeed, getNewFeedSaga);
}

function* getNewFeedSaga() {
  try {
    const latestFeed: Feed | undefined = yield select(selectorLatestFeed);
    if (latestFeed) {
      const response: Feed[] = yield API.post("optionOrder", "/feed/newFeeds", {
        body: { time: latestFeed.createDate },
      });
      yield put(getNewFeedResolved(response));
    } //else {
    //   yield put(getFeed());
    // }
    setNewFeed(false);
  } catch (e) {
    onError(e);
    yield put(getNewFeedRejected());
  }
}

const sagaArray = [
  watchGetFeed(),
  watchPostFeed(),
  watchDeleteFeed(),
  watchLikeFeed(),
  watchReplyFeed(),
  watchGetReplies(),
  watchCheckNewFeed(),
  watchGetNewFeed(),
  watchEditFeed(),
  watchunLikeFeed(),
];
export default sagaArray;
