import React, { useEffect } from "react";
import { Feeds } from "components/Feeds/Feeds.component";
import { useDispatch } from "react-redux";
import { getFeed } from "redux/Feed";
import { useParams } from "react-router";

export const UserFeeds = () => {
  const { userName } = useParams<{ userName: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeed({ userName: userName, clearFeed: true }));
  });
  return <Feeds />;
};
