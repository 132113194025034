import { createAction } from "@reduxjs/toolkit";
import { Feed } from "journal-lib";
import {
  editFeedParam,
  GetFeedParams,
  GetFeedResponse,
  likeFeedParam,
  PostFeedParams,
  replyFeedParam,
} from "./Feed.types";

export const resetFeed = createAction("feed/reset");

export const getFeed = createAction<GetFeedParams>("feed/getFeed");

export const getFeedResolved = createAction<GetFeedResponse>(
  "feed/getFeedResolved"
);

export const getFeedRejected = createAction("feed/getFeedRejected");

export const editFeed = createAction<editFeedParam>("feed/editFeed");

export const editFeedResolved = createAction<Feed>("feed/editFeedResolved");

export const editFeedRejected = createAction("feed/editFeedRejected");

export const getNewFeed = createAction("feed/getNewFeed");

export const getNewFeedResolved = createAction<Feed[]>(
  "feed/getNewFeedResolved"
);

export const getNewFeedRejected = createAction("feed/getNewFeedRejected");

export const postFeed = createAction<PostFeedParams>("feed/post");

export const postFeedResolved = createAction<Feed>("feed/postFeedResolved");

export const postFeedRejected = createAction("feed/postFeedRejected");

export const deleteFeed = createAction<string>("feed/delete");

export const deleteFeedResolved = createAction<string>(
  "feed/deleteFeedResolved"
);

export const deleteFeedRejected = createAction("feed/deleteFeedRejected");

export const likeFeed = createAction<likeFeedParam>("feed/like");

export const likeFeedResolved = createAction<Feed>("feed/likeFeedResolved");

export const likeFeedRejected = createAction("feed/likeFeedRejected");

export const unlikeFeed = createAction<likeFeedParam>("feed/unlike");

export const unlikeFeedResolved = createAction<Feed>("feed/unlikeFeedResolved");

export const unlikeFeedRejected = createAction("feed/unlikeFeedRejected");

export const replyFeed = createAction<replyFeedParam>("feed/replyFeed");

export const replyFeedResolved = createAction<Feed>("feed/replyFeedResolved");

export const replyFeedRejected = createAction("feed/replyFeedRejected");

export const getReplies = createAction<string>("feed/getReplies");

export const getRepliesResolved = createAction<Feed[]>(
  "feed/getRepliesResolved"
);

export const getRepliesRejected = createAction("feed/getRepliesRejected");

export const checkNewFeed = createAction("feed/checkNewFeed");

export const checkNewFeedResolved = createAction<boolean>(
  "feed/checkNewFeedResolved"
);

export const checkNewFeedRejected = createAction("feed/checkNewFeedRejected");

export const setNewFeed = createAction<boolean>("feed/setNewFeed");
