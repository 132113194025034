import { AnyAction, createReducer } from "@reduxjs/toolkit";
import {
  addEntryResolved,
  deleteEntryResolved,
  editJournalEntryResolved,
  getEarningResolved,
  getEntries,
  getEntriesResolved,
  resetJournalEntries,
  updateJournalEntry,
} from "./JournalEntries.actions";
import { JournalEntryStoreState } from "./JournalEntries.types";
import { JournalEntry } from "journal-lib";

const initialState: JournalEntryStoreState = {
  JournalEntries: [],
  totalCount: 0,
  loading: false,
  EarningDates: {},
};

interface JournalEntryReducer {
  [x: string]: (
    state: JournalEntryStoreState,
    action: AnyAction
  ) => JournalEntryStoreState | undefined;
}

const removeEntry = (entries: JournalEntry[], ids: string[]) => {
  return entries.filter(
    (entry) => entry.entryId && ids.indexOf(entry.entryId) < 0
  );
};

const reducer: JournalEntryReducer = {
  [resetJournalEntries.toString()]: () => {
    return initialState;
  },
  [getEntries.toString()]: (state) => ({
    ...state,
    loading: true,
  }),
  [getEntriesResolved.toString()]: (state, action) => ({
    ...state,
    initialized: true,
    JournalEntries: action.payload?.items,
    loading: false,
    totalCount: action.payload?.totalCount,
  }),
  [addEntryResolved.toString()]: (state, action) => {
    return {
      ...state,
      JournalEntries:
        action.payload && action.payload.entryId.length > 0
          ? [...state.JournalEntries, action.payload]
          : state.JournalEntries,
    };
  },
  [deleteEntryResolved.toString()]: (state, action) => {
    return {
      ...state,
      initialized: true,
      JournalEntries: removeEntry(
        state.JournalEntries,
        action.payload.entryIds
      ),
    };
  },
  [updateJournalEntry.toString()]: (state, action) => {
    return {
      ...state,
      JournalEntries: state.JournalEntries.map((journal) => {
        if (journal.entryId === action.payload.entryId) return action.payload;
        else return journal;
      }),
    };
  },
  [getEarningResolved.toString()]: (state, action) => ({
    ...state,
    EarningDates: action.payload,
  }),
  [editJournalEntryResolved.toString()]: (state, action) => ({
    ...state,
    JournalEntries: state.JournalEntries.map((entry) =>
      entry.entryId === action.payload.entryId
        ? {
            ...entry,
            account: action.payload.account,
          }
        : entry
    ),
  }),
};

export default createReducer(initialState, reducer);
