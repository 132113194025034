import { useEffect, useRef } from "react";
import { Feeds } from "components/Feeds/Feeds.component";
import { useDispatch, useSelector } from "react-redux";
import { getFeed, selectorIsFeedLoading } from "redux/Feed";
import { useNavigate, useParams } from "react-router";
import { Grid, TextField } from "@mui/material";
import { SymbolInfo, TechnicalAnalysis } from "components";
import { AdvancedChart } from "components/TradingView/AdvancedChart";
import { getTradingViewSymbol } from "utils/tradingView";

export const SymbolPage = () => {
  const { symbol } = useParams<{ symbol: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFeed({ symbol: symbol, clearFeed: true }));
  }, [symbol, dispatch]);

  const isFeedLoading = useSelector(selectorIsFeedLoading);
  const ref = useRef(null);

  return (
    <Grid container spacing={1}>
      <Grid xs={8} item style={{ paddingTop: "15px" }} container>
        <Grid item xs={12}>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              navigate(`/symbol/${e.target[0].value.toUpperCase()}`);
            }}
          >
            <TextField
              ref={ref}
              fullWidth
              type="text"
              label="Symbol"
              variant="outlined"
              helperText="Enter a symbol's ticker for more information"
              inputProps={{
                style: {
                  textTransform: "uppercase",
                },
              }}
            />
          </form>
        </Grid>
        {symbol && !isFeedLoading ? (
          <>
            <Grid item xs={12}>
              <SymbolInfo symbol={symbol} />
            </Grid>
          </>
        ) : null}
      </Grid>
      {symbol && !isFeedLoading ? (
        <>
          <Grid item xs={4} style={{ paddingTop: "15px" }}>
            <TechnicalAnalysis symbol={symbol} />
          </Grid>
          <Grid item xs={12} style={{ height: "600px" }}>
            <AdvancedChart symbol={getTradingViewSymbol(symbol)} />
            {/* <TradingViewWidget
              symbol={symbol}
              autosize
              timezone="America/Los_Angeles"
              style="9"
              withdateranges
              range="ytd"
              show_popup_button={true}
              popup_width="1000"
              popup_height="650"
              allow_symbol_change={false}
            /> */}
          </Grid>
          <Grid xs={12} item>
            <Feeds />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};
