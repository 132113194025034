import { Store, applyMiddleware, createStore } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";
import { persistStore } from "redux-persist";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import { AnyAction } from "@reduxjs/toolkit";

const sagaMiddleware = createSagaMiddleware();

export const syncCrossTabConfig = {
  predicate: (action: AnyAction): boolean | null => {
    const sType: string = action.type.toString();
    if (sType.indexOf("analysis") >= 0) {
      return true;
    } else {
      return false;
    }
  },
};

const middlewares = [
  sagaMiddleware,
  createStateSyncMiddleware(syncCrossTabConfig),
];

declare global {
  interface Window {
    store: Store;
    Cypress: object;
  }
}

export const store: Store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
// init state with other tabs
initStateWithPrevTab(store);
// initMessageListener(store);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

if (window.Cypress) {
  window.store = window.store || {};
  window.store = store;
}
// eslint-disable-next-line
export default { store, persistStore };
