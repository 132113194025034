import { all } from "redux-saga/effects";
import journalEntries from "./JournalEntries/JournalEntries.saga";
import accounts from "./Account/Account.sagas";
import user from "./User/User.sagas";
import feed from "./Feed/Feed.sagas";
import monthly from "./Stats/Monthly/Monthly.saga";
import yearly from "./Stats/Yearly/Yearly.saga";
import ui from "./UI/UI.saga";
import stock from "./Stock/Stock.sagas";
import analysis from "./Analysis/Analysis.sagas";

export default function* rootSaga() {
  yield all([
    ...journalEntries,
    ...accounts,
    ...user,
    ...feed,
    ...monthly,
    ...yearly,
    ...ui,
    ...stock,
    ...analysis,
  ]);
}
