import React, { useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { red } from "@mui/material/colors";
import {
  Cached,
  Chat,
  ChatBubbleOutline,
  FavoriteBorder,
  MoreHoriz,
} from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Feed as FeedType } from "journal-lib";
import { getInitials } from "utils/StringUtls";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFeed as deleteFeedAction,
  editFeed,
  getFeed,
  likeFeed,
  replyFeed,
  unlikeFeed,
} from "../../redux/Feed/Feed.actions";
import { FeedEditor } from "components/FeedEditor";
import { selectorUser } from "redux/User";
import { Skeleton } from "@mui/material";
import { TimelineConnector } from "@mui/lab";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import useIntersectionObserver from "utils/hooks/intersectionObserver";
import useEventListener from "utils/hooks/eventListener";
import { selectFeedStore } from "redux/Feed";
import { createTheme } from "@mui/material";
const theme = createTheme();

const useStyles = makeStyles(() => ({
  myTimeLine: {
    paddingRight: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginTop: "2px",
    marginBottom: "0",
    "&:hover": {
      backgroundColor: "#F3F3F3b0",
    },
    "&>li>div>div": {
      backgroundColor: "transparent",
    },
    "& .MuiAvatar-colorDefault": {
      backgroundColor: "#bdbdbd !important",
    },
  },
  bottomBorder: {
    borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    borderColor: "#EBEEF0",
  },
  zeroPadding: {
    padding: "0 0 0 65px",
  },
  root: {
    "& .MuiTimelineContent-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    "& .MuiTimelineOppositeContent-root": {
      flex: 0,
      padding: 0,
    },
  },
  myAvatar: {
    width: "48px",
    height: "48px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  thumbnail: {
    width: "36px",
    height: "36px",
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardRoot: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  feedContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
}));

const StyledBadge = withStyles((theme) =>
  createStyles({
    badge: {
      right: -15,
      top: 11,
      border: `0px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "#000",
      backgroundColor: "transparent",
    },
  })
)(Badge);

export const Feed = ({
  feed,
  needConnector = false,
  replyMode = false,
  isReply = false,
  replyDialogOpen_parent,
  setReplyDialogOpen_parent,
  replyCancelClicked_parent,
  replyClicked_parent,
}: {
  feed: FeedType;
  needConnector?: boolean;
  replyMode?: boolean;
  isReply?: boolean;
  replyDialogOpen_parent?: boolean;
  setReplyDialogOpen_parent?: any;
  replyCancelClicked_parent?: () => void;
  replyClicked_parent?: (event: any, htmlText: string) => void;
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [replyDialogOpen_, setReplyDialogOpen_] = React.useState(false);
  const replyDialogOpen = replyDialogOpen_parent
    ? replyDialogOpen_parent
    : replyDialogOpen_;
  const setReplyDialogOpen = setReplyDialogOpen_parent
    ? setReplyDialogOpen_parent
    : setReplyDialogOpen_;
  const user = useSelector(selectorUser);
  // const ref = useRef<HTMLDivElement | null>(null);
  const [ref, entry] = useIntersectionObserver({ onAppearOnly: true });

  const { isLoading, isThereNewPage } = useSelector(selectFeedStore);
  const onScroll = () => {
    const scrollTop = window.innerHeight + document.documentElement.scrollTop;
    const isBottom = scrollTop > document.documentElement.offsetHeight * 0.9;
    if (isBottom && !isLoading && isThereNewPage) {
      dispatch(getFeed({}));
      // loadMoreImage(next_page)
    }
  };

  useEventListener("scroll", onScroll);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteFeed = (feedId: string) => {
    dispatch(deleteFeedAction(feedId));
    handleClose();
  };

  const editSaveClicked = (event: any, htmlText: string) => {
    dispatch(
      editFeed({
        feedId: feed.feedId,
        content: htmlText,
        repliedTo: feed.repliedTo,
      })
    );
    setIsEdit(false);
  };

  const cancelClicked = () => {
    setIsEdit(false);
  };

  const handleReplyDialogClose = () => {
    setReplyDialogOpen(false);
  };

  const replyCancelClicked_ = () => {
    setReplyDialogOpen(false);
  };
  const replyCancelClicked = replyCancelClicked_parent
    ? replyCancelClicked_parent
    : replyCancelClicked_;

  const replyClicked_ = (event: any, htmlText: string) => {
    dispatch(
      replyFeed({
        content: htmlText,
        repliedTo: feed.feedId,
        owner: user,
      })
    );
    setReplyDialogOpen(false);
  };
  const replyClicked = replyClicked_parent
    ? replyClicked_parent
    : replyClicked_;

  return (
    <div ref={ref}>
      {Boolean(entry?.isVisible) ? (
        <Timeline
          className={clsx(
            classes.myTimeLine,
            { [classes.zeroPadding]: isReply },
            { [classes.bottomBorder]: !isReply }
          )}
        >
          <TimelineItem className={classes.root}>
            <TimelineOppositeContent />
            <TimelineSeparator>
              <Link
                href=""
                onClick={() => navigate(`/${feed?.owner.userName}`)}
              >
                <Avatar className={classes.myAvatar}>
                  {getInitials(feed?.owner?.name)?.toUpperCase()}
                </Avatar>
              </Link>
              {/* <TimelineDot className={classes.thumbnail}>CW</TimelineDot> */}
              {replyMode || needConnector ? (
                // || (!isReply && feed.replies && feed.replies.length > 0)
                <TimelineConnector />
              ) : (
                <></>
              )}
              {/* {feed.replies && feed.replies.length > 0 ? (
            <TimelineConnector />
          ) : (
            <></>
          )} */}
            </TimelineSeparator>
            <TimelineContent style={{ marginTop: "-16px" }}>
              <Card className={classes.cardRoot} raised={false} elevation={0}>
                <CardHeader
                  //   style={{ paddingTop: "0" }}
                  //   avatar={
                  //     <Avatar aria-label="recipe" className={classes.avatar}>
                  //       R
                  //     </Avatar>
                  //   }
                  action={
                    feed.identityId === user.identityId && !replyMode ? (
                      <>
                        <IconButton
                          aria-label="settings"
                          onClick={handleClick}
                          size="large"
                        >
                          <MoreHoriz />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setIsEdit(true);
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              deleteFeed(feed.feedId);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  //   disableTypography
                  title={
                    <div
                      onClick={() =>
                        navigate(`/${user.userName}/feed/${feed.feedId}`)
                      }
                    >
                      {/* <Link
                    href="#"
                    onClick={() => history.push(`/${user.userName}`)}
                  > */}
                      <Typography
                        variant="h6"
                        display="inline"
                        style={{ paddingRight: "10px" }}
                      >
                        {feed?.owner?.name}
                      </Typography>
                      {/* </Link> */}
                      {/* <Typography
                    variant="body2"
                    display="inline"
                    style={{ paddingRight: "10px" }}
                  >
                    userName
                  </Typography> */}
                      <Typography variant="caption" display="inline">
                        {moment(feed?.createDate).get("date") !==
                        moment().get("date")
                          ? moment(feed?.createDate).format("M/D/YYYY h:m a")
                          : moment(feed?.createDate).fromNow()}
                      </Typography>
                    </div>
                  }
                  disableTypography
                />
                {/* <CardMedia
              className={classes.media}
              image="/static/images/cards/paella.jpg"
              title="Paella dish"
            /> */}
                <CardContent className={classes.feedContent}>
                  {!isEdit ? (
                    <div dangerouslySetInnerHTML={{ __html: feed.content }} />
                  ) : (
                    <FeedEditor
                      value={feed.content}
                      buttonLabel="Save"
                      onClicked={editSaveClicked}
                      onCancelClicked={cancelClicked}
                    />
                  )}
                  {/* <Typography variant="body2" color="textSecondary" component="p">
                {feed.content}
              </Typography> */}
                </CardContent>
                {!replyMode ? (
                  <CardActions
                    style={{
                      justifyContent: "space-between",
                      paddingBottom: "0",
                      paddingTop: "0",
                    }}
                  >
                    <IconButton
                      onClick={() => setReplyDialogOpen(true)}
                      size="large"
                    >
                      {feed.repliedCount && feed.repliedCount > 0 ? (
                        <StyledBadge
                          badgeContent={feed.repliedCount}
                          color="secondary"
                        >
                          <Chat />
                        </StyledBadge>
                      ) : (
                        <ChatBubbleOutline />
                      )}
                    </IconButton>
                    <IconButton size="large">
                      <Cached />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        feed.likes?.indexOf(user.identityId) >= 0
                          ? dispatch(
                              unlikeFeed({
                                feedId: feed.feedId,
                                like: false,
                                repliedTo: feed.repliedTo,
                              })
                            )
                          : dispatch(
                              likeFeed({
                                feedId: feed.feedId,
                                like: true,
                                repliedTo: feed.repliedTo,
                              })
                            )
                      }
                      size="large"
                    >
                      {feed.likes && feed.likes.length > 0 ? (
                        <StyledBadge
                          badgeContent={feed.likes.length}
                          color="secondary"
                        >
                          {feed.likes.indexOf(user.identityId) >= 0 ? (
                            <FavoriteIcon
                              style={{ color: "rgb(224, 36, 94)" }}
                            />
                          ) : (
                            <FavoriteBorder />
                          )}
                        </StyledBadge>
                      ) : (
                        <FavoriteBorder />
                      )}
                    </IconButton>
                    <IconButton size="large">
                      <ShareIcon />
                    </IconButton>
                  </CardActions>
                ) : (
                  <></>
                )}
                <Dialog
                  fullWidth
                  open={replyDialogOpen}
                  onClose={handleReplyDialogClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Reply</DialogTitle>
                  <DialogContent>
                    <Feed
                      feed={feed}
                      replyMode={true}
                      // replyDialogOpen_parent={replyDialogOpen_}
                      // setReplyDialogOpen_parent={setReplyDialogOpen_}
                      replyCancelClicked_parent={replyCancelClicked_}
                      replyClicked_parent={replyClicked_}
                    />
                  </DialogContent>
                  {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Reply
                </Button>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
              </DialogActions> */}
                </Dialog>
              </Card>
            </TimelineContent>
          </TimelineItem>
          {replyMode ? (
            <TimelineItem className={classes.root}>
              <TimelineOppositeContent />
              <TimelineSeparator>
                <Avatar className={classes.myAvatar}>
                  {getInitials(user?.name)?.toUpperCase()}
                </Avatar>
              </TimelineSeparator>
              <TimelineContent style={{ marginTop: "-16px" }}>
                <Card className={classes.cardRoot} raised={false} elevation={0}>
                  <CardHeader
                    title={
                      <div>
                        <Typography
                          variant="h6"
                          display="inline"
                          style={{ paddingRight: "10px" }}
                        >
                          Replying to {feed.owner.name}
                        </Typography>
                      </div>
                    }
                    disableTypography
                  />
                  {/* <CardMedia
              className={classes.media}
              image="/static/images/cards/paella.jpg"
              title="Paella dish"
            /> */}
                  <CardContent className={classes.feedContent}>
                    <FeedEditor
                      value="Type your reply"
                      buttonLabel="Replay"
                      onClicked={replyClicked}
                      onCancelClicked={replyCancelClicked}
                    />
                  </CardContent>
                </Card>
              </TimelineContent>
            </TimelineItem>
          ) : !isReply && feed.replies && feed.replies.length > 0 ? (
            feed.replies?.map((reply, index) => (
              <Feed
                key={reply.feedId}
                feed={reply}
                isReply={true}
                needConnector={index < feed.replies.length - 1}
              />
            ))
          ) : (
            <></>
          )}
        </Timeline>
      ) : (
        <Timeline
          className={clsx(
            classes.myTimeLine,
            { [classes.zeroPadding]: isReply },
            { [classes.bottomBorder]: !isReply }
          )}
        >
          <TimelineItem className={classes.root}>
            <TimelineOppositeContent />
            <TimelineSeparator>
              <Skeleton
                variant="circular"
                width={48}
                height={48}
                animation={false}
              />
            </TimelineSeparator>
            <TimelineContent style={{ marginTop: "-16px" }}>
              <Card className={classes.cardRoot} raised={false} elevation={0}>
                <CardHeader
                  title={
                    <Typography
                      variant="h5"
                      display="inline"
                      style={{ paddingRight: "10px" }}
                    >
                      <Skeleton animation={false} />
                    </Typography>
                  }
                  disableTypography
                />
                <CardContent className={classes.feedContent}>
                  <Box>
                    <Typography
                      variant="body1"
                      style={{ paddingRight: "10px" }}
                    >
                      <Skeleton animation={false} />
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ paddingRight: "10px" }}
                    >
                      <Skeleton animation={false} width="60%" />
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ paddingRight: "10px" }}
                    >
                      <Skeleton animation={false} width="40%" />
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ paddingRight: "10px" }}
                    >
                      <Skeleton animation={false} />
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      )}
    </div>
  );
};
