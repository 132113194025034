import { AnyAction, createReducer } from "@reduxjs/toolkit";
import { getMonthlyResolved, resetStat } from "./Monthly/Monthly.actions";
import { StatsStoreState } from "./Stats.types";
import { getYearlyResolved } from "./Yearly/Yearly.actions";

const initialState: StatsStoreState = {
  monthly: [],
  yearly: [],
};

interface StatsReducer {
  [x: string]: (
    state: StatsStoreState,
    action: AnyAction
  ) => StatsStoreState | undefined;
}

const reducer: StatsReducer = {
  [resetStat.toString()]: () => {
    return initialState;
  },
  [getMonthlyResolved.toString()]: (state, action) => ({
    ...state,
    monthly: action.payload,
  }),
  [getYearlyResolved.toString()]: (state, action) => ({
    ...state,
    yearly: action.payload,
  }),
};

export default createReducer(initialState, reducer);
