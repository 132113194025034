import { StoreState } from "redux/store.types";
import { createSelector } from "reselect";

// const namespacedStore = (state: NamespacedStoreState) => state[moduleNamespace];
const storeState = (state: StoreState) => state;

export const selectorAccountStore = createSelector(
  storeState,
  (state) => state?.accounts
);

export const selectorAccountArray = createSelector(storeState, (state) =>
  state?.accounts
    ? Object.keys(state?.accounts).map((accountId) => ({
        value: accountId,
        label: state?.accounts[accountId].description,
        brokerageAccount: state?.accounts[accountId].brokerageAccount,
      }))
    : []
);
// export const selectJournalEntries = () => useSelector(selectorJournalEntries);
