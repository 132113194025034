import { API } from "aws-amplify";
import { put, takeLatest } from "@redux-saga/core/effects";
import { Quote } from "journal-lib";
import { onError } from "libs/errorLib";
import moment from "moment";
import { AnyAction } from "redux";
import {
  getEntryPLChart,
  getEntryPLChartRejected,
  getEntryPLChartResolved,
  getEntryPreviewPLChart,
  getEntryPreviewPLChartOnNew,
  getEntryPreviewPLChartRejected,
  getEntryPreviewPLChartResolved,
  getExpDate,
  getExpDateRejected,
  getExpDateResolved,
  getStockChart,
  getStockChartRejected,
  getStockChartResolved,
  getStockQuotes,
  getStockQuotesRejected,
  getStockQuotesResolved,
} from "./Stock.actions";
import { chartPoint, getExpDateResponse, ohlc } from "./Stock.types";

function* watchGetStockChart() {
  yield takeLatest(getStockChart, getStockChartSaga);
}

function* getStockChartSaga(action: AnyAction) {
  try {
    const response: ohlc[] = yield API.get(
      "optionOrder",
      `/marketData?symbol=${action.payload.symbol}&interval=${
        action.payload.interval
      }&from=${moment(action.payload.from)}&to=${moment(action.payload.to)}`,
      {}
    );
    yield put(
      getStockChartResolved(
        response?.map((ohlc) => ({ ...ohlc, date: moment(ohlc.date).format() }))
      )
    );
  } catch (e) {
    onError(e);
    yield put(getStockChartRejected());
  }
}

function* watchGetStockQuotes() {
  yield takeLatest(getStockQuotes, getStockQuotesSaga);
}

function* getStockQuotesSaga(action: AnyAction) {
  try {
    const symbol = action.payload.join(",").trim();
    if (symbol !== "") {
      const response: Quote[] = yield API.get(
        "optionOrder",
        `/marketData/quote?symbols=${symbol}`,
        {}
      );
      yield put(getStockQuotesResolved(response));
    }
  } catch (e) {
    onError(e);
    yield put(getStockQuotesRejected());
  }
}

function* watchGetExpDate() {
  yield takeLatest(getExpDate, getExpDateSaga);
}
function* getExpDateSaga(action: AnyAction) {
  try {
    const response: getExpDateResponse = yield API.get(
      "optionOrder",
      `/marketData/expirations?symbol=${action.payload}`,
      {}
    );
    yield put(getExpDateResolved(response));
  } catch (e) {
    onError(e);
    yield put(getExpDateRejected());
  }
}

function* watchGetEntryPLChart() {
  yield takeLatest(getEntryPLChart, getEntryPLChartSaga);
}
function* getEntryPLChartSaga(action: AnyAction) {
  try {
    const response: chartPoint[] = yield API.get(
      "optionOrder",
      `/journalEntry/profitChart?entryId=${action.payload}`,
      {}
    );
    yield put(
      getEntryPLChartResolved(response.sort((a, b) => (a.x > b.x ? -1 : 1)))
    );
  } catch (e) {
    onError(e);
    yield put(getEntryPLChartRejected());
  }
}

function* watchGetEntryPreviewPLChart() {
  yield takeLatest(getEntryPreviewPLChart, getEntryPreviewPLChartSaga);
}
function* getEntryPreviewPLChartSaga(action: AnyAction) {
  try {
    const response: chartPoint[] = yield API.post(
      "optionOrder",
      `/journalEntry/profitChartOnRoll`,
      {
        body: action.payload,
      }
    );
    yield put(
      getEntryPreviewPLChartResolved(
        response.sort((a, b) => (a.x > b.x ? -1 : 1))
      )
    );
  } catch (e) {
    onError(e);
    yield put(getEntryPreviewPLChartRejected());
  }
}

function* watchGetEntryPreviewPLChartOnNew() {
  yield takeLatest(
    getEntryPreviewPLChartOnNew,
    getEntryPreviewPLChartOnNewSaga
  );
}
function* getEntryPreviewPLChartOnNewSaga(action: AnyAction) {
  try {
    const response: chartPoint[] = yield API.post(
      "optionOrder",
      `/journalEntry/profitChartOnNew`,
      {
        body: action.payload,
      }
    );
    yield put(
      getEntryPreviewPLChartResolved(
        response.sort((a, b) => (a.x > b.x ? -1 : 1))
      )
    );
  } catch (e) {
    onError(e);
    yield put(getEntryPreviewPLChartRejected());
  }
}

const sagaArray = [
  watchGetStockChart(),
  watchGetStockQuotes(),
  watchGetExpDate(),
  watchGetEntryPLChart(),
  watchGetEntryPreviewPLChart(),
  watchGetEntryPreviewPLChartOnNew(),
];
export default sagaArray;
