import { Button, IconButton, Snackbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { Feed } from "components/Feed/Feed.component";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  checkNewFeed,
  getNewFeed,
  selectorFeeds,
  selectorNewFeed,
  setNewFeed,
} from "redux/Feed";
import useInterval from "utils/hooks/interval";
import { createTheme } from "@mui/material";
const theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "13px",
    borderWidth: "0px 1px 0px 1px",
    borderColor: "#EBEEF0",
    borderStyle: "solid",
  },
  toolbar: theme.mixins.toolbar,
  title: {
    padding: "16px 0 0 24px;",
  },
}));

// function SlideTransition(props: TransitionProps) {
//   return <Slide {...props} direction="down" />;
// }

export const Feeds = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [checkNewDelay, setCheckNewDelay] = useState(60000);
  const newFeed = useSelector(selectorNewFeed);
  const location = useLocation();

  useInterval(
    () => {
      dispatch(checkNewFeed());
    },
    !newFeed && location.pathname === "/newsfeed" ? checkNewDelay : null
  );

  const feeds = useSelector(selectorFeeds);

  const dismissRefresh = () => {
    setCheckNewDelay(checkNewDelay * 2);
    dispatch(setNewFeed(false));
  };

  const refreshNewFeed = () => {
    setCheckNewDelay(60000);
    dispatch(getNewFeed());
  };

  return (
    <>
      {/* <div style={{ width: "100%" }}>
        <FeedEditor />
      </div> */}
      <div className={classes.root}>
        {feeds && feeds.length > 0 ? (
          feeds.map((feed) => <Feed key={feed.feedId} feed={feed} />)
        ) : (
          <></>
        )}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={newFeed}
        // onClose={handleSnackbarClose}
        message="I love snacks"
        // TransitionComponent={SlideTransition}
        autoHideDuration={9000000}
      >
        <Alert
          style={{ width: "400px" }}
          severity="info"
          variant="filled"
          action={
            <>
              <Button color="inherit" size="small" onClick={refreshNewFeed}>
                REFRESH
              </Button>
              <IconButton size="small" onClick={dismissRefresh}>
                <Close fontSize="small" />
              </IconButton>
            </>
          }
        >
          There are new updates!
        </Alert>
      </Snackbar>
    </>
  );
};
