import { API } from "aws-amplify";
import { put, takeLatest } from "@redux-saga/core/effects";
import { onError } from "libs/errorLib";
import { AnyAction } from "redux";
import {
  addAccount,
  addAccountRejected,
  addAccountResolved,
  deleteAccount,
  deleteAccountRejected,
  deleteAccountResolved,
  editAccount,
  editAccountRejected,
  editAccountResolved,
  getAccount,
  getAccountRejected,
  getAccountResolved,
  resetAccount,
} from "./Account.actions";
import { AccountStoreState, GetAccountResponse } from "./Account.types";
import { resetAnalysis } from "redux/Analysis";
import { resetFeed } from "redux/Feed";
import { resetJournalEntries } from "redux/JournalEntries";
import { resetStat } from "redux/Stats/Monthly/Monthly.actions";
import { resetStock } from "redux/Stock/Stock.actions";
import { resetUI } from "redux/UI";
import { resetUser } from "redux/User/User.actions";

function* getAccountSaga(action: AnyAction) {
  try {
    const accounts: GetAccountResponse[] = yield API.get(
      "optionOrder",
      "/account",
      {}
    );

    let modifiedAccounts: AccountStoreState = {};

    accounts.forEach((account) => {
      modifiedAccounts[account.id] = {
        description: account.description,
        brokerageAccount: account.brokerageAccount,
      };
    });

    yield put(getAccountResolved(modifiedAccounts));
  } catch (e) {
    onError(e);
    yield put(getAccountRejected());
  }
}

function* watchGetAccount() {
  yield takeLatest(getAccount, getAccountSaga);
}

function* watchAddAccount() {
  yield takeLatest(addAccount, addAccountSaga);
}

function* addAccountSaga(action: AnyAction) {
  try {
    const response: GetAccountResponse = yield API.post(
      "optionOrder",
      "/account",
      { body: action.payload }
    );
    yield put(addAccountResolved(response));
  } catch (e) {
    onError(e);
    yield put(addAccountRejected());
  }
}

function* watchEditAccount() {
  yield takeLatest(editAccount, editAccountSaga);
}

function* editAccountSaga(action: AnyAction) {
  try {
    yield API.put("optionOrder", `/account/${action.payload.id}`, {
      body: action.payload,
    });
    yield put(editAccountResolved(action.payload));
  } catch (e) {
    onError(e);
    yield put(editAccountRejected());
  }
}

function* watchDeleteAccount() {
  yield takeLatest(deleteAccount, deleteAccountSaga);
}

function* deleteAccountSaga(action: AnyAction) {
  try {
    yield API.del("optionOrder", `/account/${action.payload}`, {});
    yield put(deleteAccountResolved(action.payload));
  } catch (e) {
    onError(e);
    yield put(deleteAccountRejected());
  }
}

function* resetAccountSaga() {
  yield put(resetAnalysis());
  yield put(resetFeed());
  yield put(resetJournalEntries());
  yield put(resetStat());
  yield put(resetStock());
  yield put(resetUI());
  yield put(resetUser());
}

function* watchReset() {
  yield takeLatest(resetAccount, resetAccountSaga);
}

const sagaArray = [
  watchGetAccount(),
  watchAddAccount(),
  watchEditAccount(),
  watchDeleteAccount(),
  watchReset(),
];
export default sagaArray;
