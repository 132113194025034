import { AppBar, IconButton, Toolbar, Typography, Drawer } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { JournalTable, DeleteEntry, CloseEntry } from "components";
import { EntryDetails } from "components/EntryDetails";
import { RollEntry } from "components/RollEntry";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { MenuList } from "components/MenuList/MenuList.component";
import { AddLegs } from "components/AddLegs";
import { AddLegsFromOrders } from "components/AddLegsFromOrders";
import { ChangeCustomStrategy } from "components/ChangeCustomStrategy";
import { createTheme } from "@mui/material";
const theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    // height: "100vh",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  appBar: {
    boxShadow:
      "0px 2px 4px -4px rgb(0 0 0 / 26%), 0px 4px 5px -4px rgb(0 0 0 / 17%), 0px 1px 10px -10px rgb(0 0 0 / 14%)",
    background: "white",
  },
}));

type Anchor = "left";

export const Main = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MenuList />
    </div>
  );

  return (
    <>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer("left", true)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Trader Journals
          </Typography>
        </Toolbar>
      </AppBar>
      <JournalTable></JournalTable>
      <CloseEntry />
      <DeleteEntry />
      <RollEntry />
      <EntryDetails />
      <AddLegs />
      <AddLegsFromOrders />
      <ChangeCustomStrategy />
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </>
  );
};

export default Main;
